import React, { useEffect } from "react";
// import { roles, getRoleIdInLS } from "../../../utils/videoCall";
import { useSelector } from "react-redux";
import { Dialog, DialogContent } from "../../../../ui/dialog";

function DoctorCancelMeeting(props) {
  const {
    cancelCallFromDoctorSide,
    rejoinCancelCallFromDoctorSide,
    role,
    isInsurance,
    claimAndPrescriptionHandler,
    isCallFinished,
    isClaimRXDone,
    openDialog,
    closeDialog,
  } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);
  return (
    <Dialog open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <h3 className="text-2xl font-semibold text-navy">Please Confirm</h3>

        <div className="my-6 text-base font-medium text-navy">
          <p>
            {"Are you sure you want to end the call?"}
            {/* {role == 4
							? "Are you sure you want to end the call?"
							: isInsurance == 1
							? isClaimRXDone == 1
								? "End Call and View E-Claim?"
								: "End Call and Create an E-Claim?"
							: isClaimRXDone == 1 && isCallFinished == 1
							? "End Call and View Prescription?"
							: "End Call and Write Prescription?"} */}
          </p>
        </div>

        <div className="flex items-center gap-4 justify-center">
          <button
            className="bg-error text-white border-none text-base font-semibold rounded-lg py-2 px-5 w-[150px]"
            onClick={cancelCallFromDoctorSide}
          >
            End Call
          </button>
          {/* {role == 8 && (
						<button
							className="btn btn-simple fs22"
							onClick={claimAndPrescriptionHandler}
						>
							{isInsurance == 1
								? isClaimRXDone == 1
									? "View E-Claim"
									: "Create E-Claim"
								: isClaimRXDone == 1 && isCallFinished == 1
								? "View prescription"
								: "Write prescription"}
						</button>
					)} */}
          {/* <button
						className="btn btn-primary fs22"
						onClick={rejoinCancelCallFromDoctorSide}
					>
						Rejoin
					</button> */}
          <button
            className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5 w-[150px]"
            onClick={closeDialog}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DoctorCancelMeeting;
