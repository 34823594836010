import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
type IProp = {
  totalPages: number;
  handlePageChange: (selectedItem: { selected: number }) => void;
  currentPage: number;
  isProvider?: string;
  colorData?: any
};

function Pagination({ totalPages, handlePageChange, currentPage, colorData, isProvider }: IProp) {
  const [paginationRange, setPaginationRange] = useState({
    pageRangeDisplayed: 5,
    marginPagesDisplayed: 2,
  });

  useEffect(() => {
    const updatePaginationRange = () => {
      if (window.innerWidth < 768) {
        setPaginationRange({
          pageRangeDisplayed: 1,
          marginPagesDisplayed: 3,
        });
      } else {
        setPaginationRange({
          pageRangeDisplayed: 5,
          marginPagesDisplayed: 3,
        });
      }
    };

    updatePaginationRange();
    window.addEventListener("resize", updatePaginationRange);

    return () => window.removeEventListener("resize", updatePaginationRange);
  }, []);
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<button className="w-[32px] h-[32px]">{">"}</button>}
      onPageChange={handlePageChange}
      pageRangeDisplayed={paginationRange.pageRangeDisplayed}
      marginPagesDisplayed={paginationRange.marginPagesDisplayed}
      // pageRangeDisplayed={5}
      pageCount={Math.ceil(totalPages)}
      previousLabel={<button className="w-[32px] h-[32px]">{"<"}</button>}
      renderOnZeroPageCount={null}
      pageLabelBuilder={(page) => (
        <button className="w-[32px] h-[32px]">{page}</button>
      )}
      containerClassName={
        "pagination flex items-center justify-center gap-[5px]"
      }
      previousClassName={
        "border border-paginationBorder rounded-lg  flex justify-center items-center text-paginationText font-semibold text-[13px] hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      }
      nextClassName={
        "border border-paginationBorder rounded-lg flex justify-center items-center text-paginationText font-semibold text-[13px] hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      }
      activeClassName={"active bg-seaGreen text-white border-seaGreen"}
      pageClassName={
        "pagination-page border border-paginationBorder rounded-lg flex justify-center items-center text-paginationText font-semibold text-[13px] cursor-pointer hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      }

      // containerClassName={
      //   "pagination flex items-center justify-center gap-[5px]"
      // }
      // previousClassName={
      //   "border border-paginationBorder rounded-lg  flex justify-center items-center text-paginationText font-semibold text-[13px] hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      // }
      // nextClassName={
      //   "border border-paginationBorder rounded-lg flex justify-center items-center text-paginationText font-semibold text-[13px] hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      // }
      // activeClassName={`active ${!isProvider ? colorData?.btn_bg_color : "bg-seaGreen"}  text-white border-seaGreen`}
      // pageClassName={
      //   "pagination-page border border-paginationBorder rounded-lg flex justify-center items-center text-paginationText font-semibold text-[13px] cursor-pointer hover:bg-seaGreen hover:text-[#fff] hover:border-seaGreen"
      // }
    />
  );
}

export default Pagination;
