import * as z from 'zod';

const MIN_AGE = 18;

// export const getPhoneSchema = (digits: number) =>
//     z.object({
//         phone_number: z
//             .string()
//             .length(digits, { message: `Phone number must be ${digits} digits long.` })
//             .regex(/^\d+$/, { message: "Phone number must only contain digits." }),
//     });



const validatePhoneNumber = (phone_no: string, digits: number) => {
    let phoneLength = phone_no.length;

    if (phoneLength === digits) {
        const validPhone = phone_no.charAt(0); // First character
        const validPhoneAt1 = phone_no.charAt(1); // Second character

        if (digits === 10) {
            // For 10-digit numbers
            if (validPhone === "0" && (validPhoneAt1 === "7" || validPhoneAt1 === "1")) {
                return true; // Valid 10-digit number
            }
            return false; // Invalid number
        } else if (digits === 9) {
            // For 9-digit numbers
            if (validPhone === "7") {
                return true; // Valid 9-digit number
            }
            return false; // Invalid number
        }
    }
    return false; // If the length does not match the expected digits
};


// export const getPhoneSchema = (digits: number) =>
//     z.object({
//         phone_number: z
//             .string()
//             .min(digits, `Phone number must be exactly ${digits} digits`)
//             .refine((phone_no) => validatePhoneNumber(phone_no, digits), {
//                 message: "Invalid phone number",
//             }),
//     });


// export const getPhoneSchema = (digits: number) =>
//     z.object({
//         phone_number: z
//             .string()
//             .length(digits, `Phone number must be exactly ${digits} digits`)
//             .regex(/^\d+$/, { message: "Phone number must only contain digits." })
//             .refine((phone_no) => validatePhoneNumber(phone_no, digits), {
//                 message: "Invalid phone number",
//             }),
//     });



// export const getPhoneSchema = (digits: any) => {
//     return z.object({
//         phone_number: z
//             .string()
//             .length(digits, {
//                 message: `Phone number must be exactly ${digits} digits long`,
//             })
//             .regex(/^[0-9]+$/, {
//                 message: "Phone number must only contain digits",
//             })
//             .refine((val) => {
//                 if (digits == 10) {
//                     return val.startsWith("0") && (val[1] == '7' || val[1] == '1');
//                 } else if (digits == 9) {
//                     return val.startsWith("7") || val.startsWith("1");
//                 }
//                 return false;
//             }, {
//                 message: digits == 10
//                     ? "Invalid phone no"
//                     : "Invalid phone no",
//             }),
//     });
// };



// export const getPhoneSchema = (selectedCountry: any) => {

//     const isKenya = selectedCountry?.phone_country_code == "254";
//     const digits = selectedCountry?.digits_phone_number;

//     console.log("selectedCountry", selectedCountry, digits)

//     return z.object({
//         phone_number: z
//             .string()
//             .min(9, {
//                 message: "Phone number must be at least 9 digits long",
//             })
//             .max(10, {
//                 message: "Phone number must be at most 10 digits long",
//             })
//             .regex(/^[0-9]+$/, {
//                 message: "Phone number must only contain digits",
//             })
//             .refine((val) => {

//                 if (isKenya) {
//                     // Allow 9 or 10 digits for Kenya
//                     return val.length == 9 || val.length == 10;
//                 } else {
//                     // Existing logic for other countries

//                     if (digits == 10) {
//                         return val.startsWith("0") && (val[1] == '7' || val[1] == '1');
//                     } else if (digits == 9) {
//                         return val.startsWith("7") || val.startsWith("1");
//                     }
//                 }
//                 return false;
//             }, {
//                 message: "Invalid phone number",
//             }),
//     });
// };


export const phoneSchema = z.object({
    phone_number: z
        .string()
        .regex(/^[0-9]+$/, { message: "Phone number must only contain digits" })
        .min(9, { message: "Phone number must be at least 9 digits long" })
        .max(10, { message: "Phone number must be at most 10 digits long" })
        .refine((val) => {
            // Allow 9 digits starting with 7 or 1
            if (val.length === 9) {
                return val.startsWith("7") || val.startsWith("1");
            }
            // Allow 10 digits starting with 0 and second digit is 7 or 1
            if (val.length === 10) {
                return val.startsWith("0") && (val[1] === '7' || val[1] === '1');
            }
            return false;
        }, {
            message: "Invalid phone no",
        }),
});


export const otpSchema = z.object({
    otp: z.string().min(4, { message: 'Please enter a valid otp' }).max(4),
});

export const signupSchema = z.object({
    phone_number: z.string()
        .min(9, 'Phone number must be at least 9 characters long')
        .max(15, 'Phone number must be at most 15 characters long'),
    national_id: z.string({required_error:'Please enter National ID number'})
        .min(10, 'National ID Number must be at least 10 characters long')
        .max(15, 'National ID Number must be at most 15 characters long')
        .refine((val) => /^[a-zA-Z0-9]*$/.test(val), 'National ID Number must be alphanumeric'),
    name_prefix: z.string()
        .min(1, 'Please select title')
        .max(20, 'Please select title'),
    first_name: z.string()
        .min(1, 'Please enter first name')
        .max(50, 'First name must be at most 50 characters long'),
    last_name: z.string()
        .min(1, 'Please enter last name')
        .max(50, 'Last name must be at most 50 characters long'),

    gender: z.string()
        .min(1, 'Please select gender')
        .max(20, 'Please select gender'),


    city: z.string().min(1, 'City is required'),
        // .max(100, 'City is required'),
    date_of_birth: z.string().refine((value) => {
        const today = new Date();
        const dob = new Date(value);
        return dob < today;
    }, {
        message: 'You must be at least 18 years old',
    }),
    // date_of_birth: z.string().refine((value) => {
    //     if (!value) return true; // Allow empty strings
    //     const today = new Date();
    //     const dob = new Date(value);
    //     const age = today.getFullYear() - dob.getFullYear();
    //     const monthDiff = today.getMonth() - dob.getMonth();

    //     return age > MIN_AGE || (age === MIN_AGE && monthDiff >= 0);
    // }, {
    //     message: 'You must be at least 18 years old',
    // }),
    email: z.string()
        .email('Please enter email')
        .max(100, 'Email must be at most 100 characters long'),
});


