import React, { useEffect } from 'react';
// import { Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { LoaderCircle } from 'lucide-react';



function Loader() {
    const isLoading = useSelector((state: RootState) => state.globalLoader.isLoading);
    return (
        <>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75 z-[999999]">
                    <div className="loader-container">
                        <LoaderCircle className="animate-spin h-20 w-20" color="#9EE5CA" />
                    </div>
                </div>
            )}
        </>
    );
}

export default React.memo(Loader);
