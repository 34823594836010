import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["WEBSITE_SOCKET_SAVE_SOCKET_REF","SOCKET_SAVE_SOCKET_REF"], // Add any actions to ignore
      // ignoredPaths: ['socket.socketRef'], // Add any state paths to ignore
    },
  }),
 });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {counter: CounterState}
export type AppDispatch = typeof store.dispatch;
