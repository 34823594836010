import { createSlice } from '@reduxjs/toolkit';
import { IDoctor, IInsurance } from '../../utils/types';
interface InitialStateType {
    type: number;
    branches: any[];
    hospital: any[];
    doctors: IDoctor[];
    insurance_companies: IInsurance[];
    banner_data: any;
    contact_us_emails: any[];
    about_us_texts: string;
    body_data: any;
    specialization_data:any[];
    count_events_pending_action:number;
    total_records:number
}
const initialState:InitialStateType = {
   type:1,
    branches: [], 
    hospital: [],
    doctors:[],
    insurance_companies:[],
    banner_data:{},
    contact_us_emails:[],
    about_us_texts:'',
    body_data:{},
    specialization_data:[],
    count_events_pending_action:0,
    total_records:0
    
};

const insuranceListSlice = createSlice({
    name: 'insurance-list',
    initialState,
    reducers: {
        setInsuranceList(state, action) {
            return { ...state, ...action.payload };
        },
        resetInsuranceList(state) {
            state = initialState;
        }
    }
});

export const { setInsuranceList, resetInsuranceList } = insuranceListSlice.actions;
export default insuranceListSlice.reducer;
