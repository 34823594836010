import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import "react-phone-input-2/lib/style.css";
import AuthLayout from "../../layout/authLayout";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { signupSchema } from "../../lib/validations/auth";
import { getCityList } from "../../actions";
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import API_CONSTANTS from "../../utils/api/api.configuration";
import END_POINTS from "../../utils/api/endpoints";
import { getImage, getPhoneId } from "../../utils/helperfunction";
import images from "../../utils/images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CityAutocomplete from "../../components/CityAutocomplete";
import SignUpModal from "./signUpModal";
import Cookies from "js-cookie";
import { showAlert } from "../../utils/alert";
import SelectDropdown from "../../components/Select";
import { Icons } from "../../utils/icons";
import { genderOptions, namePrefixes } from "../../utils/constant";


const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#CBCAD7' : '#CBCAD7',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#CBCAD7' : '#CBCAD7',
        },
        height: "56px",
        // cursor: "pointer",
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#23901E' : 'white',
        color: state.isSelected ? '#FFFFFF' : '#000000',
        '&:hover': {
            backgroundColor: '#23901E',
            color: '#FFFFFF',
        },
        cursor: "pointer"
    }),
    menu: (base: any) => ({
        ...base,
        cursor: "pointer",
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        cursor: "pointer",
    }),
};
type IProp = {
    type: string;
    phoneCode?: string,
    phoneNumber?: string | null
}

const SignUp = ({ type, phoneCode = "+254", phoneNumber }: IProp) => {
    const dispatch = useAppDispatch();
    const logindata = useAppSelector((state) => state.auth);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPending, setIsPending] = useState(false)
    const [selectedPrefix, setSelectedPrefix] = useState('');

    type Inputs = z.infer<typeof signupSchema>;

    const form = useForm<Inputs>({
        resolver: zodResolver(signupSchema),
        defaultValues: {
            phone_number: logindata?.phone_number,
            national_id: "",
            name_prefix: "",
            first_name: "",
            last_name: "",
            gender: "",
            date_of_birth: "",
            city: "",
            email: "",
        },
    });

    useEffect(() => {
        dispatch(getCityList());
    }, [dispatch]);

    const onSubmit = async (data: any) => {
        setIsPending(true)
        try {
            const response = await API_CONSTANTS.AUTHENTICATION_AXIOS.patch(
                END_POINTS.SIGNUP,
                {
                    city_id: data.city,
                    country_code: logindata?.country_code,
                    date_birth: data.date_of_birth,
                    email: data.email,
                    name_prefix: selectedPrefix,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    os_type: "3",
                    phone_id: getPhoneId(),
                    phone_code: logindata?.phone_code,
                    phone_number: data.phone_number,
                    sex: data.gender,
                    user_role: 4,
                    user_status: "1",
                    national_id: data.national_id,
                }
            );

            if (response.status === 200) {
                let userData: any = {
                    user_status: response.data.user_status.toString(),
                    id: response.data.id,
                    token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    city_id: response.data.city_id,
                };
                userData.jwt_token = response.data.jwt_token;
                const jsonString = JSON.stringify(userData);
                Cookies.set("userData", jsonString, { expires: 7 });
                Cookies.set(
                    "user_name",
                    `${response.data.first_name} ${response.data.last_name}`
                );
                setIsModalOpen(true);
                setIsPending(false)
                form.reset()
            }
        } catch (error: any) {
            if (typeof error === "object" && error !== null && "response" in error) {
                const axiosError = error as {
                    response?: { data?: { message: string } };
                };

                const errorMessage = axiosError.response?.data?.message
                    ? axiosError.response?.data?.message
                    : "An unknown error occurred";
            }
            const { response } = error;

            console.log("message", response?.data?.messages[0], error);
            showAlert(2, response?.data?.messages[0]);
            setIsPending(false)
        }
    };

    const handleDateChange = (date: Date | null) => {
        form.setValue(
            "date_of_birth",
            date ? date.toISOString().split("T")[0] : ""
        );
        form.trigger("date_of_birth");
    };

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return (
        <AuthLayout type={type}>
            <div className="flex flex-col gap-3 text-navy mb-9">
                <h2 className="text-[32px] font-semibold">Create your account.</h2>
                <p className="font-medium text-[18px]">
                    Please enter your details here to proceed.
                </p>
            </div>

            <Form {...form}>
                <form
                    className="flex flex-col gap-7"
                    onSubmit={(...args) => {
                        void form.handleSubmit(onSubmit)(...args);
                    }}
                >
                    <div className="flex gap-4">
                        <FormField
                            control={form.control}
                            name="phone_number"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Phone Number
                                    </FormLabel>
                                    {
                                        type === "guestRegister" ?
                                            <FormControl>
                                                <div className="flex items-center border rounded-md p-2 bg-gray-100 h-[56px] w-full">
                                                    <img
                                                        src={getImage("/images/flags/32x32/ke.png", images.PLACEHOLDER)}
                                                        alt="Country Flag"
                                                        className="w-6 h-6 mr-2"
                                                    />
                                                    <span className="font-normal text-sm">
                                                        {`+${logindata?.phone_code || phoneCode}`}
                                                    </span>
                                                    <Input
                                                        fieldState={fieldState}
                                                        defaultValue={logindata?.phone_number}
                                                        value={field.value}
                                                        disabled
                                                        className="ml-2 flex-grow border-none bg-transparent outline-none"
                                                    />
                                                </div>
                                            </FormControl>
                                            :
                                            <FormControl>
                                                <div className="flex items-center border rounded-md p-2 bg-gray-100 h-[56px] w-full">
                                                    <img
                                                        src={getImage(logindata?.flag, images.PLACEHOLDER)}
                                                        alt="Country Flag"
                                                        className="w-6 h-6 mr-2"
                                                    />
                                                    <span className="font-normal text-sm">
                                                        {`+${logindata?.phone_code || phoneCode}`}
                                                    </span>
                                                    <Input
                                                        fieldState={fieldState}
                                                        defaultValue={logindata?.phone_number}
                                                        value={field.value}
                                                        disabled
                                                        className="ml-2 flex-grow border-none bg-transparent outline-none"
                                                    />
                                                </div>
                                            </FormControl>}
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="national_id"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        National ID Number
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            fieldState={fieldState}
                                            placeholder="Enter National ID Number"
                                            {...field}
                                            maxLength={15}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>


                    <div className="flex gap-4">
                        <FormField
                            control={form.control}
                            name="name_prefix"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full flex-[2]">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Title
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex items-center border border-[#CBCAD7] rounded-md signup-first-name cursor-pointer">
                                            <SelectDropdown
                                                options={namePrefixes}
                                                value={namePrefixes.find(
                                                    (option) => option.value === field.value
                                                )}
                                                onChange={(option: { value: any }) => {
                                                    field.onChange(option?.value);
                                                    setSelectedPrefix(option ? option.value : '');
                                                }}
                                                placeholder="Title"
                                                customStyles={customStyles}
                                                className="border-none outline-none"

                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="first_name"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full flex-[5]">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        First Name
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            fieldState={fieldState}
                                            placeholder="Enter First Name"
                                            className="border border-[#CBCAD7] focus-visible:ring-transparent"
                                            {...field}
                                            onKeyPress={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.target.value;
                                                if (/^[a-zA-Z]*$/.test(value)) {
                                                    field.onChange(value);
                                                }
                                            }}
                                            maxLength={50}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="last_name"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full flex-[5]">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Last Name
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            fieldState={fieldState}
                                            placeholder="Enter Last Name"
                                            {...field}
                                            onKeyPress={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.target.value;
                                                if (/^[a-zA-Z]*$/.test(value)) {
                                                    field.onChange(value);
                                                }
                                            }}
                                            maxLength={50}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>


                    <div className="flex gap-4">
                        <FormField
                            control={form.control}
                            name="gender"
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Gender
                                    </FormLabel>
                                    <FormControl>
                                        <SelectDropdown
                                            options={genderOptions}
                                            value={genderOptions.find(
                                                (option) => option.value === field.value
                                            )}
                                            onChange={(option: { value: any; }) => field.onChange(option?.value)}
                                            // classNamePrefix="react-select"
                                            // className="border border-[#CBCAD7] rounded-md h-[56px] pt-2 text-[#333333B2] text-base font-medium"
                                            placeholder="Select Gender"
                                            customStyles={customStyles}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="date_of_birth"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Date of Birth
                                    </FormLabel>
                                    <FormControl>
                                        <DatePicker
                                            className="border border-[#CBCAD7] rounded-md h-[56px] text-[#333333B2] text-base font-medium w-full pl-5 focus-visible:ring-none focus-visible:outline-none dropdown-cover"
                                            selected={field.value ? new Date(field.value) : null}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={maxDate}
                                            placeholderText="Select a date"
                                            showYearDropdown
                                            scrollableMonthYearDropdown
                                            filterDate={(date) => date <= minDate}
                                            dropdownMode="select"
                                            showMonthDropdown
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            locale="en-gb"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className="flex gap-4">

                        <FormField
                            control={form.control}
                            name="city"
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        City
                                    </FormLabel>
                                    <CityAutocomplete
                                        handleCityChange={(selectedCity) => {
                                            field.onChange(selectedCity?.value);
                                        }}
                                        customStyles={customStyles}
                                    // className="border border-[#CBCAD7] rounded-md h-[56px] pt-2 text-[#333333B2] text-base font-medium outline-none"
                                    // value={field.value ? { label: cityList.find((city: { id: string; }) => city.id === field.value)?.name, value: field.value } : null}
                                    />
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field, fieldState }) => (
                                <FormItem className="flex flex-col w-full">
                                    <FormLabel className="font-medium text-sm text-navy">
                                        Email
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            fieldState={fieldState}
                                            placeholder="Enter Email"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <button
                        type="submit"
                        className="bg-[#23901E] rounded-lg text-white font-semibold text-base w-full h-[52px] mt-3"
                    >
                        {/* Create Account */}
                        {isPending ? (
                            <Icons.spinner
                                className="h-6 w-4 animate-spin w-full"
                                aria-hidden="true"
                            />
                        ) :
                            'Create Account'
                        }
                    </button>


                    <p className="text-sm text-navy text-center">
                        By Signing up, you agree to Safaricom{" "}
                        <span className="text-seaGreen underline">
                            Terms and Conditions
                        </span>{" "}
                        and that you are over 18 years of age.
                    </p>
                    <SignUpModal
                        ModalOpen={isModalOpen}
                        setModalOpen={setIsModalOpen}
                    />
                </form>
            </Form>
        </AuthLayout>
    );
};

export default SignUp;

