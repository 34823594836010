import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layout/DashboardLayout";
import errorImg from "./../../assets/404-error.svg";

function PageNotFound() {
    return (
        <>
            <DashboardLayout>
                <div className="flex flex-col items-center justify-center min-h-screen text-center px-5 py-20">
                    <h1 className="text-[#152567]-600 font-bold text-[90px] m-0">404</h1>
                    <h2 className="text-[#152567]-600 font-bold text-[30px] mt-0">Page not found.</h2>
                    <p className="text-gray-500 font-semibold">Sorry, we couldn't find the page you were looking for.</p>
                    <p className="text-gray-500 font-semibold">We suggest you return to the home page.</p>
                    <Link
                        className="mt-6 py-2 px-4 bg-transparent border border-seaGreen text-seaGreen rounded hover:bg-seaGreen hover:text-white transition-colors"
                        to="/"
                    >
                        Back to Home
                    </Link>
                </div>
            </DashboardLayout>
        </>
    );
}

export default PageNotFound;
