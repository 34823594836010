import React from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogContent } from "../../../../ui/dialog";

function CallEndByPatient(props) {
  const { handleOk, patientName, duration, openDialog } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);

  return (
    <Dialog className="popup-cover" open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <h3 className="text-2xl font-semibold text-navy">
          Consultation finished
        </h3>
        <div className="my-6 text-base font-medium text-navy">
          <p>{patientName ? patientName : "User"} has ended the call.</p>
          <p>
            Duration of Consultation:&nbsp;
            <span>
              {duration?.hour == 0 ? "" : duration?.hour + " " + "hours" + " "}
            </span>
            <span>
              {duration?.minutes == 0
                ? ""
                : duration?.minutes + " " + "minutes" + " "}
            </span>
            <span>
              {duration?.seconds == 0
                ? ""
                : duration?.seconds + " " + "seconds" + " "}
            </span>
          </p>
        </div>
        <div className="popup-footer">
          <button
            className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5"
            onClick={handleOk}
          >
            Ok
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CallEndByPatient;
