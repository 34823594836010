// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import images from "../../utils/images";
// import { useAppSelector } from "../../redux/store/hooks";
// import { RootState } from "../../redux/store/store";

// const Footer: React.FC = () => {
//   const navigate = useNavigate();

//   const InsuranceList = useAppSelector(
//     (state) => state.insuranceList.insurance_companies
//   );

//   const { patientHomeData } = useAppSelector(
//     (state: RootState) => state.patientHome
//   );

//   const { specialization_data: specializationList } = useAppSelector(
//     (state: RootState) => state.specializationList
//   );

//   console.log("specialization_data", patientHomeData);

//   return (
//     <footer className={`${patientHomeData?.is_provider ? `bg-[${patientHomeData?.body_data?.footer_bg_color}]` : "bg-[#161C28]"}  py-10 md:py-[88px]`}>
//       <div className={`container-box ${patientHomeData?.is_provider ? `text-${patientHomeData?.body_data?.footer_txt_color}` : "text-white"}`}>
//         <div className="flex justify-between items-start flex-col md:flex-row">
//           <div className="flex flex-col gap-6 md:max-w-[450px]">
//             <div>
//               <img src={images.LOGODARK} alt="logo" />
//             </div>

//             <p className="md:font-medium">
//               A customized online platform made for Alternative Health with
//               workflows to engage clients before and after their visits.
//             </p>

//             <ul className="font-medium md:font-semibold text-sm md:text-[15px] flex flex-col gap-4">
//               <li className="flex items-center gap-[10px]">
//                 <img src={images.LOCATION} alt="location" className="text-red" /> Old Town, Mombasa,
//                 Kenya
//               </li>
//               <li className="flex items-center gap-[10px] underline">
//                 <img src={images.MAIL} alt="mail" />
//                 <a href="mailto:gothospitalreception@yopmail.com">
//                   gothospitalreception@yopmail.com
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <div className="flex flex-col md:flex-row gap-14 w-full md:w-auto mt-14 md:mt-0">
//             <div className="flex flex-col gap-4 md:gap-8">
//               <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
//                 Links
//               </h2>
//               <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-8">
//                 <li>
//                   <a
//                     href="https://www.liviaapp.com/about-livia/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     FAQs
//                   </a>
//                 </li>
//                 <li
//                   className="cursor-pointer"
//                   onClick={() => navigate("/consult-doctor")}
//                 >
//                   Doctors
//                 </li>
//                 <li className="cursor-pointer">
//                   <a
//                     href="https://www.liviaapp.com/about-livia/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     About Us
//                   </a>
//                 </li>
//               </ul>
//             </div>

//             <div className="flex flex-col gap-4 md:gap-8">
//               <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
//                 Specialities
//               </h2>
//               <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-8">
//                 {specializationList &&
//                   specializationList
//                     .slice(0, 4)
//                     .map((data) => <li className="cursor-pointer" key={data.id} onClick={() => navigate(`/consult-doctor/spec/${data.id}`)}>{data.name}</li>)}
//                 <li>
//                   <Link to="/specialities" className={`${patientHomeData?.is_provider ? `text-${patientHomeData?.body_data?.footer_txt_color}` : "text-white"} underline`}>
//                     View all
//                   </Link>
//                 </li>
//               </ul>
//             </div>

//             <div className="flex flex-col gap-4 md:gap-6">
//               <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
//                 Insurances
//               </h2>
//               <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-6">
//                 {InsuranceList &&
//                   InsuranceList.slice(0, 4).map((data) => (
//                     <li className="flex items-center gap-[10px]">
//                       <img
//                         src={images.LIVIAFOOTER}
//                         alt="livia"
//                         className="w-[24px] h-[24px] rounded-[50%] md:w-[36px] md:h-[36px]"
//                       />
//                       {data?.name}
//                     </li>
//                   ))}
//                 <li className="pl-9 md:pl-12">
//                   <Link to="/consult-doctor" className={`${patientHomeData?.is_provider ? `text-${patientHomeData?.body_data?.footer_txt_color}` : "text-white"} underline`}>
//                     View all
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>

//         <p className="text-center text-sm md:text-base border-t border-[#FFFFFF1F] py-10 font-semibold mt-14 md:mt-10">
//           Are you a Provider?{" "}
//           <Link to="/login" className="underline">
//             Click here
//           </Link>{" "}
//           for Login or Signup as Provider
//         </p>

//         <div className="flex flex-col md:flex-row items-center justify-between gap-14 md:gap-0">
//           <ul className="flex items-center gap-3">
//             <li className="font-semibold text-xl md:text-2xl mr-2">
//               Follow us on
//             </li>
//             <li>
//               <a
//                 href="https://www.liviaapp.com/livia-health/#"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img src={images.INSTAGRAM} alt="instagram" />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://www.facebook.com/liviaapp/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img src={images.FACEBOOK} alt="facebook" />
//               </a>
//             </li>
//             <li>
//               <a
//                 href="https://twitter.com/LiviaappKE"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <img src={images.TWITTER} alt="twitter" />
//               </a>
//             </li>
//           </ul>

//           <p className="font-semibold text-sm md:text-base">
//             Copyright © 2024{" "}
//           </p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../utils/images";
import { useAppSelector } from "../../redux/store/hooks";
import { RootState } from "../../redux/store/store";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const InsuranceList = useAppSelector(
    (state) => state.insuranceList.insurance_companies
  );

  const { patientHomeData } = useAppSelector(
    (state: RootState) => state.patientHome
  );

  const { specialization_data: specializationList } = useAppSelector(
    (state: RootState) => state.specializationList
  );

  console.log("specialization_data", patientHomeData);

  return (
    <footer
      style={{
        backgroundColor: patientHomeData?.is_provider
          ? patientHomeData?.body_data?.footer_bg_color
          : "#161C28",
      }}
      className="py-10 md:py-[88px]"
    >
      <div
        className="container-box"
        style={{
          color: patientHomeData?.is_provider
            ? patientHomeData?.body_data?.footer_txt_color
            : "white",
        }}
      >
        <div className="flex justify-between items-start flex-col md:flex-row">
          <div className="flex flex-col gap-6 md:max-w-[450px]">
            <div>
              <img src={images.LOGODARK} alt="logo" className="h-[40px]" />
            </div>

            <p className="md:font-medium">
              A customized online platform made for Alternative Health with
              workflows to engage clients before and after their visits.
            </p>

            <ul className="font-medium md:font-semibold text-sm md:text-[15px] flex flex-col gap-4">
              <li className="flex items-center gap-[10px]">
                <img
                  src={images.LOCATION}
                  alt="location"
                  className="text-red"
                />{" "}
                Old Town, Mombasa, Kenya
              </li>
              <li className="flex items-center gap-[10px] underline">
                <img src={images.MAIL} alt="mail" />
                <a href="mailto:gothospitalreception@yopmail.com">
                  gothospitalreception@yopmail.com
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col md:flex-row gap-14 w-full md:w-auto mt-14 md:mt-0">
            <div className="flex flex-col gap-4 md:gap-8">
              <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
                Links
              </h2>
              <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-8">
                <li>
                  <a
                    href="https://www.liviaapp.com/about-livia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQs
                  </a>
                </li>
                <li
                  className="cursor-pointer"
                  onClick={() => navigate("/consult-doctor")}
                >
                  Doctors
                </li>
                <li className="cursor-pointer">
                  <a
                    href="https://www.liviaapp.com/about-livia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-4 md:gap-8">
              <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
                Specialities
              </h2>
              <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-8">
                {specializationList &&
                  specializationList.slice(0, 4).map((data) => (
                    <li
                      className="cursor-pointer"
                      key={data.id}
                      onClick={() =>
                        navigate(`/consult-doctor/spec/${data.id}`)
                      }
                    >
                      {data.name}
                    </li>
                  ))}
                <li>
                  <Link
                    to="/specialities"
                    style={{
                      color: patientHomeData?.is_provider
                        ? patientHomeData?.body_data?.footer_txt_color
                        : "white",
                    }}
                    className="underline"
                  >
                    View all
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-4 md:gap-6">
              <h2 className="text-base md:text-2xl font-semibold md:font-bold border-b border-[#FFFFFF1F] md:border-none pb-4 md:pb-0">
                Insurances
              </h2>
              <ul className="text-sm md:text-base font-medium flex flex-col gap-5 md:gap-6">
                {InsuranceList &&
                  InsuranceList.slice(0, 4).map((data) => (
                    <li className="flex items-center gap-[10px]" key={data.id}>
                      <img
                        src={images.LIVIAFOOTER}
                        alt="livia"
                        className="w-[24px] h-[24px] rounded-[50%] md:w-[36px] md:h-[36px]"
                      />
                      {data?.name}
                    </li>
                  ))}
                <li className="pl-9 md:pl-12">
                  <Link
                    to="/consult-doctor"
                    style={{
                      color: patientHomeData?.is_provider
                        ? patientHomeData?.body_data?.footer_txt_color
                        : "white",
                    }}
                    className="underline"
                  >
                    View all
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <p className="text-center text-sm md:text-base border-t border-[#FFFFFF1F] py-10 font-semibold mt-14 md:mt-10">
          Are you a Provider?{" "}
          <Link to="/login" className="underline">
            Click here
          </Link>{" "}
          for Login or Signup as Provider
        </p>

        <div className="flex flex-col md:flex-row items-center justify-between gap-14 md:gap-0">
          <ul className="flex items-center gap-3">
            <li className="font-semibold text-xl md:text-2xl mr-2">
              Follow us on
            </li>
            <li>
              <a
                href="https://www.liviaapp.com/livia-health/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images.INSTAGRAM} alt="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/liviaapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images.FACEBOOK} alt="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/LiviaappKE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images.TWITTER} alt="twitter" />
              </a>
            </li>
          </ul>

          <p className="font-semibold text-sm md:text-base">
            Copyright © 2024{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
