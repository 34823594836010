import ExpertsBanner from "../assets/images/experts-banner.png";
import ExpertsCards from "../assets/images/experts-cards.png";
import Eye from "../assets/icons/eye.svg";
import Star from "../assets/icons/star.svg";
import Info from "../assets/icons/info.svg";
import HeroImg from "../assets/images/hero-banner.png";
import Location from "../assets/icons/location.svg";
import Mail from "../assets/icons/mail.svg";
import Instagram from "../assets/icons/instagram.svg";
import Facebook from "../assets/icons/facebook.svg";
import Twitter from "../assets/icons/twitter.svg";
import LiviaFooter from "../assets/images/livia-footer.svg";
import Placeholder from "../assets/images/defaultplaceholder.svg";
import ArrowRightWhite from "../assets/icons/arrow-right-white.svg";
import BookAppointment from "../assets/images/book-appointment.png";
import LoginSignUp from "../assets/images/login-signup.svg";
import ExpertsTeamAvatar from "../assets/images/experts-team-avatar.png";
import OnlineStatus from "../assets/icons/online.svg";
import OfflineStatus from "../assets/icons/Offline-Status.svg";
import Dollar from "../assets/icons/dollar.svg";
import DoctorPlaceholder from "../assets/images/doctor-placeholder.png";
import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";
import LoginBanner from "../assets/images/login-banner.png";
import FilterApplied from "../assets/icons/FilterAppliedIcon.png";
import Congratulations from "../assets/icons/congratulations.svg";
import Experience from "../assets/icons/experience.svg";
import Degree from "../assets/icons/degree.svg";
import LocationGreen from "../assets/icons/location-green.svg";
import Logout from "../assets/images/LogoutIcon.svg";
import Calendar from "../assets/icons/calendar.svg";
import OnlineVideo from "../assets/icons/video.svg";
import InClick from "../assets/icons/In Clinit.svg";
import Messages from "../assets/icons/messages.svg";
import Bms from "../assets/icons/bms.svg";
import Close from "../assets/icons/filters-close.svg";
import ClinicDefault from "../assets/icons/inclinic-default.svg";
import ClinicActive from "../assets/icons/inclinic-active.svg";
import OnlineAppActive from "../assets/icons/online-appointment-active.svg";
import OnlineAppDefault from "../assets/icons/online-appointment-default.svg";
import CarouselArrowLeft from "../assets/icons/carousel-arrow-left.svg";
import CarouselArrowRight from "../assets/icons/carousel-arrow-right.svg";
import Cards from "../assets/icons/cards.svg";
import Mpesa from "../assets/icons/m-pesa.svg";
import Check from "../assets/icons/check.svg";
import SpecialityBanner from "../assets/images/speciality-banner.svg";
import Insurance from "../assets/images/insurance.svg";
import DropdownWhite from "../assets/icons/dropdown-white.svg";
import DropdownBlack from "../assets/icons/dropdown-black.svg";
import MyProfile from "../assets/icons/my-profile.svg";
import LogoutIcon from "../assets/icons/logout.svg";
import Upload from "../assets/icons/upload.svg";
import Delete from "../assets/icons/delete.svg";
import Image from "../assets/icons/image.svg";
import Edit from "../assets/icons/edit.svg";
import Professionalism from "../assets/icons/professionalism.svg";
import Punctuality from "../assets/icons/punctuality.svg";
import Appearance from "../assets/icons/appearance.svg";
import PhysicianCare from "../assets/icons/physician-care.svg";
import SortByIcon from "../assets/icons/sort-by.svg";
import Heartbeat from "../assets/icons/heartbeat.svg";
import ListingBanner from "../assets/images/listing-banner.png";
import ListingBannerMobile from "../assets/images/listing-banner-mobile.png";
import InsurancePlaceholder from "../assets/images/insurance-placeholder.png";
import PatientImage from "../assets/calling-icons/patient_placeholder.svg";

const images = {
  EXPERTSBANNER: ExpertsBanner,
  EXPERTSCARDS: ExpertsCards,
  EYE: Eye,
  STAR: Star,
  INFO: Info,
  HEROIMG: HeroImg,
  LOCATION: Location,
  MAIL: Mail,
  INSTAGRAM: Instagram,
  FACEBOOK: Facebook,
  TWITTER: Twitter,
  LIVIAFOOTER: LiviaFooter,
  PLACEHOLDER: Placeholder,
  ARROWRIGHTWHITE: ArrowRightWhite,
  BOOKANAPPOINTMENT: BookAppointment,
  LOGINSIGNUP: LoginSignUp,
  EXPERTSTEAMAVATAR: ExpertsTeamAvatar,
  ONLINE: OnlineStatus,
  OFFLINE: OfflineStatus,
  DOLLAR: Dollar,
  DOCTORPLACEHOLDER: DoctorPlaceholder,
  LOGOLIGHT: LogoLight,
  LOGODARK: LogoDark,
  LOGINBANNER: LoginBanner,
  FILTERAPPLIED: FilterApplied,
  CONGRATULATIONS: Congratulations,
  EXPERIENCE: Experience,
  DEGREE: Degree,
  LOCATIONGREEN: LocationGreen,
  LOGOUT: Logout,
  CALENDAR: Calendar,
  VIDEO: OnlineVideo,
  INCLINIC: InClick,
  MESSAGES: Messages,
  BMS: Bms,
  CLOSE: Close,
  CLINICDEFAULT: ClinicDefault,
  CLINICACTIVE: ClinicActive,
  ONLINEAPPACTIVE: OnlineAppActive,
  ONLINEAPPDEFAULT: OnlineAppDefault,
  CAROUSELARROWLEFT: CarouselArrowLeft,
  CAROUSELARROWRIGHT: CarouselArrowRight,
  CARDS: Cards,
  MPESA: Mpesa,
  CHECK: Check,
  SPECIALITYBANNER: SpecialityBanner,
  INSURANCE: Insurance,
  DROPDOWNBLACK: DropdownBlack,
  DROPDOWNWHITE: DropdownWhite,
  MYPROFILE: MyProfile,
  LOGOUTICON: LogoutIcon,
  UPLOAD: Upload,
  DELETE: Delete,
  IMAGE: Image,
  EDIT: Edit,
  PROFESSIONALISM: Professionalism,
  PUNCTUALITY: Punctuality,
  APPEARANCE: Appearance,
  PHYSICIANCARE: PhysicianCare,
  SORTBYICON: SortByIcon,
  HEARTBEAT: Heartbeat,
  LISTING_BANNER: ListingBanner,
  LISTING_BANNER_MOBILE: ListingBannerMobile,
  INSURANCE_PLACEHOLDER: InsurancePlaceholder,
  PATIENT_IMAGE: PatientImage,
};
export default images;
