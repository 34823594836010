export const IMG_URL = process.env.NEXT_PUBLIC_DEV_MEDIA_CDN;
export const apiParamsOfDoctorsConstant = {
  limit: 12,
  offset: 0,
  search: "",
  specialization: " ",
  insurance: "",
  location: "",
  sortBy: "",
  city: "",
};

export const apiParamsAppoinmentConstant = {
  limit: 12,
  offset: 0,
  // sortBy: "",
  // city: "",
  filter: "",
};

export const apiParamsSpecialityConstant = {
  limit: 12,
  offset: 0,
  };

export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const namePrefixes = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Prof.", label: "Prof." },
];

export const patientInsuraceStatus = {
  ACTIVE: 1,
  POLICY_EXPIRED: 2,
  BALANCE_INSUFFICIENT: 3,
  BENEFITS_UPDATING: 4,
  DOCTOR_NOT_HAVE_SPECIALIZATION: 5,
  TIME_OUT_ERROR: 8,
};

export const appointmentEventStatus = {
  BOOKING: 1,
  EVENT: 2,
  REMINDER: 3,
};


export const appointmentStatusFilterConstant = [
  // { key: 'appointmentFil1', text: 'Reschedule', value: -1 },
  { key: "appointmentFil2", text: "Pending doctor's approval", value: 1 },
  { key: "appointmentFil3", text: "Approved", value: 2 },
  { key: "appointmentFil4", text: "Declined by doctor", value: 3 },
  { key: "appointmentFil5", text: "Finished", value: 4 },
  { key: "appointmentFil6", text: "Declined by you", value: 5 },
  { key: "appointmentFil7", text: "Available", value: 6 },
  { key: "appointmentFil8", text: "Blocked", value: 7 },
  { key: "appointmentFil9", text: "Pending patient's approval", value: 8 },
  { key: "appointmentFil10", text: "Waiting for payment approval", value: 9 },
  { key: "appointmentFil11", text: "Payment Unsuccessful", value: 10 },
  { key: "appointmentFil12", text: "Refund provided", value: 11 },
  { key: "appointmentFil13", text: "Reported", value: 12 },
];

export const appointmentStatusConstantForText = {
  NeedToReschedule: "-1",
  WaitingFromDoctor: "1",
  Approved: "2",
  DeclinedByDoctor: "3",
  Ended: "4",
  DeclinedByCustomer: "5",
  Free: "6",
  Blocked: "7",
  WaitingFromCustomer: "8",
  WaitingFromPaymentApproval: "9",
  PaymentUnsuccesful: "10",
  RefundProvided: "11",
  Reported: "12",
};


export const appointmentStatusSentenceFilterConstant = [
  // { key: 'appointmentFil1', text: 'Reschedule', value: -1 },
  { key: "appointmentFil2", text: "Pending doctor's approval", value: 1 },
  {
    key: "appointmentFil3",
    text: "Your appointment has been confirmed.",
    value: 2,
  },
  {
    key: "appointmentFil4",
    text: "Your payment is Declined by doctor",
    value: 3,
  },
  { key: "appointmentFil5", text: "Finished", value: 4 },
  { key: "appointmentFil6", text: "Declined by customer", value: 5 },
  { key: "appointmentFil7", text: "Available", value: 6 },
  { key: "appointmentFil8", text: "Blocked", value: 7 },
  { key: "appointmentFil9", text: "Pending patient's approval", value: 8 },
  { key: "appointmentFil10", text: "Waiting for payment approval", value: 9 },
  { key: "appointmentFil11", text: "Oh no, your payment failed.", value: 10 },
  { key: "appointmentFil12", text: "Refund provided", value: 11 },
  { key: "appointmentFil13", text: "Reported", value: 12 },
];



export const Status = [
  // { key: 'appointmentFil1', label: 'Reschedule', value: -1 },
  { label: "Pending doctor's approval", value: 1 },
  { label: "Approved", value: 2 },
  { label: "Declined by doctor", value: 3 },
  { label: "Finished", value: 4 },
  { label: "Declined by you", value: 5 },
  { label: "Available", value: 6 },
  { label: "Blocked", value: 7 },
  { label: "Pending patient's approval", value: 8 },
  { label: "Waiting for payment approval", value: 9 },
  { label: "Payment Unsuccessful", value: 10 },
  { label: "Refund provided", value: 11 },
  { label: "Reported", value: 12 },
];

export const allowedOnlyImageType = ["jpg", "jpeg", "png"];

