import React from "react";
import { Dialog, DialogContent } from "../../../../ui/dialog";
// import { roles } from "../../../utility/roleConstant";
// import { getRoleIdInLS } from "../../../utility/util";
// import { useSelector } from "react-redux";

function MeeetingDuration(props) {
  const { finishConsultation, duration, isChangeMessage, openDialog } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);

  return (
    <Dialog className="popup-cover" open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <div className="mt-1 mb-6 text-base font-medium text-navy text-left">
          <>
            {isChangeMessage ? (
              <p>
                Doctor is writing your prescription.
                <br /> Delivery will be fulfilled by Livia Health to your
                office.
              </p>
            ) : (
              <div className="flex flex-col gap-3">
                <p>
                  Doctor is writing your prescription and will send to Pharmacy
                  in few minutes.
                </p>
                <p>Please go to Pharmacy counter and collect your medicines.</p>
              </div>
            )}
          </>
        </div>
        <div className="popup-footer">
          <button
            className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5"
            onClick={finishConsultation}
          >
            Ok
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MeeetingDuration;
