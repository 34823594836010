import { createSlice } from '@reduxjs/toolkit';
import { ChronicCondition, Relation, UserInsuranceCompany } from '../../utils/types';
interface InitialStateType {
    id: string;
    avatar: string;
    email: string;
    phone_code: string;
    phone_number: string;
    online: string;
    latitude: string | null;
    longitude: string | null;
    user_status: string;
    country_code: string;
    is_dob_confirmed: string;
    branch_id: string | null;
    business_id: string | null;
    branch_url: string | null;
    auto_generated: string | null;
    fr_unique_id: string | null;
    country_id: string;
    country_name: string;
    currency: string | null;
    was_registered: string;
    city_id: string;
    city_name: string;
    lat: string;
    lon: string;
    role_id: string;
    role_name: string;
    os_type: string;
    app_version: string | null;
    age: string;
    chronic_condition_counselling: string | null;
    date_birth: string;
    first_name: string;
    guest_user: string | null;
    id_number: string;
    is_ethical_substitute: string;
    is_qr_action_taken: string;
    last_name: string;
    name_prefix: string;
    photo_id_number_backend: string;
    photo_id_number_frontend: string;
    sex: string;
    date_of_the_birth: string;
    city_lat: string;
    city_lon: string;
    experience_years: string | null;
    reception_phone_numbers: string[];
    insurance_company: string | null;
    chronic_conditions: string[];
    chronic_conditions_list: ChronicCondition[];
    relations: Relation[];
    show_chronic_conditions: boolean;
    insurance_companies: UserInsuranceCompany[];
    cdmp_linkage: string;
    user_insurance_companies: string;
}
const initialState: InitialStateType = {
    id: "",
    avatar: "",
    email: "",
    phone_code: "",
    phone_number: "",
    online: "",
    latitude: null,
    longitude: null,
    user_status: "",
    country_code: "",
    is_dob_confirmed: "",
    branch_id: null,
    business_id: null,
    branch_url: null,
    auto_generated: null,
    fr_unique_id: null,
    country_id: "",
    country_name: "",
    currency: null,
    was_registered: "",
    city_id: "",
    city_name: "",
    lat: "",
    lon: "",
    role_id: "",
    role_name: "",
    os_type: "",
    app_version: null,
    age: "",
    chronic_condition_counselling: null,
    date_birth: "",
    first_name: "",
    guest_user: null,
    id_number: "",
    is_ethical_substitute: "",
    is_qr_action_taken: "",
    last_name: "",
    name_prefix: "",
    photo_id_number_backend: "",
    photo_id_number_frontend: "",
    sex: "",
    date_of_the_birth: "",
    city_lat: "",
    city_lon: "",
    experience_years: null,
    reception_phone_numbers: [],
    insurance_company: null,
    chronic_conditions: [],
    chronic_conditions_list: [],
    relations: [],
    show_chronic_conditions: false,
    insurance_companies: [],
    cdmp_linkage: "",
    user_insurance_companies: ""

};

const profileSlice = createSlice({
    name: 'user-profile',
    initialState,
    reducers: {
        setProfileData(state, action) {
            return { ...state, ...action.payload };
        },
        resetProfileData(state) {
            state = initialState;
        }
    }
});

export const { setProfileData, resetProfileData } = profileSlice.actions;
export default profileSlice.reducer;
