import { createSlice } from '@reduxjs/toolkit';
import { IPatientInsurance } from '../../utils/types';

interface InitialStateType {
    patientInsuranceData:IPatientInsurance[];
    isPatientInsuranceDataLoading:boolean;
    isPatientLeftOverDataLoading:boolean;
}
const initialState:InitialStateType = {
    patientInsuranceData:[],
    isPatientInsuranceDataLoading:false,
    isPatientLeftOverDataLoading:false,
}
const patientInsuranceDataSlice = createSlice({
    name: 'insurance-list',
    initialState,
    reducers: {
        setPatientInsuranceData(state, action) {
            return { ...state, ...action.payload };
        },
        setPatientInsuranceLeftOverData(state, action) {
            return { ...state, ...action.payload };
        },
        resetPatientInsuranceData(state) {
            state = initialState;
        }
    }
});

export const { setPatientInsuranceData,setPatientInsuranceLeftOverData, resetPatientInsuranceData } = patientInsuranceDataSlice.actions;
export default patientInsuranceDataSlice.reducer;
