import axios, { AxiosError, AxiosRequestConfig } from "axios";
// import { getAuthHeader } from "./auth";
import ENV_VARIABLES from "../../utils/api/env.variable";
import { getUserToken } from "../../utils/helperfunction";

const axiosInstance = axios.create({
  baseURL: ENV_VARIABLES.API_BASE_URL,
  // timeout: 10000,
  headers: {
    "LiviaApp-country": "ke",
    "LiviaApp-language": "en",
    "LiviaApp-timezone": "330",
    "LiviaApp-APIVersion": "3.0",
  },
});

const requestHandler = (request:any) => {
  if (request.headers) request.headers.Authorization = getUserToken();
  return request;
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => Promise.reject(error)
);

const responseErrorHandler = (error: AxiosError) => {
  //* const originalRequest = error.config;
  // const errors = ['Something went wrong, please try again!'];
  if (error.response) {
    if (error.response.status === 401) {
      // log out user
      // ? or
      // call refresh token
      // const refreshToken = await refreshToken();
      // if (refreshToken.status === 200) {
      //   token = refreshToken.data.hashToken;
      //   localStorage.setItem('authToken', token);
      //   return axiosInstance(originalRequest);
      // }
    }
  } else if (error.request) {
    // ??  error request
  } else {
    // ??  error request message
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
);

export default axiosInstance;
