import React from "react";
import images from "../utils/images";
import { useNavigate } from "react-router-dom";

interface AuthLayoutProps {
  children: React.ReactNode;
  type?: string;
  // currentStep: number;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, type }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return type === "guestRegister" ? (
    <div>{children}</div>
  ) : (
    <div className="flex h-[100vh] p-5">
      <div className="w-[35%] h-full relative hidden md:inline-block">
        <img
          src={images.LOGINBANNER}
          alt="sample drop"
          className="h-full w-full object-cover rounded-2xl"
          loading="lazy"
        />
        <div className="absolute top-0 left-0 p-5">
          <button
            className="bg-seaGreen rounded-[70px] text-white font-semibold text-[14px] flex items-center justify-center gap-1.5 w-[90px] h-[32px]"
            onClick={handleBackClick}
          >
            <img
              src={images.ARROWRIGHTWHITE}
              alt="arrow"
              className="rotate-180 w-4"
            />
            Back
          </button>
          <h2 className="text-white font-medium text-[32px] mb-1.5 mt-8">
            Safaricom connects you to care, right from home
          </h2>
          <p className="text-[#FFFFFFCC] text-base font-normal italic">
            “Safaricom brings trusted doctors to you, ensuring care and comfort
            right from home.”
          </p>
        </div>
      </div>

      <main className="w-full md:w-[65%] h-full flex items-center justify-center overflow-y-auto">
        <div className="max-w-[630px] w-full">{children}</div>
      </main>
    </div>
  );
};

export default AuthLayout;
