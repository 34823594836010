import React from 'react';

const InvalidDomain: React.FC = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="text-center p-4 bg-white shadow-md rounded-lg">
                <h1 className="text-2xl font-bold text-red-600">Invalid Domain</h1>
                <p className="mt-4 text-lg">The domain you are trying to access is not valid. Please contact support.</p>
            </div>
        </div>
    );
};

export default InvalidDomain;
