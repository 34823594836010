import { setAppointmentEventResponse } from "../redux/slices/appointmentEventResponseSlice";
import { resetAppointmentSlot, setAppointmentSlot } from "../redux/slices/appointmentSlotSlice";
import { ICity, setCityList } from "../redux/slices/citySlice";
import { setCountryList } from "../redux/slices/countrySlice";
import { setInsuranceList } from "../redux/slices/insuranceListSlice";
import { setPatientInsuranceCompanyList } from "../redux/slices/patientCompanyInsuranceSlice";
import { setPatientInsuranceData } from "../redux/slices/patientInsuranceSlice";
import { resetScheduleAppointmentDetail } from "../redux/slices/scheduleAppointmentSlice";
import { setSpecializationList } from "../redux/slices/specializationSlice";
import { AppDispatch } from "../redux/store/store";
import { showAlert } from "../utils/alert";
import API_CONSTANTS from "../utils/api/api.configuration";
import END_POINTS from "../utils/api/endpoints";
import { WebsiteAuthHeaders } from "../utils/helperfunction";

export const getInsuranceList = () => {
  return (dispatch: AppDispatch) => {
    API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
      params: {
        is_insurance_company_list: 1,
      },
    })
      .then((res) => {
        dispatch(setInsuranceList(res?.data?.body));
      })
      .catch((error) => {
        const { response, message } = error;
        showAlert(2, message);
      });
  };
};


export const getPatientInsuranceCompanyList = () => {
  return (dispatch: AppDispatch) => {
    dispatch(setPatientInsuranceCompanyList({ patientInsuranceList: {}, isLoading: true }));
    API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
      params: {
        is_patient_insurance_company_list: 1,
      },
      headers: {
        ...WebsiteAuthHeaders(Headers), "LiviaApp-Url":
          "gothospital.liviaapp.net",
      }
    })
      .then((res) => {
        dispatch(setPatientInsuranceCompanyList({ patientInsuranceList: res?.data?.body, isLoading: false }));
      })
      .catch((error) => {
        const { response, message } = error;
        dispatch(setPatientInsuranceCompanyList({ doctorsListHome: {}, isLoading: false }))
        showAlert(2, message);
      });
  };
};


export const getSpecializationList = (
  offset=0,
  limit=120,
  isSetTotalRecords = false,
  isCountNeeded?: number
) => {
  return (dispatch: AppDispatch) => {
    dispatch(setSpecializationList({ specializationList: {}, isLoading: true }));

    // Create params object dynamically
    const params: any = {
      is_specialization_list: 1,
      limit,
      offset,
    };

    // Add is_count_needed only if it is passed
    if (typeof isCountNeeded !== 'undefined') {
      params.is_count_needed = isCountNeeded;
    }

    API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
      params,
    })
      .then((res) => {
        dispatch(setSpecializationList({ specialization_data: res?.data?.body?.specialization_data, isLoading: false }));
        if (isSetTotalRecords) {
          dispatch(setSpecializationList({ totalRecords: res?.data?.body?.specialization_data_count }));
        }
      })
      .catch((error) => {
        const { response, message } = error;
        dispatch(setSpecializationList({ specializationList: {}, isLoading: false }));
        showAlert(2, message);
      });
  };
};


export const getCountryList = () => {
  return (dispatch: AppDispatch) => {
    API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
      params: {
        is_countries_list: 1,
      },
    })
      .then((res) => {
        dispatch(setCountryList(res?.data?.body));
      })
      .catch((error) => {
        const { response, message } = error;
        showAlert(2, message);
      });
  };
};

export const getCityList = (
  search: string = "",
  callback?: (param: ICity[]) => void
) => {
  return (dispatch: AppDispatch) => {
    API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.CITY, {
      params: {
        limit: 10,
        country_code: "ke",
        ...(search ? { search } : {}),
      },
    })
      .then((res) => {
        dispatch(setCityList(res?.data?.body));
      })
      .catch((error) => {
        const { response, message } = error;
        showAlert(2, message);
      });
  };
};

export const addDoctorViewCount = (doctor_id: string, option: number) => {
  return (dispatch: AppDispatch) => {
    API_CONSTANTS.PATIENT_API_AXIOS.patch(END_POINTS.ADD_DOCTOR_VIEW,
      {
        doctor_id,
        option,
      },
    )
      .then((res) => {
        //do nothing
      })
      .catch((error) => {
        const { response, message } = error;
        // showAlert(2, message)
      });
  };
};

export function getPatientInsurance(doctorId: string, isFirstTime: number) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        setPatientInsuranceData({
          patientInsuranceData: [],
          isPatientInsuranceDataLoading: true,
        })
      );
      API_CONSTANTS.PATIENT_API_AXIOS.get(
        END_POINTS.PATIENT_INSURANCE_COMPANY,
        {
          params: {
            type: "patient_doctor_insurance_company",
            doctor_id: doctorId,
            is_first_time: isFirstTime,
          },
          headers: WebsiteAuthHeaders(Headers),
        }
      )
        .then((res) => {
          dispatch(
            setPatientInsuranceData({
              patientInsuranceData: res?.data?.body,
              isPatientInsuranceDataLoading: false,
            })
          );
          resolve(res);
        })
        .catch((error) => {
          const { response, message } = error;
          dispatch(
            setPatientInsuranceData({
              patientInsuranceData: [],
              isPatientInsuranceDataLoading: false,
            })
          );
          showAlert(2, message);
          reject(error);
        });
    });
  };
}

export function getPatientInsuranceLeftOverData(
  doctorId: string,
  isFirstTime: number
) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(setPatientInsuranceData({ isPatientLeftOverDataLoading: true }));
      API_CONSTANTS.PATIENT_API_AXIOS.get(
        END_POINTS.PATIENT_INSURANCE_COMPANY,
        {
          params: {
            type: "patient_doctor_insurance_company",
            doctor_id: doctorId,
            is_first_time: isFirstTime,
          },
          headers: WebsiteAuthHeaders(Headers),
        }
      )
        .then((res) => {
          dispatch(
            setPatientInsuranceData({
              patientInsuranceData: res?.data?.body,
              isPatientLeftOverDataLoading: false,
            })
          );
          resolve(res);
        })
        .catch((error) => {
          const { response, message } = error;
          dispatch(
            setPatientInsuranceData({ isPatientLeftOverDataLoading: false })
          );
          showAlert(2, message);
          reject(error);
        });
    });
  };
}

export function getApppointmentSlot(payload: any) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        setAppointmentSlot({
          appointmentSlotList: [],
          isAppointmentSlotLoading: true,
        })
      );
      API_CONSTANTS.PATIENT_API_AXIOS.post(END_POINTS.CALENDER, payload, {
        params: {
          from_patient_portal: 1,
        },
        headers: WebsiteAuthHeaders(Headers),
      })
        .then((res) => {
          dispatch(
            setAppointmentSlot({
              appointmentSlotList: res?.data?.body,
              isAppointmentSlotLoading: false,
            })
          );
          resolve(res);
        })
        .catch((error) => {
          const { response, message } = error;
          dispatch(
            setAppointmentSlot({
              appointmentSlotList: [],
              isAppointmentSlotLoading: false,
            })
          );
          //   showAlert(2, message);
          //   reject(error);
        });
    });
  };
}

export function createAppointmentEvent(payload: any) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        setAppointmentEventResponse({
          appappointmentEventData: null,
          isAppointmentEventAPILoading: true,
        })
      );
      API_CONSTANTS.PATIENT_API_AXIOS.put(END_POINTS.EVENT, payload, {
        headers: WebsiteAuthHeaders(Headers),
      })
        .then((res) => {
          dispatch(
            setAppointmentEventResponse({
              appappointmentEventData: res?.data,
              isAppointmentEventAPILoading: false,
            })
          );
          dispatch(resetScheduleAppointmentDetail())
          showAlert(1, "Appointment created successfully");
          resolve(res?.data);

        })
        .catch((error) => {
          const { response, message } = error;
          console.log(response)
          showAlert(2, response?.data?.message[0]);
          dispatch(
            resetScheduleAppointmentDetail()
          );
          dispatch(
            setAppointmentEventResponse({
              appappointmentEventData: null,
              isAppointmentEventAPILoading: false,
            })
          );
        });
    });
  };
}

export function getSlotForConnectNow(doctor_id: string, hospital_id: string) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.EVENT, {
        params: { doctor_id, hospital_id },
        headers: WebsiteAuthHeaders(Headers),
      })
        .then((res) => {
          resolve(res?.data);

        })
        .catch((error) => {
          const { response, message } = error;
          console.log(response)
          showAlert(2, response?.data?.message[0]);
        });
    });
  };
}

