import { combineReducers } from "redux";
import authReducer from "../slices/authSlice";
import countryReducer from "../slices/countrySlice";
import patientHomeReducer from "../slices/patientHomeSlice";
import globalLoaderReducer from "../slices/globalLoaderSlice";
import doctorListReducer from "../slices/doctorListSlice";
import insuranceListReducer from "../slices/insuranceListSlice";
import specializationReducer from "../slices/specializationSlice";
import userRatingReducer from "../slices/userRatingSlice";
import cityListReducer from "../slices/citySlice";
import DoctorDetailsReducer from "../slices/doctorDetailsSlice";
import scheduleAppointmentReducer from "../slices/scheduleAppointmentSlice";
import patientInsuranceReducer from "../slices/patientInsuranceSlice";
import appointmentSlotReducer from "../slices/appointmentSlotSlice";
import appointmentEventResponseReducer from "../slices/appointmentEventResponseSlice";
import chimeMeetingReducer from "../slices/chimeMeetingSlice";
import appointmentDetailReducer from "../slices/appointmentDetailSlice";
import { doctorSlice } from "../../components/MeetingChime/meeting.slice";
import { SocketReducer } from "../../components/MeetingChime/socketReducer";
import { videoCallNotifierReducer } from "../../components/MeetingChime/videoCallNotifierReducer";
import { WebsiteSocketReducer } from "../../components/MeetingChime/WebsiteSocket";
import { WebsiteMeetingReducer } from "../../components/reducer/websiteMeetingReducer";
import { AppointmentsDetailReducer } from "../../components/reducer/myAppointmentDetailReducer";
import appoinmentListReducer from "../slices/appointmentListSlice";
import patientInsuranceCompanyReducer from "../slices/patientCompanyInsuranceSlice";
import profileReducer from "../slices/profileSlice";
import addInsuranceReducer from "../slices/addInsuranceSlice";




const rootReducer = combineReducers({
  auth: authReducer,
  patientHome: patientHomeReducer,
  globalLoader: globalLoaderReducer,
  doctorList: doctorListReducer,
  insuranceList: insuranceListReducer,
  countryList: countryReducer,
  specializationList: specializationReducer,
  userRatingList: userRatingReducer,
  cityList: cityListReducer,
  doctorDetails: DoctorDetailsReducer,
  scheduleAppointment: scheduleAppointmentReducer,
  patientInsuranceData: patientInsuranceReducer,
  patientInsuranceCompanyList: patientInsuranceCompanyReducer,
  appointmentSlot: appointmentSlotReducer,
  appointmentEventResponse: appointmentEventResponseReducer,
  chimeMeetingData: chimeMeetingReducer,
  appointmentDetail: appointmentDetailReducer,
  doctor: doctorSlice,
  appotinmetList: appoinmentListReducer,
  SocketReducer: SocketReducer,
  videoCallNotifierReducer: videoCallNotifierReducer,
  websiteAppointmentDetail: AppointmentsDetailReducer,
  websiteMeeting: WebsiteMeetingReducer,
  WebsiteSocketReducerNotification: WebsiteSocketReducer,
  profileDetails: profileReducer,
  addInsuranceDetail:addInsuranceReducer
})

export default rootReducer;
