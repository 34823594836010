import { createSlice } from '@reduxjs/toolkit';
import { IUserRating } from '../../utils/types';

const initialState:{userRatingList:IUserRating[], isUserRatingLoading:boolean} = {
    userRatingList:[],
    isUserRatingLoading:false
    
};

const userRatingSlice = createSlice({
    name: 'userRating',
    initialState,
    reducers: {
        setUserRating(state, action) {
            return { ...state, ...action.payload };
        },
        resetUserRating(state) {
            state = initialState;
        }
    }
});

export const { setUserRating, resetUserRating } = userRatingSlice.actions;
export default userRatingSlice.reducer;
