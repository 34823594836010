import { createSlice } from '@reduxjs/toolkit';
import { IDoctor, IInsurance } from '../../utils/types';


interface InsuranceCompany {
    id: string;
    name: string;
    avatar: string;
    insufficient_balance_amount: string;
    global_medicine_search: string;
    force_profile_update_allowed: string;
    integ_app_code: string;
    insurance_company_member_id: string;
    insurance_company_member_card: string;
    insurance_company_selfie: string;
    insurance_status: string;
    employee_number: string;
    relationship: string;
    card_type: string;
    abbreviation: string | null;
    benefits_updated_at: string;
    microscheme_id: string | null;
    other_number: string | null;
}

interface InitialStateType {
    type: number;
    countries_list: any[];
    insurance_companies: any[];
    doctors: any[];
    patient_insurance_company_list: InsuranceCompany[];
}
const initialState: InitialStateType = {
    type: 1,
    countries_list: [],
    insurance_companies: [],
    doctors: [],
    patient_insurance_company_list: []
};

const patientInsuranceCompanyListSlice = createSlice({
    name: 'patient-company-insurance-list',
    initialState,
    reducers: {
        setPatientInsuranceCompanyList(state, action) {
            return { ...state, ...action.payload };
        },
        resetPatientInsuranceCompanyList(state) {
            state = initialState;
        }
    }
});

export const { setPatientInsuranceCompanyList, resetPatientInsuranceCompanyList } = patientInsuranceCompanyListSlice.actions;
export default patientInsuranceCompanyListSlice.reducer;
