import { toast } from "react-toastify";
import MeetingService, {
  WebsiteMeetingService,
  WebsiteHomeService,
} from "../MeetingChime/meetingActionService";
// import WebsiteMeetingService from "../../service/Meeting/WebsiteMeetingService";
// import { toastIdsConstant } from "../../utils/toastConstant";
import {
  commonAction,
  WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME,
  MEETINGREDUCERNAME,
  WEBSITEMEETINGREDUCERNAME,
  relatedAction,
  WEBSITESOCKETREDUCERNAME,
  SOCKETREDUCERNAME,
} from "./actionConstant";
import { commonActionCreator } from "./commonActionCreator";
import { Navigate } from "react-router-dom";
import { showAlert } from "../../utils/alert";

export function getMeetingAction(eventId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(MEETINGREDUCERNAME)(
          commonAction.IS_API_LOADING,
          true
        )
      );
      try {
        const response = await new MeetingService().getMeeting(eventId);
        if (response.is_time_valid != 1) {
          window.location.href = `/my-appointments/detail/${eventId}`;
          showAlert(4,response.msg)
          dispatch(
            commonActionCreator(MEETINGREDUCERNAME)(
              commonAction.IS_API_LOADING,
              false
            )
          );
          return;
        }
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.SET_API_DATA,
            response
          )
        );
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        window.location.href = `/my-appointments/detail/meeting-chime/${eventId}/meeting`;
        resolve(response);
      } catch (error) {
        toast.warn(error.response.data.messages[0], {
          className: "toast-warn",
        });
        reject(error);
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.SET_API_ERROR,
            error
          )
        );
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        window.location.href = `/my-appointments/detail/${eventId}`;
      }
    });
  };
}

export function getMeetingActionWithNotification(eventId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(MEETINGREDUCERNAME)(
          commonAction.IS_API_LOADING,
          true
        )
      );
      try {
        const response = await new MeetingService().getMeetingWithNotification(
          eventId
        );
        if (response.is_time_valid != 1) {
          window.location.href = `/my-appointments/detail/${eventId}`;
          showAlert(4,response.msg)
          dispatch(
            commonActionCreator(MEETINGREDUCERNAME)(
              commonAction.IS_API_LOADING,
              false
            )
          );
          return;
        }
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.SET_API_DATA,
            response
          )
        );
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        window.location.href = `/my-appointments/detail/meeting-chime/${eventId}/meeting`;
        resolve(response);
      } catch (error) {
        toast.warn(error.response.data.messages[0], {
          className: "toast-warn",
        });
        reject(error);
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.SET_API_ERROR,
            error
          )
        );
        dispatch(
          commonActionCreator(MEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        window.location.href = `/my-appointments/detail/${eventId}`;
      }
    });
  };
}

export async function updateMeetingTimeAction(payload) {
  await new MeetingService().updateMeetingDuration(payload);
}

export function clearChimeMeetingDataAction() {
  return (dispatch) => {
    dispatch(
      commonActionCreator(MEETINGREDUCERNAME)(commonAction.SET_API_DATA, null)
    );
  };
}

export async function meetingFinishedAction(payload) {
  await new MeetingService().cancelMeeting(payload);
}

export async function meetingRejectedAction(payload, type) {
  (await type) == "doctor" && new MeetingService().callRejected(payload);
}

export async function meetingAcceptedAction(payload, type) {
  (await type) == "doctor" && new MeetingService().callAccepted(payload);
  // : new WebsiteMeetingService().websiteCallRejected(payload);
}

export async function meetingJoinedAction(payload) {
  await new MeetingService().meetingJoined(payload);
}

export function saveDoctorNotesAction(payload) {
  return async (dispatch) => {
    dispatch(
      commonActionCreator(MEETINGREDUCERNAME)(
        relatedAction.IS_SAVE_DOCTOR_NOTES_API_LOADING,
        true
      )
    );
    const response = await new MeetingService().saveDoctorNotes(payload);
    if (response) {
      dispatch(
        commonActionCreator(MEETINGREDUCERNAME)(
          relatedAction.IS_SAVE_DOCTOR_NOTES_API_LOADING,
          false
        )
      );
    }
  };
}

export function saveWebSocketRefAction(socket) {
  return async (dispatch) => {
    dispatch(
      commonActionCreator(SOCKETREDUCERNAME)(
        relatedAction.SAVE_SOCKET_REF,
        socket
      )
    );
  };
}

export function clearWebSocketRefAction() {
  return async (dispatch) => {
    dispatch(
      commonActionCreator(SOCKETREDUCERNAME)(relatedAction.CLEAR_SOCKET_REF)
    );
  };
}

export function getWebsiteMeetingAction(eventId, navigate) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
          commonAction.IS_API_LOADING,
          true
        )
      );
      try {
        const response = await new WebsiteMeetingService().getMeeting(eventId);
        if (response?.data?.is_time_valid != 1) {
          // ;

          window.location.href = `/`;
          showAlert(4,response?.data?.msg)
          dispatch(
            commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
              commonAction.IS_API_LOADING,
              false
            )
          );
          return;
        }
        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.SET_API_DATA,
            response?.data
          )
        );

        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        if (window.location.href.indexOf("?join=true") == -1) {
          navigate(
            `/my-appointments/detail/meeting-chime/${eventId}/meeting`
          );
          // window.location.href = ;
        } else {
          navigate(
            `/my-appointments/detail/meeting-chime/${eventId}/meeting?join=true`
          );

          // window.location.href = `/my-appointments/detail/meeting-chime/${eventId}/meeting?join=true`;
        }
        resolve(response);
      } catch (error) {
        toast.warn(error.response.data.messages[0], {
          className: "toast-warn",
        });
        reject(error);
        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.SET_API_ERROR,
            error
          )
        );
        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        
        window.location.href = `/consult-doctor`;
      }
    });
  };
}

export function getWebsiteMeetingActionWithNotification(eventId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
          commonAction.IS_API_LOADING,
          true
        )
      );
      try {
        const busyResponse = await new WebsiteMeetingService().checkDoctorBusy(
          eventId
        );

        if (busyResponse?.data?.is_doctor_on_call == 0) {
          var response =
            await new WebsiteMeetingService().getMeetingWithNotification(
              eventId
            );
          if (response?.data?.is_time_valid != 1) {
            
            window.location.href = `/consult-doctor`;
            showAlert(4,response?.data?.msg)
            dispatch(
              commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
                commonAction.IS_API_LOADING,
                false
              )
            );
            return;
          }
          dispatch(
            commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
              commonAction.IS_API_LOADING,
              false
            )
          );
          dispatch(
            commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
              commonAction.SET_API_DATA,
              response?.data
            )
          );

          resolve(response);
        } else if (busyResponse?.data?.is_doctor_on_call == 1) {
          
          window.location.href = `/consult-doctor`;
          toast.warn(
            "Doctor is currently on call with another patient. Please hold.",
            {
              className: "toast-warn",
            }
          );
          resolve(response);
        } else {
          
          window.location.href = `/consult-doctor`;
          toast.warn("Please try again later.", {
            className: "toast-warn",
          });
          resolve(response);
        }
      } catch (error) {
        toast.warn(error.response.data.messages[0], {
          className: "toast-warn",
        });
        reject(error);
        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.SET_API_ERROR,
            error
          )
        );
        dispatch(
          commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
            commonAction.IS_API_LOADING,
            false
          )
        );
        // window.location.href = `/my-appointments/detail/${eventId}`;
      }
    });
  };
}

export function clearWebsiteChimeMeetingDataAction() {
  return (dispatch) => {
    dispatch(
      commonActionCreator(WEBSITEMEETINGREDUCERNAME)(
        commonAction.SET_API_DATA,
        null
      )
    );
  };
}

export async function websiteMeetingJoinedAction(payload) {
  await new WebsiteMeetingService().meetingJoined(payload);
}

export function saveWebsiteWebSocketRefAction(socket) {
  return async (dispatch) => {
    dispatch(
      commonActionCreator(WEBSITESOCKETREDUCERNAME)(
        relatedAction.SAVE_SOCKET_REF,
        socket
      )
    );
  };
}

export function clearsaveWebsiteWebSocketRefAction() {
  return async (dispatch) => {
    dispatch(
      commonActionCreator(WEBSITESOCKETREDUCERNAME)(
        relatedAction.CLEAR_SOCKET_REF
      )
    );
  };
}

export function getAppointmentDetailForWebsiteAction2(id) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME)(
          commonAction.IS_API_LOADING,
          true
        )
      );
      try {
        const response = await new WebsiteHomeService().getMyAppointmetsDetail(
          id
        );
        dispatch(
          commonActionCreator(WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME)(
            commonAction.SET_API_DATA,
            response?.data
          )
        );
        resolve(response);
      } catch (error) {
        dispatch(
          commonActionCreator(WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME)(
            commonAction.SET_API_ERROR,
            error
          )
        );
        reject(error);
      }
    });
  };
}
