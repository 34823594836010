const getEnvValue = (value: string | undefined): string => {
    return value ? value : '';
};

const API_BASE_URL: string = getEnvValue(process.env.REACT_APP_API_BASE_URL);
const AUTH_API_BASE_URL: string = getEnvValue(process.env.REACT_APP_AUTH_BASE_API_URL);
const IMAGE_BASE_URL:string = getEnvValue(process.env.REACT_APP_IMAGE_URL);
const REACT_APP_SOCKET_URL:string = getEnvValue(process.env.REACT_APP_SOCKET_URL)

const ENV_VARIABLES = {
    API_BASE_URL,
    AUTH_API_BASE_URL,
    IMAGE_BASE_URL,
    REACT_APP_SOCKET_URL
};

export default ENV_VARIABLES;
