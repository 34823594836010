import { createSlice } from "@reduxjs/toolkit";
import { IChimeMeetingResponse } from "../../utils/types";

interface InitialStateType {
  chimeMeetingResponse: IChimeMeetingResponse|null;
  isMeetingDataLoading: boolean;
}
const initialState: InitialStateType = {
  chimeMeetingResponse: null,
  isMeetingDataLoading: false,
};
const chimeMeetingSlice = createSlice({
  name: "chime-meeting-detail",
  initialState,
  reducers: {
    setChimeMeetingData(state, action) {
      return { ...state, ...action.payload };
    },
    resetChimeMeetingData(state) {
      state = initialState;
    },
  },
});

export const { setChimeMeetingData, resetChimeMeetingData } =
  chimeMeetingSlice.actions;
export default chimeMeetingSlice.reducer;
