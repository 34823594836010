import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Chime.css";
import {
  BackgroundBlurVideoFrameProcessor,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultVideoTransformDevice,
  LogLevel,
} from "amazon-chime-sdk-js";
import {
  Route,
  Link,
  Routes,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { Typography } from "@mui/material";
import {
  useMeetingManager,
  useMeetingStatus,
  useRemoteVideoTileState,
  useAudioVideo,
  useLocalVideo,
  useRosterState,
  useMeetingEvent,
  useAttendeeStatus,
  useToggleLocalMute,
  AudioInputControl,
  VideoInputControl,
  LocalVideo,
  Microphone,
  RemoteVideos,
  useBandwidthMetrics,
  useMediaStreamMetrics,
  useUserActivityState,
} from "amazon-chime-sdk-component-library-react";
import MeetingRoster from "./components/MeetingRoster";

// import { Icon, Message } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { toastIdsConstant } from "../../utils/toastConstant";
import {
  clearWebsiteChimeMeetingDataAction,
  getWebsiteMeetingAction,
  websiteMeetingJoinedAction,
  getWebsiteMeetingActionWithNotification,
  getAppointmentDetailForWebsiteAction2,
} from "./actions";
import {
  cancelCall,
  endCall,
  startCall,
  acceptCall,
  rejectCall,
  busyCall,
} from "../../utils/socketConstant";

import UserIsNotOnlineForCall from "./components/UserIsNotOnlineForCall";
import MeeetingDuration from "./components/MeeetingDuration/MeeetingDuration";
import DoctorCancelMeeting from "./components/DoctorCancelMeeting/DoctorCancelMeeting";
import UserNotAvailableForCall from "./components/UserNotAvailableForCall";
import CallEndByPatient from "./components/CallEndByPatient/CallEndByPatient";
import UserTryingtoNavigate from "./components/UserTryingtoNavigate";
import GoogleBlockeUI from "./components/GoogleBlockedUI";
// import {
// 	canJoinCall,
// 	printDifference,
// } from "../../utility/appointmentConstant";
// import { canWeShowStartConsultationBtn } from "../../utility/websiteConstant/appointmentConstant";
import {
  DATA_MESSAGE_TOPIC,
  DATA_MESSAGE_LIFETIME_MS,
  CAMERA_OFF,
  CAMERA_ON,
  SignalTypeAppWentToBackground,
  SignalTypeAppResumedFromBackground,
  END_CALL_BOTH_CONNECTED,
  SecondsToHMSConvertor,
} from "../../utils/meetingConstant";

import audioOffImg from "../../assets/calling-icons/audio-off.png";
import audioOnImg from "../../assets/calling-icons/audio-on.png";
import videoOffImg from "../../assets/calling-icons/video-off.png";
import videoOnImg from "../../assets/calling-icons/video-on.png";
import ringtone from "../../assets/ringtones/calling.wav";
import { videoCallNotifier } from "./actionConstant";
import { meetingRejectedAction } from "./actions";
import docImage from "../../assets/calling-icons/doctor_placeholder.svg";
import blurBackgndImgSelect from "../../assets/calling-icons/bg_blur_select.png";
import blurBackgndImgUnselect from "../../assets/calling-icons/bg_blur_unselect.png";
import patientImage from "../../assets/calling-icons/patient_placeholder.svg";
import endCallImg from "../../assets/calling-icons/end-call.svg";
import useAudio from "./components/useAudio";
// import { Env } from "../../constants";
import { showAlert } from "../../utils/alert";
import { getImage } from "../../utils/helperfunction";
import DashboardLayout from "../../layout/DashboardLayout";
import { useSocket } from "./socketContext";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import ReviewDoctorDialog from "../../pages/My_Appoinments/reviewDoctorDialog";

// Currently in Use for Patient #VideoCall

function Chime(props) {
  let seconds = 0;
  // const { socketData } = props;
  console.log("props?.notificationData", props?.notificationData, props);
  const navigate = useNavigate();
  const meetingManager = useMeetingManager();
  const ringtoneAudio = useRef(null);
  let audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const meetingEvent = useMeetingEvent();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { toggleMute } = useToggleLocalMute();
  const { tiles, size } = useRemoteVideoTileState();
  const meetingStatus = useMeetingStatus();
  const [attndeeId, setAttndeeId] = useState("");
  // const metrics = useBandwidthMetrics();
  console.log(attndeeId, "attndeeId");
  const metrics = useMediaStreamMetrics();
  const { isUserActive } = useUserActivityState();
  // const { selectedDevice } = useVideoInputs();
  // const { selectedDevice: selectedAudioDevice } = useAudioInputs();
  const { videoEnabled, muted, signalStrength } = useAttendeeStatus(
    attndeeId || ""
  );
  const [privewVideos, setPrivewVideos] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioMuted, setAudioMuted] = useState(true);
  const [isMeetinigJoin, setIsMeetingJoin] = useState(false);
  const [onlyOnce, setOnlyOnce] = useState(false);
  const [onlyOnceAudio, setOnlyOnceAudio] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [isApiLoadingToCheckEligibility, setIsApiLoadingToCheckEligibility] =
    useState(false);
  const dispatch = useDispatch();
  const [consultationtime, setConsultationtime] = useState(0);
  const overallMeetingTimer = useRef();
  const isUserJoinTheCall = useRef();
  
  // const { socketData } = useSelector(
  //   (state) => state?.WebsiteSocketReducerNotification
  // );
  const [showModalUserNotAvailable, setShowModalUserNotAvailable] =
    useState(false);
  const { appointmentDetailApiData, isApiLoading } = useSelector(
    (state) => state?.websiteAppointmentDetail
  );
  const [showFinishConsultation, setShowFinishConsultation] = useState(false);
  const [
    meetingEndPopupWhenDoctorCancelCall,
    setMeetingEndPopupWhenDoctorCancelCall,
  ] = useState(false);
  const [userCancelTheCall, setUserCancelTheCall] = useState(false);
  const { meetingApiData, isApiLoading: isEventLoading } = useSelector(
    (state) => state?.websiteMeeting
  );
  console.log(meetingApiData, appointmentDetailApiData, "meetingApiData");
  const [showDialogCustomerCutTheCall, setShowDialogCustomerCutTheCall] =
    useState(false);
  const [
    isCallInitiatedApiCallTakesPlace,
    setIsCallInitiatedApiCallTakesPlace,
  ] = useState(false);
  const [isUserTryingtoNavigate, setIsUserTryingtoNavigate] = useState(false);
  const [
    leaveMettingDueToPatientUnavailability,
    setLeaveMettingDueToPatientUnavailability,
  ] = useState(false);
  const [checkIfPatientComebackIn30Sec, setCheckIfPatientComebackIn30Sec] =
    useState(null);
  const { endCallListener, doctorClickedStartCall } = useSelector(
    (state) => state?.videoCallNotifierReducer
  );

  const params = useParams();
  const joined = useLocation().search;

  // console.log(searchParams,"paramsparams")
  const [videoBlock, setVideoBlock] = useState(false);
  const [audioBlock, setAudioBlock] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const videoElement = useRef();
  const timeout = useRef();
  let attendees = Object.values(roster);
  const [isValidated, setIsValidated] = useState(false);
  const [msgCode, setMsgCode] = useState(0);
  const [waitingFor, setWaitingFor] = useState(true);
  const [finalEndCall, setFinalEndCall] = useState(false);
  const [blurVideo, setBlurVideo] = useState(false);
  const [timer, setTimer] = useState(0);
  const [audioAccessBlocked, setAudioAccessBlocked] = useState(false);
  const [videoAccessBlocked, setVideoAccessBlocked] = useState(false);
  const [googleBlockedUI, setGoogleBlockedUI] = useState(false);
  const [endCallPressed, setEndCallPressed] = useState(false);
  const [play, stop] = useAudio(ringtone);
  const overlayFiveSecond = useRef(null);
  const [showOverlayFiveSecond, setShowOverlayFiveSecond] = useState(true);
  const [showBlurButton, setShowBlurBtn] = useState(false);
  const [blurBack, setBlurBack] = useState(null);
  const [transformDevice, setTransformDevice] = useState(null);
  const [openAddReviewDialog, setOpenAddReviewDialog] = useState(false)

  const logger = new ConsoleLogger("sdk", LogLevel.INFO);
  console.log("logger", logger);
  const deviceController = new DefaultDeviceController(logger);
  console.log("deviceController", deviceController);
  const { socketData } = useSocket();
  useEffect(() => {
    console.log("socketData", socketData);
  }, [socketData]);

  const previewVideo = async () => {
    try{
      const audioList = await deviceController.listAudioInputDevices();
      setAudioBlock(audioList[0]?.label === "" ? false : true);
      const deviceList = await deviceController.listVideoInputDevices();
      setVideoBlock(deviceList[0]?.label === "" ? false : true);
      console.log("audioList", audioList, deviceList);
      setCameraOn(
        deviceList[0]?.label === "" || audioList[0]?.label === "" ? false : true
      );
      await deviceController.startVideoInput(deviceList[0]?.deviceId);
      deviceController.startVideoPreviewForVideoInput(videoElement.current);
    }
    catch(error){
      console.log(error)
    }
    
    // console.log("video turn on");
  };

  const privewVideoStop = async () => {
    // if (videoElement.current) {
      console.log("video turn off before await", deviceController);
      try {
        await deviceController.destroy(); // Stops the video input
      } catch (err) {
        console.error("Error stopping video", err);
      }
      console.log("video turn off after await");
    // }
  };
  console.log(joined === "?join=true", "joined");
  useEffect(() => {
    if (joined !== "?join=true") {
      previewVideo();
    }
    // return () => {
    //   if (joined !== "?join=true") {
    //     privewVideoStop();
    //   }
    // };
  }, [joined]);
  useEffect(()=>{
    return () => {
     privewVideoStop();
    
    }
  },[])

  useEffect(() => {
    console.log("useEffect1");
    const getAttendeeId = () => {
      attendees.map((attendee) => {
        const { chimeAttendeeId } = attendee || {};
        if (
          !(
            meetingEvent &&
            meetingEvent?.attributes?.attendeeId === chimeAttendeeId
          )
        ) {
          setAttndeeId(chimeAttendeeId);
          return;
        }
      });
      return "";
    };
    getAttendeeId();
  }, [roster]);

  useEffect(() => {
    console.log("useEffect2");
    setGoogleBlockedUI(!audioBlock || !videoBlock ? true : false);
  }, [audioBlock, videoBlock]);

  const handleClosePopup = () => {
    setGoogleBlockedUI(!googleBlockedUI);
  };

  useEffect(() => {
    console.log("useEffect3");
    if (endCallListener || msgCode == END_CALL_BOTH_CONNECTED) {
      handleOkHandler();
      setFinalEndCall(false);
      setLeaveMettingDueToPatientUnavailability(false);
      setShowDialogCustomerCutTheCall(true);
      setMeetingEndPopupWhenDoctorCancelCall(false);
    }
    return () => {
      clearTimeoutFor2min();
      clearOverlayFiveSecond();
    };
  }, [endCallListener, msgCode]);

  console.log("msg", msgCode);

  useEffect(() => {
    console.log("useEffect4");
    // User may or may not be present
    if (finalEndCall && msgCode != END_CALL_BOTH_CONNECTED && !endCallPressed) {
      endcallAfter30SecIfUserDontComeBack();
      setLeaveMettingDueToPatientUnavailability(true);
    }
  }, [finalEndCall, msgCode, endCallPressed]);

  // useEffect(() => {
  // 	//non-logged in flow when user come from email link for consultation
  // 	if (
  // 		isHospitalWebsite() &&
  // 		props?.match?.params?.id?.includes("k") &&
  // 		params?.id?.includes("e")
  // 	) {
  // 		setGuestUser(true);
  // 		const len = window.location.href?.split("/")?.length - 1;
  // 		const qParams = {
  // 			k: window.location.href?.split("/")[len].split("&")[0]?.substring(2),
  // 			e: window.location.href?.split("/")[len].split("&")[1]?.substring(2),
  // 		};
  // 		setIsApiLoadingToCheckEligibility(true);
  // 		dispatch(checkIsUserComingForConsValid(qParams))
  // 			.then((res) => {
  // 				res?.data?.guest_user != null &&
  // 					setGuestUser(res?.data?.guest_user == 0 ? false : true);
  // 				setIsValidated(true);
  // 				// setGuestUser(true);
  // 				// // <MeetingNotificationWithSocket />;
  // 				// // if (!socketData) {
  // 				// // 	console.log("socketData", socketData);
  // 				// // }
  // 				setIsApiLoadingToCheckEligibility(false);
  // 				setEventId(res?.data?.event_id);
  // 				setCurrentCountryCodeToLS("ke");
  // 				if (!getWebsiteAccessTokenFromLS()) {
  // 					setWebsiteAccessTokenInLS(res.data.access_token);
  // 				}
  // 				dispatch(getAppointmentDetailForWebsiteAction2(res?.data?.event_id));
  // 				props?.history?.push(`/chime/detail/${qParams.k}/meeting`);
  // 			})
  // 			.catch((error) => {
  // 				if (!toast.isActive(toastIdsConstant.apiFailure)) {
  // 					return (toastIdsConstant.apiFailure = toast.warn(
  // 						error?.response?.messages[0],
  // 						{
  // 							toastId: toastIdsConstant.apiFailure,
  // 							className: "toast-warn",
  // 						}
  // 					));
  // 				}
  // 				return;
  // 			});
  // 	}

  // 	return async () => {
  // 		if (
  // 			isHospitalWebsite() &&
  // 			params?.id?.includes("k") &&
  // 			params?.id?.includes("e")
  // 		) {
  // 			socketData.off(startCall);
  // 			socketData.off(cancelCall);
  // 			socketData.off(endCall);
  // 			socketData.off(acceptCall);
  // 			socketData.off(rejectCall);
  // 			socketData.off(busyCall);
  // 		}
  // 		clearInterval(overallMeetingTimer.current);
  // 		clearTimeoutFor30Sec();
  // 		dispatch(clearWebsiteChimeMeetingDataAction());
  // 		await meetingManager?.leave();
  // 		dispatch({
  // 			type: `${WEBSITEMYAPPOINTMENTSDETAILREDUCERNAME}_${commonAction.SET_API_DATA}`,
  // 			payload: null,
  // 		});
  // 	};
  // }, [params?.id]);

  useEffect(() => {
    console.log("useEffect5");
    if (timer === 60) {
      toast.warn("No internet connection, please try again.", {
        className: "toast-warn",
      });
      navigate(`/`);
    }
  }, [timer]);

  const handleConnectionChange = () => {
    console.log("useEffect6");
    if (!navigator.onLine) {
      const webPing = setInterval(() => {
        if (navigator.onLine) {
          clearInterval(webPing);
          setTimer(0);
        } else {
          setTimer((timer) => timer + 1);
        }
      }, 1000);
      return;
    }
  };

  useEffect(() => {
    console.log("useEffect7");
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener(
      "popstate",
      (event) => {
        if (event.state) {
          cancelCallFromDoctorSideHandler();
          setTimeout(() => {
            navigate(`/my-appointments/detail/${params?.id}`);
          }, 200);
        }
      },
      true
    );
    return () => {
      cancelCallFromDoctorSideHandler();
      // navigate("/");
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    console.log("useEffect8");
    if (
      (tiles.length > 0 || !!attndeeId) &&
      checkIfPatientComebackIn30Sec &&
      !leaveMettingDueToPatientUnavailability
    ) {
      clearTimeoutFor30Sec();
    }
    // patient come back after reconnecting to wifi
    if (leaveMettingDueToPatientUnavailability) {
      // clearTimeoutForInternet();
    }
  }, [
    tiles,
    checkIfPatientComebackIn30Sec,
    leaveMettingDueToPatientUnavailability,
    attndeeId,
  ]);

  useEffect(() => {
    console.log("useEffect9");
    if (isCallInitiatedApiCallTakesPlace) {
      stopRingtone();
    }
  }, [isCallInitiatedApiCallTakesPlace]);

  useEffect(() => {
    console.log("useEffect10");
    if (params?.id && !params?.id?.includes("k")) {
      dispatch(getWebsiteMeetingAction(params?.id, navigate));
    }
  }, [params?.id]);

  // useEffect(() => {
  //   if (eventId) {
  //     dispatch(getWebsiteMeetingAction(eventId));
  //   }
  // }, [eventId]);

  useEffect(() => {
    console.log("useEffect11");
    if (socketData) {
      socketData?.on(cancelCall, (res) => {
        console.log("SOCKET ON - PATIENT: CANCEL CALL 319");
        handleEndCallHandler();
        clearTimeoutFor30Sec();
        clearTimeoutFor2min();
        setUserCancelTheCall(true);
      });
      socketData?.on(rejectCall, (res) => {
        console.log("SOCKET ON - PATIENT: reject CALL 350", res);
      });
      socketData?.on(endCall, (res) => {
        console.log("SOCKET ON - PATIENT: END CALL 324");
        clearTimeoutFor30Sec();
        clearTimeoutFor2min();
        setMeetingEndPopupWhenDoctorCancelCall(false);
      });
      socketData?.on(acceptCall, (res) => {
        stopRingtone();
        console.log("SOCKET ON - PATIENT: ACCEPT CALL 330");
      });
      socketData?.on(busyCall, async (res) => {
        console.log("busy res", res);
        console.log("busy res", eventId, params?.id);
        stopRingtone();
        // toast.dismiss(toastIdsConstant.ProperInternetConenction);

        await meetingManager?.leave();
        clearTimeoutFor2min();
        navigate(`/my-appointments/detail/${params?.id}`);
        EndCallHandler();
        console.log("SOCKET ON - PATIENT: BUSY CALL 330");
      });
      socketData?.on(startCall, (res) => {
        setShowDialogCustomerCutTheCall(false);
        setShowFinishConsultation(false);
        if (
          window.location.href.indexOf(
            `chime/detail/${eventId ?? params?.id}/meeting`
          ) > -1
        ) {
          navigate(`/my-appointments/detail/${params?.id}`);
        }
      });
    }
  }, [socketData]);
  console.log(params, "join=true");
  useEffect(() => {
    console.log("useEffect12");
    // if (params?.id && !params?.id?.includes("k")) {
    dispatch(getAppointmentDetailForWebsiteAction2(params?.id));
    // }
  }, []);

  useEffect(() => {
    console.log("useEffect13");
    if (
      (tiles.length > 0 || !!attndeeId) &&
      !isCallInitiatedApiCallTakesPlace
    ) {
      clearTimeoutFor2min();
      callDurationApi();
    }
    // when doctor end the call
    if (
      tiles?.length == 0 &&
      isCallInitiatedApiCallTakesPlace &&
      meetingStatus == 1 &&
      !leaveMettingDueToPatientUnavailability
    ) {
      clearTimeoutFor2min();
    }
  }, [
    tiles,
    isCallInitiatedApiCallTakesPlace,
    meetingStatus,
    leaveMettingDueToPatientUnavailability,
    attndeeId,
  ]);

  useEffect(() => {
    console.log("useEffect14");
    // when user join meeting from push notification
    if (
      joined == "?join=true" &&
      meetingApiData &&
      !isCallInitiatedApiCallTakesPlace &&
      !isMeetinigJoin
    ) {
      joinMeeting(meetingApiData);
    }
  }, [
    props.location?.search,
    meetingApiData,
    isCallInitiatedApiCallTakesPlace,
    isMeetinigJoin,
  ]);
  useEffect(() => {
    console.log("useEffect15");
    async function toggle() {
      if (!isVideoEnabled && meetingStatus === 1 && !onlyOnce && cameraOn) {
        console.log("Toggle Video On - Before");
        await toggleVideo();
        console.log("Toggle Video On - After");
        setOnlyOnce(true);
      }
    }
    toggle();
  }, [isVideoEnabled, meetingStatus, cameraOn, onlyOnce]);

  useEffect(() => {
    console.log("useEffect16");
    if (meetingStatus === 1 && !onlyOnceAudio && !audioMuted) {
      toggleMute();
      setOnlyOnceAudio(true);
    }
  }, [meetingStatus, onlyOnceAudio, audioMuted]);

  const clearTimeoutFor30Sec = () => {
    console.log("useEffect17");
    if (checkIfPatientComebackIn30Sec) {
      clearTimeout(checkIfPatientComebackIn30Sec);
      setCheckIfPatientComebackIn30Sec(null);
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  useEffect(() => {
    console.log("useEffect18");
    if (audioVideo) {
      audioVideo.realtimeSubscribeToAttendeeIdPresence(
        (attendeeId, present, externalUserId, dropped) => {
          console.log("timeout present", present, dropped);
          setWaitingFor(false);
          if (
            meetingApiData?.chime_meeting_data?.Attendee.AttendeeId &&
            attendeeId &&
            meetingApiData?.chime_meeting_data?.Attendee.AttendeeId !==
              attendeeId &&
            isCallInitiatedApiCallTakesPlace
          ) {
            if (present) {
              clearTimeoutFor30Sec();
              clearTimeoutFor2min();
              setFinalEndCall(false);
              setLeaveMettingDueToPatientUnavailability(false);
              return;
            }
            if (!present) {
              setFinalEndCall(true);
            }
          }
        }
      );
    }
  }, [audioVideo, isCallInitiatedApiCallTakesPlace, endCallListener]);

  function Decodeuint8arr(uint8array) {
    return new TextDecoder("utf-8").decode(uint8array);
  }

  function sendMessage(message) {
    if (!audioVideo) return;

    audioVideo.realtimeSendDataMessage(
      DATA_MESSAGE_TOPIC,
      message,
      DATA_MESSAGE_LIFETIME_MS
    );
  }

  useEffect(() => {
    console.log("useEffect19");
    if (!audioVideo) return;

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      DATA_MESSAGE_TOPIC,
      (msg) => {
        setMsgCode(Decodeuint8arr(msg.data));
        if (Decodeuint8arr(msg.data) == SignalTypeAppResumedFromBackground) {
          setBlurVideo(false);
        }
        if (Decodeuint8arr(msg.data) == SignalTypeAppWentToBackground) {
          setBlurVideo(true);
        }
      }
    );
  }, [audioVideo]);

  const handleBlurVideo = (event) => {
    if (document.hidden) {
      sendMessage(SignalTypeAppWentToBackground);
    } else {
      sendMessage(SignalTypeAppResumedFromBackground);
    }
  };

  useEffect(() => {
    console.log("useEffect20");
    if (isCallInitiatedApiCallTakesPlace) {
      if (isVideoEnabled) {
        sendMessage(CAMERA_ON);
      } else {
        sendMessage(CAMERA_OFF);
      }
      if (performance.navigation.type == 1) {
        sendMessage(SignalTypeAppResumedFromBackground);
      }
      if (/Mobi|Android/.test(navigator.userAgent)) {
        document.addEventListener("visibilitychange", handleBlurVideo);
      }
    }
    return () => {
      document.removeEventListener("visibilitychange", handleBlurVideo);
    };
  }, [
    isVideoEnabled,
    isCallInitiatedApiCallTakesPlace,
    navigator.userAgent,
    performance.navigation.type,
  ]);

  const endcallAfter30SecIfUserDontComeBack = () => {
    if (!timeout.current) {
      timeout.current = setTimeout(() => {
        socketEmitCancelCall("30sec");
        handleEndCallHandler();
        setShowModalUserNotAvailable(true);
      }, 45000);
      setCheckIfPatientComebackIn30Sec(timeout.current);
    }
  };

  console.log(
    "DebugCustom -timeout 45 sec",
    timeout.current,
    checkIfPatientComebackIn30Sec
  );
  console.log(
    "DebugCustom -timeout 2min",
    isUserJoinTheCall.current,
    "no state"
  );
  console.log("DebugCustom -timeout internet", timer);
  console.log("DebugCustom -timeout meetingStatus", meetingStatus);
  console.log("DebugCustom - Tiles, size", tiles, size);
  console.log(
    "DebugCustom - Metric - audio uplink is loss",
    metrics.audioPacketsSentFractionLossPercent
  );
  console.log(
    "DebugCustom - Metric - audio downlink is loss",
    metrics.audioPacketsReceivedFractionLossPercent
  );
  console.log(
    "DebugCustom - Metric - bandwidth incoming",
    metrics.availableIncomingBandwidth
  );
  console.log(
    "DebugCustom - Metric - bandwidth outgoing",
    metrics.availableOutgoingBandwidth
  );
  console.log(
    "DebugCustom - Metric - video stream",
    metrics.videoStreamMetrics
  );
  console.log("DebugCustom - User Active", isUserActive);
  console.log("attndeeId", attndeeId);

  // call once when for the first time 2 people joined
  const callDurationApi = () => {
    setIsCallInitiatedApiCallTakesPlace(true);
    websiteMeetingJoinedAction({
      call_initiated: 1,
      event_id: eventId ?? params?.id,
    });
    overallMeetingTimer.current = setInterval(() => {
      seconds++;
      setConsultationtime(seconds);
    }, 1000);
  };

  const resetAppointmentDetails = () => {
    dispatch(clearWebsiteChimeMeetingDataAction());
  };

  const socketEmitCancelCall = (re = "none") => {
    console.log("socket emit cancel call", re);

    socketData?.emit(cancelCall, {
      eventId: eventId ?? params?.id,
      toUserId: appointmentDetailApiData?.body?.user_id,
      toUserType: 2,
    });
  };

  // Conditions to run:
  // User not available for 2 minutes
  const handleEndCallHandler = async () => {
    setLeaveMettingDueToPatientUnavailability(false);
    stopRingtone();
    // clearing redux because otherwise start call push is going on behalf meetingApiData in useffect
    //leave meeting then routing

    await meetingManager?.leave();
    resetAppointmentDetails();
  };

  const checkIfUserjoinTheCall = () => {
    if (!isUserJoinTheCall.current) {
      isUserJoinTheCall.current = setTimeout(() => {
        socketEmitCancelCall("2min");
        handleEndCallHandler();
        setShowModalUserNotAvailable(true);
      }, 120000);
    }
  };

  const clearTimeoutFor2min = () => {
    if (isUserJoinTheCall.current) {
      clearTimeout(isUserJoinTheCall.current);
      isUserJoinTheCall.current = null;
    }
  };

  if (!overlayFiveSecond.current && joined !== "?join=true") {
    overlayFiveSecond.current = setTimeout(() => {
      setShowOverlayFiveSecond(false);
    }, 5000);
  }

  const clearOverlayFiveSecond = () => {
    if (joined !== "?join=true") {
      // setShowOverlayFiveSecond(true);
      if (overlayFiveSecond.current) {
        clearTimeout(overlayFiveSecond.current);
        overlayFiveSecond.current = null;
      }
    }
  };

  const finishConsultationHandler = async () => {
    setShowFinishConsultation(false);
    setEndCallPressed(false);
    localStorage.removeItem("accessToken");
    navigate(`/my-appointments/detail/${params?.id}`);
  };

  const EndCallHandler = async () => {
    clearOverlayFiveSecond();
    clearTimeoutFor30Sec();
    clearTimeoutFor2min();
    dispatch({
      type: videoCallNotifier.VIDEO_CALL_NOTIFIER_PATIENT,
      payload: false,
    });
    stopRingtone();
    // when patient call and immediately end the call
    if (
      meetingStatus === 0 ||
      meetingStatus === 2 ||
      meetingStatus === 3 ||
      meetingStatus === 4 ||
      meetingStatus === 5 ||
      meetingStatus === 6
    ) {
      dispatch(clearWebsiteChimeMeetingDataAction());
      socketData?.emit(cancelCall, {
        eventId: eventId ?? params?.id,
        toUserId: appointmentDetailApiData?.body?.user_id,
        toUserType: 2,
      });

      await meetingManager?.leave();

      console.log("SOCKET EMIT - PATIENT: CANCEL CALL - Myself");
    }
    if (tiles?.length > 0 || isCallInitiatedApiCallTakesPlace) {
      // Anyone Called - Patient Clicked End Call
      setMeetingEndPopupWhenDoctorCancelCall(true);
    } else {
      // Patient Called Patient Ended without Connecting
      meetingRejectedAction(
        {
          call_rejected: 1,
          rejected_by_same_user: 1,
          event_id: params?.id,
          device_push_token: "abc",
        },
        "patient"
      );
      socketData?.emit(cancelCall, {
        eventId: params?.id,
        toUserId: appointmentDetailApiData?.body?.user_id,
        toUserType: 2,
      });
      console.log("SOCKET EMIT: cancel CALL, 656");
      socketData?.emit(rejectCall, {
        eventId: params?.id,
        // accessToken: getWebsiteAccessTokenFromLS(),
      });
      console.log("SOCKET EMIT: reject CALL, 661");

      await meetingManager?.leave();
      // meetingManager?.audioVideo?.stop();
      // meetingManager?.audioVideo?.stopLocalVideoTile();
      navigate(`/my-appointments/detail/${params?.id}`);
    }
  };

  const cancelCallFromDoctorSideHandler = async () => {
    setEndCallPressed(true);
    sendMessage(END_CALL_BOTH_CONNECTED);
    stopRingtone();
    // clearing redux because otherwise start call push is going on behalf meetingApiData in useffect
    clearInterval(overallMeetingTimer.current);
    // dispatch(resetWebsiteAppointmentDetailAction());
    // dispatch(clearWebsiteChimeMeetingDataAction());
    // clearInterval(apiCallInterval.current)
    seconds = 0;
    // leaveMeeting();

    await meetingManager?.leave();
    // commented for issue
    // setMeetingEndPopupWhenDoctorCancelCall(false);
    // setShowFinishConsultation(true);
    // setEndCallPressed(false);
    socketData?.emit(endCall, {
      eventId: eventId ?? params?.id,
      toUserId: appointmentDetailApiData?.body?.user_id,
      toUserType: 2,
    });
    privewVideoStop()
    console.log("SOCKET EMIT - PATIENT: END CALL 572");
  };
  const rejoinCancelCallFromDoctorSideHandler = () => {
    setMeetingEndPopupWhenDoctorCancelCall(false);
  };

  const handleProceedCallHandler = async () => {
    // leaveMeeting().then(() => {

    await meetingManager?.leave();
    appointmentDetailApiData?.body?.is_insurance == 1
      ? navigate(`/my-appointments/detail/${params?.id}`)
      : navigate(`/my-appointments/detail/${params?.id}`);
    // });
  };
  // new code example
  const joinMeeting = async (meetingApiData) => {
    if (joined !== "?join=true") {
      clearOverlayFiveSecond();
      console.log("video turn off before");
      // privewVideoStop();
      console.log("video turn off after");
      playRingtone();
    }
    setIsMeetingJoin(true);
    setTimeout(() => {
      setPrivewVideos(false);
    }, 5000);
    let joinData;
    if (meetingApiData && meetingApiData?.chime_meeting_data) {
      if (joined !== "?join=true" && socketData) {
        socketData?.emit(startCall, {
          eventId: eventId ?? params?.id,
          toUserId: appointmentDetailApiData?.body?.user_id,
          toUserType: 2,
        });
        console.log("SOCKET EMIT - PATIENT: START CALL 606");
      }
      joinData = {
        Meeting: meetingApiData?.chime_meeting_data?.Meeting,
        Attendee: meetingApiData?.chime_meeting_data?.Attendee,
      };
    }
    // setShowProperInternetConenction(true);
    console.log("meetingManager", meetingManager, joinData);
    showAlert(
      4,
      `Please make sure you have an active and stable internet connection for best audio consultation experience.`
    );
    checkIfUserjoinTheCall();
    console.log("Toggle Video - Meeting Joining", isVideoEnabled);
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      joinData.Meeting,
      joinData.Attendee
    );
    const deviceLabels = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      return stream;
    };
    const options = {
      deviceLabels,
    };

    // const options = {
    //     deviceLabels: Device.AudioAndVideo,
    // };

    // await meetingManager.join(meetingSessionConfiguration, {
    //   enableWebAudio: true,
    // });
    await meetingManager.join(meetingSessionConfiguration);
    // await meetingManager.join(joinData);
    console.log(
      "Toggle Video - Meeting Starting",
      isVideoEnabled,
      meetingManager
    );
    try {
      await meetingManager.start();
    } catch (error) {
      console.log("error", error);
    }

    console.log("Toggle Video - Meeting Started", isVideoEnabled);
  };

  const checkMeetingStatus = async () => {
    resetAppointmentDetails();
    setUserCancelTheCall(false);
    if (disabled) {
      return;
    }
    setDisabled(true);
    const response = await dispatch(
      getWebsiteMeetingActionWithNotification(params?.id)
    );
    if (response.data.is_time_valid === 1) {
      joinMeeting(response.data);
    } else {
      showAlert(4, response.msg);
    }
  };

  const leaveMeeting = async () => {
    EndCallHandler();
  };

  const handleOkHandler = async () => {
    await meetingManager?.leave();
    clearInterval(overallMeetingTimer.current);

    seconds = 0;
    // clearing redux because otherwise start call push is going on behalf meetingApiData inn useffect
    // dispatch(clearWebsiteChimeMeetingDataAction());
    dispatch({
      type: videoCallNotifier.VIDEO_CALL_END_CALL_LISTNER,
      payload: false,
    });
  };

  const showDontNavigatepopup = () => {
    setIsUserTryingtoNavigate(true);
  };

  const playRingtone = () => {
    // play();
    console.log("New Build Patient");
    // var playedPromise = ringtoneAudio.current.play();
    // if (playedPromise) {
    // 	playedPromise
    // 		.catch((e) => {
    // 			if (e.name === "NotAllowedError" || e.name === "NotSupportedError") {
    // 				// console.log(e.name);
    // 			}
    // 		})
    // 		.then(() => {
    // 			// console.log("playing sound !!!");
    // 		});
    // }
  };

  const stopRingtone = () => {
    stop();
    // if (ringtoneAudio && ringtoneAudio.current) {
    // 	ringtoneAudio.current.pause();
    // 	ringtoneAudio.current.currentTime = 0;
    // }
  };

  function windowReload() {
    if (joined == "?join=true") {
      window.location.reload();
    }
    // else {
    //
    // if (!toast.isActive(toastIdsConstant.somethingWrong)) {
    // toastIdsConstant.somethingWrong = toast.warn(
    // `Something went wrong, please try again later`,
    // {
    // toastId: toastIdsConstant.somethingWrong,
    // className: "toast-warn",
    // preventDuplicates: true,
    // preventOpenDuplicates: true,
    // }
    // );
    // }
    // }
  }
  console.log(videoBlock, "videoBlock");
  const observer = {
    eventDidReceive(name, attributes) {
      if (name === "meetingFailed" || name === "meetingStartFailed") {
        console.log(
          `meetingManager Failed during a meeting: ${attributes.meetingErrorMessage} in `,
          attributes
        );

        windowReload();
      }
    },
  };

  audioVideo?.addObserver(observer);

  const checkBlockers = () => {
    if (videoBlock && !audioBlock) {
      return (
        <p className="text-cam-off">
          Your microphone is blocked.
          {/* For proper video consultation we require */}
          {/* access to your microphone. Click the microphone blocked icon in your */}
          {/* browser's address bar and reload the page. */}
        </p>
      );
    }
    if (!videoBlock && audioBlock) {
      return (
        <p className="text-cam-off">
          Your camera is blocked.
          {/* For proper video consultation we require */}
          {/* access to your camera. Click the camera blocked icon in your browser's */}
          {/* address bar and reload the page. */}
        </p>
      );
    }
    if (!videoBlock && !audioBlock) {
      return (
        <p className="text-cam-off">
          Your camera and microphone are blocked.
          {/* For proper video consultation */}
          {/* we require access to your camera and microphone. Click the camera & */}
          {/* microphone blocked icon in your browser's address bar and reload the */}
          {/* page. */}
        </p>
      );
    }
  };

  const accessCameraAndVideo = async () => {
    let audioAccess = null;
    let videoAccess = null;
    try {
      audioAccess = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      setAudioAccessBlocked(true);
    }
    try {
      videoAccess = await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (error) {
      setVideoAccessBlocked(true);
    }
  };

  useEffect(() => {
    console.log("useEffect21");
    accessCameraAndVideo();
  }, []);

  useEffect(() => {
    async function configBLur() {
      const deviceList = await deviceController.listVideoInputDevices();
      const processors = [];
      if (await BackgroundBlurVideoFrameProcessor.isSupported()) {
        console.log("blur supported for the given call");
        BackgroundBlurVideoFrameProcessor.create()
          .then((res) => {
            console.log("BackgroundBlurVideoFrameProcessor res", res);
            const blurProcessor = res;
            console.log("blurProcessor", blurProcessor);
            setShowBlurBtn(true);
            processors.push(blurProcessor);
          })
          .catch((err) => {
            console.log("BackgroundBlurVideoFrameProcessor err", err);
          });
      }
      setTransformDevice(
        new DefaultVideoTransformDevice(logger, deviceList[0], processors)
      );
    }
    configBLur();
  }, []);
  const blurBackgnd = () => {
    setBlurBack((current) => {
      if (current === null || current === false) return true;
      else return false;
    });
  };
  useEffect(() => {
    async function toggleBlur() {
      const deviceList = await deviceController.listVideoInputDevices();
      if (blurBack === true && privewVideos) {
        await deviceController.startVideoInput(transformDevice);
        deviceController.startVideoPreviewForVideoInput(videoElement.current);
      }
      if (blurBack === false && privewVideos) {
        await deviceController.startVideoInput(deviceList[0]?.deviceId);
        deviceController.startVideoPreviewForVideoInput(videoElement.current);
      }
      if (blurBack === true && !privewVideos && isVideoEnabled) {
        audioVideo.startVideoInput(transformDevice);
      }
      if (blurBack === false && !privewVideos && audioVideo) {
        audioVideo.startVideoInput(deviceList[0]?.deviceId);
      }
    }
    try{
      toggleBlur();
    }
    catch(error){
      console.log(error)
    }
    
  }, [blurBack, privewVideos, isVideoEnabled]);
  return (
    <DashboardLayout>
      {/* {isValidated && !socketData && <MeetingNotificationWithSocket />} */}
      {/* <audio
				className={"d-none"}
				id="callMaker"
				ref={ringtoneAudio}
				controls="controls"
				preload="auto"
				src={ringtone}
				loop="loop"
				type="audio/wav"
			></audio> */}
      {(meetingStatus === 1 || showOverlayFiveSecond) && (
        <div className="overlay-navbar" onClick={showDontNavigatepopup} />
      )}
      {leaveMettingDueToPatientUnavailability &&
        meetingStatus === 1 &&
        !endCallPressed  && (
        <div className="text-center">
          <p className="bg-warning text-navy text-center py-1 px-3 z-[99999999999] relative inline-block">
            Reconnecting, There is a poor internet
            connection...
          </p>
        </div>
        )}
      {/* {!(
				userCancelTheCall ||
				showDialogCustomerCutTheCall ||
				showFinishConsultation
			) && ( */}
      <div className="page-video-calling for-patient bg-greyBg">
        <div className="video-calling-content bg-white">
          <div className="flex items-center justify-around mb-3">
            <h4 className="text-xl font-semibold text-black">
              {`${
                appointmentDetailApiData?.body?.beneficiary
                  ?.beneficiary_first_name
                  ? appointmentDetailApiData?.body?.beneficiary
                      ?.beneficiary_first_name
                  : ""
              }  ${
                appointmentDetailApiData?.body?.beneficiary
                  ?.beneficiary_last_name
                  ? appointmentDetailApiData?.body?.beneficiary
                      ?.beneficiary_last_name
                  : ""
              }`}
            </h4>
            <h4 className="text-xl font-semibold text-black">
              {appointmentDetailApiData?.body?.doctor_name}
            </h4>
          </div>
          {privewVideos && (
            <div className="two-videos-cover">
              <div className="left-portion">
                <div className="self-video">
                  {cameraOn && <video ref={videoElement}></video>}
                  {!cameraOn && appointmentDetailApiData?.body?.user_name && (
                    <>
                      {appointmentDetailApiData?.body?.beneficiary
                        ?.user_avatar ? (
                        <img
                          src={getImage(
                            appointmentDetailApiData?.body?.beneficiary
                              ?.user_avatar,
                            ""
                          )}
                          alt={appointmentDetailApiData?.body?.patient_name}
                        />
                      ) : (
                        <img src={patientImage} alt={""} />
                      )}
                    </>
                  )}
                </div>
                {!cameraOn && appointmentDetailApiData?.body?.user_name && (
                  <h4 className="text-cam-off">You turned off the camera</h4>
                )}
                {appointmentDetailApiData?.body?.user_name && checkBlockers()}
                <div className="call-options-cover preview-calling-option">
                  <div className="single-option">
                    {audioBlock ? (
                      !audioMuted ? (
                        <img
                          src={audioOffImg}
                          title="Unmute"
                          onClick={() => {
                            setAudioMuted(!audioMuted);
                          }}
                        />
                      ) : (
                        <img
                          src={audioOnImg}
                          title="Mute"
                          onClick={() => {
                            setAudioMuted(!audioMuted);
                          }}
                        />
                      )
                    ) : !audioBlock ? (
                      <img src={audioOffImg} title="Unmute" />
                    ) : (
                      <img src={audioOnImg} title="Mute" />
                    )}
                  </div>

                  <div className="single-option">
                    {videoBlock ? (
                      !cameraOn ? (
                        <img
                          src={videoOffImg}
                          onClick={() => {
                            setCameraOn(!cameraOn);
                            previewVideo();
                          }}
                          title="Turn video on"
                        />
                      ) : (
                        <img
                          src={videoOnImg}
                          onClick={() => {
                            setCameraOn(!cameraOn);
                            privewVideoStop();
                          }}
                          title="Turn video off"
                        />
                      )
                    ) : !videoBlock ? (
                      <img src={videoOffImg} title="Turn video on" />
                    ) : (
                      <img src={videoOnImg} title="Turn video off" />
                    )}
                  </div>
                  {showBlurButton && (
                    <div className="single-option">
                      {(blurBack === null || blurBack === false) && (
                        <img
                          src={blurBackgndImgSelect}
                          title="Turn blur on"
                          onClick={() => {
                            blurBackgnd();
                          }}
                        />
                      )}
                      {blurBack === true && (
                        <img
                          src={blurBackgndImgUnselect}
                          title="Turn blur off"
                          onClick={() => {
                            blurBackgnd();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="right-portion">
                <div className="others-video">
                  {appointmentDetailApiData?.body?.user_avatar ? (
                    <img
                      src={getImage(
                        appointmentDetailApiData?.body?.user_avatar,
                        ""
                      )}
                      alt={appointmentDetailApiData?.body?.user_name}
                    />
                  ) : (
                    <img src={docImage} alt={""} />
                  )}
                </div>
              </div>
            </div>
          )}
          {console.log(
            "xdgd",
            disabled,
            doctorClickedStartCall,
            !socketData,
            !audioBlock,
            !meetingApiData,
            isEventLoading,
            meetingStatus === 1,
            isApiLoading,
            isApiLoadingToCheckEligibility
          )}
          {privewVideos && (
            <div className="call-options-cover">
              {(meetingStatus === 0 ||
                meetingStatus === 2 ||
                meetingStatus === 3 ||
                meetingStatus === 4 ||
                meetingStatus === 5 ||
                meetingStatus === 6) && (
                <>
                  {joined !== "?join=true" && (
                    <>
                      {/* {!meetingApiData ? ( */}
                      {/* <></> */}
                      {/* ) : ( */}
                      <button
                        disabled={
                          disabled ||
                          doctorClickedStartCall ||
                          !socketData ||
                          !audioBlock ||
                          !meetingApiData ||
                          isEventLoading ||
                          meetingStatus === 1 ||
                          isApiLoading ||
                          isApiLoadingToCheckEligibility
                        }
                        onClick={checkMeetingStatus}
                        className="bg-seaGreen rounded-lg max-w-[188px] w-full h-[52px] text-white text-base font-semibold"
                      >
                        Connect Now
                        {/* {printDifference(
                            appointmentDetailApiData?.body?.to_date,
                            appointmentDetailApiData?.body?.from_date
                          )}{" "} */}
                        &nbsp;&nbsp;
                        {/* <Icon aria-label="Start call" name="call" /> */}
                      </button>
                      {/* )} */}
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {!privewVideos && (
            <div
              className={`two-videos-cover ${
                isVideoEnabled ? "" : "no-local-video"
              }`}
            >
              <div className={`left-portion`}>
                <div className="self-video">
                  {isVideoEnabled ? (
                    <LocalVideo />
                  ) : (
                    <>
                      {/* <p className="text-cam-off">You turned off the camera</p> */}
                      {appointmentDetailApiData?.body?.beneficiary
                        ?.user_avatar ? (
                        <img
                          src={getImage(
                            appointmentDetailApiData?.body?.beneficiary
                              ?.user_avatar,
                            ""
                          )}
                          alt={appointmentDetailApiData?.body?.patient_name}
                        />
                      ) : (
                        <img src={patientImage} alt={""} />
                      )}
                    </>
                  )}
                </div>
                {!isVideoEnabled &&
                !showFinishConsultation &&
                !showDialogCustomerCutTheCall &&
                !(userCancelTheCall && attndeeId == "" && disabled) ? (
                  <p className="text-cam-off">You turned off the camera</p>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`right-portion ${
                  tiles.length > 0 ? "video-abtr" : ""
                } ${blurVideo ? "video-blur" : ""}`}
              >
                <div className="others-video">
                  {/* Remote Video Tile */}
                  {<RemoteVideos />}
                  {console.log(
                    !videoEnabled,
                    meetingStatus === 1,
                    isCallInitiatedApiCallTakesPlace,
                    tiles,
                    tiles.length,
                    "remote"
                  )}
                  {/* Turned off the Camera */}
                  {!videoEnabled &&
                  meetingStatus === 1 &&
                  isCallInitiatedApiCallTakesPlace &&
                  tiles &&
                  tiles.length == 0 ? (
                    <div className="text-center w-full h-full video-turned-off">
                      <p className="text-cam-off">
                        {appointmentDetailApiData?.body?.user_name}{" "}
                        {!leaveMettingDueToPatientUnavailability
                          ? "turned off the camera"
                          : "video is paused"}
                      </p>
                      {appointmentDetailApiData?.body?.user_avatar ? (
                        <img
                          src={getImage(
                            appointmentDetailApiData?.body?.user_avatar,
                            ""
                          )}
                          alt={appointmentDetailApiData?.body?.user_name}
                          className="user-video-img"
                        />
                      ) : (
                        <img src={docImage} alt={""} />
                      )}
                    </div>
                  ) : (
                    <>
                      {appointmentDetailApiData?.body?.user_avatar &&
                        (showFinishConsultation ||
                          showDialogCustomerCutTheCall ||
                          (userCancelTheCall &&
                            attndeeId == "" &&
                            disabled)) && (
                          <img
                            src={
                              getImage(
                                appointmentDetailApiData?.body?.user_avatar,
                                ""
                              )
                              // Env.BASE_URL +
                              // appointmentDetailApiData?.body?.user_avatar
                            }
                            alt={appointmentDetailApiData?.body?.user_name}
                            className="user-video-img"
                          />
                        )}
                    </>
                  )}

                  {/* Calling to */}
                  {((joined !== "?join=true" &&
                    appointmentDetailApiData?.body?.user_name &&
                    meetingStatus === 0) ||
                    (appointmentDetailApiData?.body?.user_name &&
                      meetingStatus === 1 &&
                      !isCallInitiatedApiCallTakesPlace &&
                      joined !== "?join=true")) && (
                    <div className="text-center w-full h-full video-turned-off">
                      <p className="text-cam-off">
                        Calling to {appointmentDetailApiData?.body?.user_name}
                      </p>
                      {appointmentDetailApiData?.body?.user_avatar ? (
                        <img
                          src={
                            getImage(
                              appointmentDetailApiData?.body?.user_avatar,
                              ""
                            )
                            // Env.BASE_URL +
                            // appointmentDetailApiData?.body?.user_avatar
                          }
                          alt={appointmentDetailApiData?.body?.user_name}
                          className="user-video-img"
                        />
                      ) : (
                        <img
                          src={docImage}
                          alt={appointmentDetailApiData?.body?.user_name}
                          className="user-video-img"
                        />
                      )}
                    </div>
                  )}

                  {/* Waiting For */}
                  {waitingFor &&
                    joined == "?join=true" &&
                    appointmentDetailApiData?.body?.user_name && (
                      <div className="text-center w-100 h-100 video-turned-off">
                        <p className="text-cam-off">
                          {`Waiting for ${appointmentDetailApiData?.body?.user_name}`}
                        </p>
                        {appointmentDetailApiData?.body?.user_avatar ? (
                          <img
                            src={
                              getImage(
                                appointmentDetailApiData?.body?.user_avatar,
                                ""
                              )
                              // Env.BASE_URL +
                              // appointmentDetailApiData?.body?.user_avatar
                            }
                            alt={appointmentDetailApiData?.body?.user_name}
                            className="user-video-img"
                          />
                        ) : (
                          <img
                            src={docImage}
                            alt={appointmentDetailApiData?.body?.user_name}
                            className="user-video-img"
                          />
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}

          {!privewVideos && (
            <div className="call-options-cover">
              {meetingStatus === 1 && (
                <>
                  <span className={audioAccessBlocked ? "no-label" : ""}>
                    {audioAccessBlocked ? (
                      <button className="custom-mic">
                        <Microphone muted width="2rem" />
                      </button>
                    ) : (
                      <AudioInputControl />
                    )}
                  </span>
                  <div className="end-call-img">
                    <img
                      src={endCallImg}
                      onClick={leaveMeeting}
                      title="End Call"
                    />
                  </div>
                  <span className={videoAccessBlocked ? "no-label" : ""}>
                    <VideoInputControl />
                  </span>
                  {showBlurButton && (
                    <div className="end-call-img">
                      {(blurBack === null || blurBack === false) && (
                        <img
                          src={blurBackgndImgSelect}
                          title="Turn blur on"
                          onClick={() => {
                            blurBackgnd();
                          }}
                        />
                      )}
                      {blurBack === true && (
                        <img
                          src={blurBackgndImgUnselect}
                          title="Turn blur off"
                          onClick={() => {
                            blurBackgnd();
                          }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <MeetingRoster />
        </div>
      </div>
      {/* )} */}
      {/* {console.log(props?.match?.params,"props?.match?.params",)} */}
      {/* 1. Works When User is Not Available for 2 Minutes */}
      {showModalUserNotAvailable && (
        <UserIsNotOnlineForCall
          name={appointmentDetailApiData?.body?.user_name}
          onHide={() => {
            resetAppointmentDetails();
            setShowModalUserNotAvailable(false);
            navigate("/consult-doctor");
          }}
          openDialog={showModalUserNotAvailable}
        />
      )}
      {/* 1. Works When User Cancels The Call */}
      {userCancelTheCall && attndeeId == "" && disabled && (
        <UserNotAvailableForCall
          name={appointmentDetailApiData?.body?.user_name}
          onHide={() => {
            setUserCancelTheCall(false);
            navigate("/consult-doctor");
          }}
          openDialog={userCancelTheCall && attndeeId == "" && disabled}
        />
      )}
      {showFinishConsultation && (
        <MeeetingDuration
          finishConsultation={finishConsultationHandler}
          duration={SecondsToHMSConvertor(consultationtime)}
          isChangeMessage={appointmentDetailApiData?.body?.changeMessage}
          openDialog={showFinishConsultation}
        />
      )}
      {meetingEndPopupWhenDoctorCancelCall && (
        <DoctorCancelMeeting
          cancelCallFromDoctorSide={()=>{
            cancelCallFromDoctorSideHandler();
            setMeetingEndPopupWhenDoctorCancelCall(false);
            setOpenAddReviewDialog(true)
            // setShowFinishConsultation(true);
            setEndCallPressed(false);
          }}
          rejoinCancelCallFromDoctorSide={rejoinCancelCallFromDoctorSideHandler}
          role={4}
          history={props.history}
          isInsurance={appointmentDetailApiData?.body?.is_insurance}
          claimAndPrescriptionHandler={handleProceedCallHandler}
          openDialog={meetingEndPopupWhenDoctorCancelCall}
          closeDialog={() => {
            setMeetingEndPopupWhenDoctorCancelCall(false);
          }}
        />
      )}
      {showDialogCustomerCutTheCall && (
        <CallEndByPatient
          handleOk={() => {
            setShowDialogCustomerCutTheCall(false);
            setOpenAddReviewDialog(true)
          }}
          patientName={appointmentDetailApiData?.body?.user_name}
          duration={SecondsToHMSConvertor(consultationtime)}
          openDialog={showDialogCustomerCutTheCall}
        />
      )}
      {openAddReviewDialog && (
        <ReviewDoctorDialog openDialog={openAddReviewDialog}
         handleClose={()=>{setOpenAddReviewDialog(false)}}
         doctor_id={appointmentDetailApiData?.body?.doctor_id}
         event_id={appointmentDetailApiData?.body?.id}
         handleOk={() => {
          setOpenAddReviewDialog(false)
          setShowFinishConsultation(true);
        }}
         />
      )}
      {isUserTryingtoNavigate && (
        <UserTryingtoNavigate
          onHide={() => setIsUserTryingtoNavigate(false)}
          openDialog={isUserTryingtoNavigate}
        />
      )}
      {privewVideos
        ? (!audioBlock || !videoBlock) &&
          googleBlockedUI && (
            <GoogleBlockeUI
              audioBlock={audioBlock}
              videoBlock={videoBlock}
              handleClosePopup={handleClosePopup}
            />
          )
        : ""}
      {console.log("tiles", tiles, "meetingStatus", meetingStatus)}
    </DashboardLayout>
  );
}

export default Chime;
