import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
    phone_number: string;
    phone_code: string;
    phone_id: string;
    user_status: string;
    flag: string;
    country_code: string;
    user_name: string;
    avatar?:string
    // authToken: string;
}

const initialState: AuthState = {
    phone_number: "",
    phone_code: "",
    phone_id: "",
    user_status: "",
    flag: "",
    country_code: "",
    user_name: "",
    avatar:""
    // authToken: ""

};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            return { ...state, ...action.payload };
        },
        signup: (state, action) => {
            return { ...state, ...action.payload };
        },
        setUser: (state, action) => {
            return { ...state, ...action.payload };
        },
        logout: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { login, signup, logout, setUser } = authSlice.actions;

export default authSlice.reducer;
