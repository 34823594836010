export let toastIdsConstant: any = {
    registerationPart1IdOnSuccess: "registerationPart1IdOnSuccess",
    registerationPart1IdOnFailure: "registerationPart1IdOnFailure",
    registerationPart2IdOnSuccess: "registerationPart2IdOnSuccess",
    registerationPart2IdOnFailure: "registerationPart2IdOnFailure",
    registerationPart3IdOnSuccess: "registerationPart3IdOnSuccess",
    registerationPart3IdOnFailure: "registerationPart3IdOnFailure",
    registerationPart4IdOnSuccess: "registerationPart4IdOnSuccess",
    registerationPart4IdOnFailure: "registerationPart4IdOnFailure",
    emailVerificationFailure: "emailVerificationFailure",
    emailVerificationSuccess: "emailVerificationSuccess",
    pleaseVerifyEmailFirst: "pleaseVerifyEmailFirst",
    userDeniedGeoLocationPermission: "userDeniedGeoLocationPermission",
    locationInformationisUnavaibale: "locationInformationisUnavaibale",
    reqstToServerGetuserLocationIsTimedOut:
        "reqstToServerGetuserLocationIsTimedOut",
    geolocationUnknownErr: "geolocationUnknownErr",
    loginApiFailure: "loginApiFailure",
    loginApiSucess: "loginApiSucess",
    otpSentSuccessfuly: "otpSentSuccessfuly",
    otpVerifyApiFailed: "otpVerifyApiFailed",
    pmAddedSuccessfully: "pmAddedSuccessfully",
    pmAddedFailed: "pmAddedFailed",
    fileNotSupported: "fileNotSupported",
    reportAlreadySentToDoctor: "reportAlreadySentToDoctor",
    onlySixHospitalPhotosAowed: "onlySixHospitalPhotosAowed",
    failedOnForgotPaswdApiErr: "failedOnForgotPaswdApiErr",
    addBranchSuccess: "addBranchSuccess",
    addBranchFailure: "addBranchFailure",
    addUserSuccess: "addUserSuccess",
    addUserFailure: "addUserFailure",
    verifyUrlFailure: "verifyUrlFailure",
    passwordValidationError: "passwordValidationError",
    passwordValidationSuccess: "passwordValidationSuccess",
    editProfileSuccess: "editProfileSuccess",
    editProfileFailure: "editProfileFailure",
    editWorkingHoursSuccess: "editWorkingHoursSuccess",
    editWorkingHoursFailure: "editWorkingHoursFailure",
    branchStatusChangeSuccess: "branchStatusChangeSuccess",
    branchStatusChangeFailure: "branchStatusChangeFailure",
    userStatusChangeSuccess: "userStatusChangeSuccess",
    userStatusChangeFailure: "userStatusChangeFailure",
    searchSmartCardFailure: "searchSmartCardFailure",
    insuranceCompanyError: "insuranceCompanyError",
    insuranceCompanySuccess: "insuranceCompanySuccess",
    labTestValidationFailureClaim: "labTestValidationFailureClaim",
    claimSentInfo: "claimSentInfo",
    verifyPatient: "verifyPatient",
    editClaimSuccessful: "editClaimSuccessful",
    editClaimFailure: "editClaimFailure",
    createClaimValidationFailure: "createClaimValidationFailure",
    preAuthApiSuccess: "preAuthApiSuccess",
    uploadImageFailure: "uploadImageFailure",
    createClaimSentOtpFailure: "createClaimSentOtpFailure",
    createClaimSentOtpInfo: "createClaimSentOtpInfo",
    getBranchDetailApiFailure: "getBranchDetailApiFailure",
    getUserDetailApiFailure: "getUserDetailApiFailure",
    paitentInsuranceNothaveProcedure: "paitentInsuranceNothaveProcedure",
    failInDeletePreAuthClaim: "failInDeletePreAuthClaim",
    sendToLabRequestSuccess: "sendToLabRequestSuccess",
    sendToLabRequestFailure: "sendToLabRequestFailure",
    treatmentError: "treatmentError",
    treatmentSuccess: "treatmentSuccess",
    drugModalError: "drugModalError",
    addPrescriptionFailure: "addPrescriptionFailure",
    addPatientSuccess: "addPatientSuccess",
    addPatientFailure: "addPatientFailure",
    patientOnMute: "patientOnMute",
    patientOnUnMute: "patientOnUnMute",
    doctorOnMute: "doctorOnMute",
    doctorOnUnMute: "doctorOnUnMute",
    reconnecting: "reconnecting",
    noInternet: "noInternet",
    weakInternet: "weakInternet",
    patientLeftMeeting: "patientLeftMeeting",
    doctorHasLeftMeeting: "doctorHasLeftMeeting",
    patientConnectionDropped: "patientConnectionDropped",
    chimeSocketMsg: "chimeSocketMsg",
    networkError: "networkError",
    callCanNotStart: "callCanNotStart",
    writePrescriptionValidationFailure: "writePrescriptionValidationFailure",
    writePrescriptionValidationSuccess: "writePrescriptionValidationSuccess",
    noQue: "noQue",
    noAns: "noAns",
    setWebDataApiSuccess: "setWebDataApiSuccess",
    addedFaqSuccessfully: "addedFaqSuccessfully",
    emailStackOver: "emailStackOver",
    emailExist: "emailExist",
    requiredAllFieldData: "requiredAllFieldData",
    policyDataFetching: "policyDataFetching",
    policyDataFetching1: "policyDataFetching1",
    consent: "consent",

    apiFailure: "apiFailure",
    slotNotSelected: "slotNotSelected",
    addInsuranceFailure: "addInsuranceFailure",
    paymentFail: "paymentFail",
    paymentSuccess: "paymentSuccess",
    mediaPerm: "mediaPerm",
    sessionExp: "sessionExp",
    callstart: "callstart",
    endcall: "endcall",
    appointmentFailure: "appointmentFailure",
    appointmentSuccess: "appointmentSuccess",
    userNotActive: "userNotActive",
    onSuccessfullInitiatePayment: "onSuccessfullInitiatePayment",
    onFailOfInitiatePayment: "onFailOfInitiatePayment",
    siteNotThere: "siteNotThere",
    claimLocationUnavailable: "claimLocationUnavailable",
    imageUploadFailure: "imageUploadFailure",
    ProperInternetConenction: "ProperInternetConenction",
    notesDeleteSuccess: "notesDeleteSuccess",
    addInsuranceAlert: "addInsuranceAlert",
    fileSizeAlert: "fileSizeAlert",
    toastIdSickId: "toastIdSickId",
    audioPermission: "audioPermission",
    busycall: "busycall",
    somethingWrong: "somethingWrong",
    rescheduleError: "rescheduleError",
    FR: "FR",
    API400ERROR: "api400error",
    reducePriceErr: "reducePriceErr",
    urlAlreadyTaken: "urlAlreadyTaken",
    pharmacyNotAssocite: "pharmacyNotAssocite",
    emptyBenefitname: "emptyBenefitname",
    emptyProcedure: "emptyProcedure",
    emptyFee: "emptyFee",
    emptyQuantity: "emptyQuantity",
    ICD10NotAdded: "ICD10NotAdded"
};
