import React from "react";
// import { roles } from "../../../utility/roleConstant";
// import { getRoleIdInLS } from "../../../utility/util";
import { useSelector } from "react-redux";
import { Dialog, DialogContent } from "../../../../ui/dialog";

function UserNotAvailableForCall(props) {
  const { name, onHide, openDialog } = props;
  // const { apiData } = useSelector((state) => state?.websiteHome);
  return (
    <Dialog open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <h3 className="text-2xl font-semibold text-navy">Alert</h3>
        <div className="my-6 text-base font-medium text-navy">
          <p>
            {name ? name : "User"} is unavailable. <br /> Please try again
            later.
          </p>
        </div>
        <button
          className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5"
          onClick={onHide}
        >
          Ok
        </button>
      </DialogContent>
    </Dialog>
  );
}

export default UserNotAvailableForCall;
