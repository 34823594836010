import { createSlice } from '@reduxjs/toolkit';
import { IDoctor } from '../../utils/types';
interface InitialStateType {
    type: number;
    branches: any[];
    hospital: any[];
    doctors: IDoctor[];
    insurance_compnanies: any[];
    banner_data: any;
    contact_us_emails: any[];
    about_us_texts: string;
    body_data: any;
    specialization_data:any[];
    count_events_pending_action:number;
    total_records:number
}
const initialState:{doctorsListHome:InitialStateType,isLoading:boolean, totalRecords: number} = {
    doctorsListHome:{type:1,
    branches: [], 
    hospital: [],
    doctors:[],
    insurance_compnanies:[],
    banner_data:{},
    contact_us_emails:[],
    about_us_texts:'',
    body_data:{},
    specialization_data:[],
    count_events_pending_action:0,
    total_records:0},
    isLoading:false,
    totalRecords:0
    
};

const doctorListSlice = createSlice({
    name: 'doctor-list',
    initialState,
    reducers: {
        setDoctorList(state, action) {
            return { ...state, ...action.payload };
        },
        resetDoctorList(state) {
            state = initialState;
        }
    }
});

export const { setDoctorList, resetDoctorList } = doctorListSlice.actions;
export default doctorListSlice.reducer;
