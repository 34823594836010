// PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const isAuthenticated = Boolean(Cookies.get("user_name"));

  return isAuthenticated ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;
