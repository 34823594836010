
import { createSlice } from '@reduxjs/toolkit';
import { InsuranceCard } from '../../utils/types';

export interface IAddInsurance {
    openSelectInsuranceListDialog:boolean;
    openChangeInsuranceCompanyDialog:boolean;
    openAddMembershipDetailDialog:boolean;
    selectedInsuranceObject:InsuranceCard | null;
    selectedCard:"Insurance"|"Smart"|null;
    uploadedCardImage:string;
    selfieImage:string;
    memberNumber:string;
    isEditMode:boolean;
    cardType:string
}


const initialState: IAddInsurance = {
    openSelectInsuranceListDialog:false,
    openChangeInsuranceCompanyDialog:false,
    openAddMembershipDetailDialog:false,
    selectedInsuranceObject:null,
    selectedCard:null,
    uploadedCardImage:'',
    selfieImage:'',
    memberNumber:'',
    isEditMode:true,
    cardType:''
};

const addInsuranceCompanySlice = createSlice({
    name: 'addInsuranceCompany',
    initialState,
    reducers: {
        setAddInsuranceCompany(state, action) {
            return { ...state, ...action.payload }; // Set the entire state to the new array of cities
        },
        resetAddInsuranceCompany() {
            return initialState; // Reset the state to the initial empty array
        }
    }
});

export const { setAddInsuranceCompany, resetAddInsuranceCompany } = addInsuranceCompanySlice.actions;
export default addInsuranceCompanySlice.reducer;

