import React, { createContext, useContext, useState } from "react";

const SocketContext = createContext(undefined);

export const SocketProvider = ({ children }) => {
  const [socketData, setSocketData] = useState(null);

  return (
    <SocketContext.Provider value={{socketData, setSocketData }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket= () => {
    const context = useContext(SocketContext);
    if (context === undefined) {
      throw new Error("useNotification must be used within a NotificationProvider");
    }
    return context;
};