import { createSlice } from "@reduxjs/toolkit";
import { IHospitalAppointment } from "../../utils/types";

interface InitialStateType {
  appointmentSlotList: IHospitalAppointment[];
  isAppointmentSlotLoading: boolean;
}
const initialState: InitialStateType = {
  appointmentSlotList: [],
  isAppointmentSlotLoading: false,
};
const appointmentSlotSlice = createSlice({
  name: "appointment-slot",
  initialState,
  reducers: {
    setAppointmentSlot(state, action) {
      return { ...state, ...action.payload };
    },
    resetAppointmentSlot(state) {
      state = initialState;
    },
  },
});

export const { setAppointmentSlot, resetAppointmentSlot } =
  appointmentSlotSlice.actions;
export default appointmentSlotSlice.reducer;
