import { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import { apiParamsSpecialityConstant, Status } from "../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redux/store/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pagination from "../../components/Pagination";
import images from "../../utils/images";
import ChooseUsCard from "../../components/Card/ChooseUsCard";
import { getSpecializationList } from "../../actions";
import { getImage, getStagingImage } from "../../utils/helperfunction";

const Specialities = () => {
  const dispatch = useAppDispatch();
  const routerParam = useParams();
  const navigate = useNavigate();

  const { patientHomeData } = useAppSelector(
    (state: RootState) => state.patientHome
  );

  const [apiParamsOfSpecialities, setApiParamsOfSpecialities] = useState<any>(
    apiParamsSpecialityConstant
  );
  const [isMounted, setIsMounted] = useState(false);
  const appoinmentListRef = useRef<any>(null);
  const {
    specialization_data: specializationList,
    isLoading,
    totalRecords,
  } = useAppSelector((state: RootState) => state.specializationList);

  useEffect(() => {
    setIsMounted(true);
    if (!routerParam?.id) {
      getSpecializationListing(true, 1);
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      getSpecializationListing(false);
    }
  }, [apiParamsOfSpecialities?.limit, apiParamsOfSpecialities?.offset]);

  const getSpecializationListing = (
    isSetTotalRecords: boolean,
    isCountNeeded?: number
  ) => {
    dispatch(
      getSpecializationList(
        apiParamsOfSpecialities.offset,
        apiParamsOfSpecialities.limit,
        isSetTotalRecords,
        isCountNeeded
      )
    );
  };

  const handlePageChange = (data: { selected: number }) => {
    if (appoinmentListRef.current) {
      appoinmentListRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const newOffset = data.selected * apiParamsOfSpecialities.limit;
    setApiParamsOfSpecialities((prevState) => ({
      ...prevState,
      offset: newOffset,
    }));
  };

  return (
    <DashboardLayout>
      <div className="bg-background">
        <div className="container-sec">
          <img
            src={
              patientHomeData?.body_data?.is_provider
                ? getStagingImage(
                    patientHomeData?.banner_data_speciality?.banner_bg_image,
                    ""
                  )
                : images.SPECIALITYBANNER
            }
            alt="banner"
            className="w-full"
          />
        </div>
      </div>

      <div className="bg-greyBg">
        <div className="container-box">
          <div className="py-10">
            <div className="flex flex-col gap-2.5 mb-9">
              <h2 className="text-[28px] font-semibold text-navy">
                {`${totalRecords} Specialities`}
              </h2>
              <p className="text-lg font-normal text-navy">
                Book appointments with minimum wait-time & verified doctor
                details
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              {/* {isLoading ? (
                        <div className="space-y-8">
                            {[...Array(3)].map((_, index) => (
                                <Skeleton
                                    key={index}
                                    count={4}
                                    containerClassName="flex w-[1400px] justify-between space-x-4"
                                    height={300}
                                />
                            ))}
                        </div>
                    ) : (
                        specializationList?.length > 0 && specializationList?.map((data) => (
                            <ChooseUsCard
                                bannerImage={getImage(data?.image, images.PLACEHOLDER)}
                                views={data?.name}
                                title={data?.meta_title}
                                description={data?.meta_description}
                                onViewDoctors={() => {
                                    navigate(`/consult-doctor/spec/${data?.id}`);
                                }}
                            />
                        ))
                    )} */}
              {isLoading ? (
                <>
                  {[...Array(specializationList?.length)].map((_, index) => (
                    <Skeleton
                      key={index}
                      count={4}
                      containerClassName="flex w-[1400px] justify-between space-x-4"
                      height={300}
                    />
                  ))}
                </>
              ) : (
                specializationList?.length > 0 &&
                specializationList?.map((data) => (
                  <ChooseUsCard
                    bannerImage={getImage(data?.image, images.PLACEHOLDER)}
                    views={data?.name}
                    title={data?.meta_title}
                    description={data?.meta_description}
                    onViewDoctors={() => {
                      navigate(`/consult-doctor/spec/${data?.id}`);
                    }}
                    colorData={patientHomeData?.body_data}
                  />
                ))
              )}
            </div>

            {specializationList?.length !== 0 && (
              <div className="my-10">
                <Pagination
                  totalPages={totalRecords / apiParamsOfSpecialities?.limit}
                  handlePageChange={handlePageChange}
                  currentPage={0}
                />
              </div>
            )}
            {specializationList?.length == 0 && !isLoading && (
              <div className="flex flex-col justify-center items-center h-full mb-20 text-center">
                <h4 className="text-[28px] font-semibold">
                  No specialities found!
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Specialities;
