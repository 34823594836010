// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from "./redux/store/store"
import ToasterContainer from './components/ToastContainer';
import {
	MeetingProvider,
	lightTheme,
	MeetingManager,
	UserActivityProvider,
} from "amazon-chime-sdk-component-library-react";
import { LogLevel,ConsoleLogger } from 'amazon-chime-sdk-js';
import { ThemeProvider } from "styled-components";
import Loader from './components/Loader';

// const meetingManager = new MeetingManager({
  //@ts-ignore
  // logLevel: LogLevel.INFO,
  // configuration: {
  //   connectionHealthPolicyConfiguration: {
  //     connectionWaitTimeMs: 20000,
  //     connectionUnhealthyThreshold: 20,
  //     missedPongsUpperThreshold: 3,
  //   },
  // },
// });
const logger = new ConsoleLogger('MeetingLogs', LogLevel.INFO);

// Create a meeting manager and pass the logger
const meetingManager = new MeetingManager(
     logger,
    // deviceController: new DefaultDeviceController(logger),
    // simulcastEnabled: true,
);
// meetingManager.configure({
//   logger: logger,
//   deviceController: new DefaultDeviceController(logger),
//   simulcastEnabled: true,
// });

// Create a logger with the correct LogLevel


const meetingConfig = {
	simulcastEnabled: true,
	meetingManager,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={lightTheme}>
  <MeetingProvider>
    <UserActivityProvider>
    <Provider store={store}>
      <ToasterContainer/>
      <App />
      <Loader/>
    </Provider>
    </UserActivityProvider>
    </MeetingProvider>
    </ThemeProvider>
  // </React.StrictMode>
);
