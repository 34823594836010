import Cookies from "js-cookie";
import ENV_VARIABLES from "./api/env.variable";
import { appointmentStatusConstantForText, appointmentStatusFilterConstant, appointmentStatusSentenceFilterConstant, patientInsuraceStatus }
  from "./constant"; import moment from "moment";
import { toastIdsConstant } from "../constants/toastIdConstant";
import { toast } from "react-toastify";

export const getImage = (
  primarySource: string | undefined,
  fallbackSource: string
): string => {
  const ourImage =
    (primarySource && ENV_VARIABLES.IMAGE_BASE_URL + primarySource) ||
    fallbackSource;
  return ourImage;
};

export const getPhoneId = () => {
  let phoneId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return phoneId;
};

export const isUserLogggedIn = () => {
  const userDataFromCookies = Cookies.get("userData");
  if (userDataFromCookies) {
    try {
      const user_status = JSON.parse(userDataFromCookies)?.user_status;
      return user_status === "1" ? true : false;
    } catch (error) {
      console.error("Error parsing user data from cookies:", error);
      return false;
    }
  }
};

export const getUserId = () => {
  const userDataFromCookies = Cookies.get("userData");
  if (userDataFromCookies) {
    try {
      const user_id = JSON.parse(userDataFromCookies)?.id;
      return user_id;
    } catch (error) {
      console.error("Error parsing user data from cookies:", error);
      return null;
    }
  }
};

export const getUserToken = () => {
  const userDataFromCookies = Cookies.get("userData");
  if (userDataFromCookies) {
    try {
      const token = JSON.parse(userDataFromCookies)?.token;
      return token;
    } catch (error) {
      console.error("Error parsing user data from cookies:", error);
      return null;
    }
  }
};

export const getUserStatus = () => {
  const userDataFromCookies = Cookies.get("userData");
  if (userDataFromCookies) {
    try {
      const user_status = JSON.parse(userDataFromCookies)?.user_status;
      return user_status;
    } catch (error) {
      console.error("Error parsing user data from cookies:", error);
      return null;
    }
  }
};

export const getGuestUser = () => {
  const guestUserDataFromCookies = Cookies.get("guest_user");
  if (guestUserDataFromCookies) {
    try {
      const isGuestUser = JSON.parse(guestUserDataFromCookies);
      return isGuestUser
    }
    catch (error) {
      console.error("guest user failed", error);
      return false;
    }
  }
}

export const getIsRegisteredUser = () => {
  const registeredUserDataFromCookies = Cookies.get("registered_user");
  if (registeredUserDataFromCookies) {
    try {
      const isRegisteredUser = JSON.parse(registeredUserDataFromCookies);
      return isRegisteredUser
    }
    catch (error) {
      console.error("registered user failed", error);
      return true;
    }

  }
  else {
    return true
  }
}
export const clearCookies = () => {
  Cookies.remove("user_name");
  Cookies.remove("userData");
  Cookies.remove("guest_user");
  Cookies.remove("guest_user_data")
  Cookies.remove("registered_user")
}


export const getCityCode = () => {
  const userDataFromCookies = Cookies.get("userData");
  if (userDataFromCookies) {
    try {
      const city_id = JSON.parse(userDataFromCookies)?.city_id;
      return city_id;
    } catch (error) {
      console.error("Error parsing user data from cookies:", error);
      return null;
    }
  }
};

export const WebsiteAuthHeaders = (headers?: any) => ({
  ...headers,
  "LiviaApp-Token": getUserToken(),
  "LiviaApp-country": localStorage.getItem("website_country_code") ?? "ke",
  "LiviaApp-language": "en",
  "LiviaApp-city": getCityCode() ? getCityCode() : "186301",
  // "LiviaApp-Url":
  //   process.env.NODE_ENV == "production"
  //     ? window.location.host
  //     : window.location.host,
});

export const getPolicyText = (status: number) => {
  switch (status) {
    case patientInsuraceStatus.ACTIVE:
      return "Policy is active";
    case patientInsuraceStatus.POLICY_EXPIRED:
      return "Policy is expired";
    case patientInsuraceStatus.BALANCE_INSUFFICIENT:
      return "Insufficient Balance";
    case patientInsuraceStatus.BENEFITS_UPDATING:
      return "Retrieving benefit details. This may take a few seconds...";
    case patientInsuraceStatus.TIME_OUT_ERROR:
      return "Time out error. Unable to retrieve benefit details. Try again later.";
    case patientInsuraceStatus.DOCTOR_NOT_HAVE_SPECIALIZATION:
      return "Sorry, the patient's scheme is restricted.";
  }
};
export const getWarningMsgOnPolicySelect = (status: number) => {
  switch (status) {
    case patientInsuraceStatus.POLICY_EXPIRED:
      // return 'Your Policy is Expired. You may proceed with this order via Self Pay Appointment.'
      return "Your medical insurance has expired or your details have not been updated yet. Please contact your medical insurance provider to reactivate your membership.";
    case patientInsuraceStatus.BALANCE_INSUFFICIENT:
      return "Your Outpatient balance is insufficient. You may proceed with this order via Self Pay Appointment.";
    case patientInsuraceStatus.BENEFITS_UPDATING:
      // return 'We are retrieving your benefit balance. However, if you wish to pay by card or Mpesa, you may do so by tapping on Self Pay Appointment.'
      // return 'We are retrieving your benefit balance. However, if you wish to pay by cash or Mpesa, you may do so by tapping on Self Pay Appointment.'
      return "We are retrieving your benefit balance. However, if you wish to pay by card or Mpesa, you may do so by tapping on Self Pay Appointment.";

    // case patientInsuraceStatus.DOCTOR_NOT_HAVE_SPECIALIZATION:
    // 	return "Sorry, you can not proceed booking the appointment with the selected insurance company as the Doctor is not associated with the insurance company. Please change Insurance company or choose a different the doctor.";
  }
};
export function getRelationshipColor(params: any) {
  return "green";
}

export function parseWorkingTime(totalMinutes: any) {
  const hour =
    Math.floor(totalMinutes / 60) == 0 ? "00" : Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60 == 0 ? "00" : totalMinutes % 60;
  return `${JSON.stringify(hour)?.length == 1 ? "0" + hour : hour}:${JSON.stringify(minutes)?.length == 1 ? "0" + minutes : minutes
    }`;
}

export function formatDate(inputDate: string | number | Date) {
  const date = new Date(inputDate);

  const options: any = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

// export const appointmentStatusConstantForText = {
//   NeedToReschedule: "-1",
//   WaitingFromDoctor: "1",
//   Approved: "2",
//   DeclinedByDoctor: "3",
//   Ended: "4",
//   DeclinedByCustomer: "5",
//   Free: "6",
//   Blocked: "7",
//   WaitingFromCustomer: "8",
//   WaitingFromPaymentApproval: "9",
//   PaymentUnsuccesful: "10",
//   RefundProvided: "11",
//   Reported: "12",
// };


// export function getAppointmentStatusColorForMyWebsite(value: any) {
//   switch (value) {
//     case appointmentStatusConstantForText.NeedToReschedule:
//       return 'black'
//     case appointmentStatusConstantForText.WaitingFromDoctor:
//       return 'orange'
//     case appointmentStatusConstantForText.Approved:
//       return 'green'
//     case appointmentStatusConstantForText.DeclinedByDoctor:
//       return '#FF8308'
//     case appointmentStatusConstantForText.Ended:
//       return '#4CAF50'
//     case appointmentStatusConstantForText.DeclinedByCustomer:
//       return '#FF0000'
//     case appointmentStatusConstantForText.Free:
//       return 'black'
//     case appointmentStatusConstantForText.Blocked:
//       return 'red'
//     case appointmentStatusConstantForText.WaitingFromCustomer:
//       return 'orange'
//     case appointmentStatusConstantForText.WaitingFromPaymentApproval:
//       return 'orange'
//     case appointmentStatusConstantForText.PaymentUnsuccesful:
//       return 'red'
//     case appointmentStatusConstantForText.RefundProvided:
//       return 'green'
//     case appointmentStatusConstantForText.Reported:
//       return '#303F9F'
//     default:
//       return 'black'
//   }
// }


export function getAppointmentStatusColorForMyWebsite(value: any) {
  switch (value) {
    case appointmentStatusConstantForText.NeedToReschedule:
      return 'black'
    case appointmentStatusConstantForText.WaitingFromDoctor:
      return 'purple'
    case appointmentStatusConstantForText.Approved:
      return 'lightgreen'
    case appointmentStatusConstantForText.DeclinedByDoctor:
      return 'DeclinedByDoctor'
    case appointmentStatusConstantForText.Ended:
      return 'green'
    case appointmentStatusConstantForText.DeclinedByCustomer:
      return 'DeclinedByCustomer'
    case appointmentStatusConstantForText.Free:
      return 'black'
    case appointmentStatusConstantForText.Blocked:
      return 'blocked'
    case appointmentStatusConstantForText.WaitingFromCustomer:
      return 'WaitingFromCustomer'
    case appointmentStatusConstantForText.WaitingFromPaymentApproval:
      return 'WaitingFromPaymentApproval'
    case appointmentStatusConstantForText.PaymentUnsuccesful:
      return 'yellow'
    case appointmentStatusConstantForText.RefundProvided:
      return 'refundProvided'
    case appointmentStatusConstantForText.Reported:
      return 'reported'
    default:
      return 'black'
  }
}


// export function canJoinCall(fromdate: any) {
//   if (Date?.parse(new Date()) < Date?.parse(fromdate)) {
//     let duration: any =
//       new Date(new Date(fromdate?.split("+")[0])?.getTime()) -
//       new Date().getTime();
//     // when date or time is in past
//     if (duration < 0) {
//       return true;
//     }
//     let milliseconds: any = Math.floor((duration % 1000) / 100);
//     let elapsedDays: any = Math.floor(duration / (24 * 60 * 60 * 1000));
//     let seconds: any = Math.floor((duration / 1000) % 60);
//     let elapsedMinutes: any = Math.floor((duration / (1000 * 60)) % 60);
//     let elapsedHours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

//     elapsedHours =
//       elapsedHours < 10 && elapsedHours > 0 ? "0" + elapsedHours : elapsedHours;
//     elapsedMinutes =
//       elapsedMinutes < 10 && elapsedMinutes > 0
//         ? "0" + elapsedMinutes
//         : elapsedMinutes;
//     seconds = seconds < 10 && seconds > 0 ? "0" + seconds : seconds;
//     if (
//       elapsedDays == 0 &&
//       elapsedHours == 0 &&
//       elapsedMinutes <= 5 &&
//       elapsedMinutes >= 0
//     ) {
//       return true;
//     } else {
//       if (elapsedDays == 0 && elapsedHours < 24) {
//         if (elapsedHours == 0) {
//           return false;
//         } else {
//           if (elapsedMinutes == 0) {
//             return false;
//           } else {
//             return false;
//           }
//         }
//       } else {
//         return false;
//       }
//     }
//   } else return true;
// }



export function canJoinCall(fromDate: any): boolean {
  const currentDate = new Date();
  const fromDateParsed = new Date(fromDate);

  // Check if current date is before the provided date
  if (currentDate < fromDateParsed) {
    let duration = fromDateParsed.getTime() - currentDate.getTime();

    // Date is in the past
    if (duration < 0) {
      return true;
    }

    const milliseconds = Math.floor((duration % 1000) / 100);
    const elapsedDays = Math.floor(duration / (24 * 60 * 60 * 1000));
    const seconds = Math.floor((duration / 1000) % 60);
    let elapsedMinutes = Math.floor((duration / (1000 * 60)) % 60);
    let elapsedHours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    // Format hours, minutes, and seconds if needed
    const formattedHours = elapsedHours < 10 ? "0" + elapsedHours : elapsedHours;
    const formattedMinutes = elapsedMinutes < 10 ? "0" + elapsedMinutes : elapsedMinutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    // Allow join if less than 5 minutes remaining
    if (elapsedDays === 0 && elapsedHours === 0 && elapsedMinutes <= 5 && elapsedMinutes >= 0) {
      return true;
    } else if (elapsedDays === 0 && elapsedHours < 24) {
      return false;
    } else {
      return false;
    }
  } else {
    // Allow joining if current date is after or the same as the fromDate
    return true;
  }
}



// export function printDifference(endDate: any, fromdate: any) {


//   console.log("endDate", endDate, fromdate)
//   // Get current date and time
//   const now = moment();

//   // Parse fromdate with moment
//   const fromDateMoment = moment(fromdate);

//   // Check if the current date is before the fromdate
//   if (now.isBefore(fromDateMoment)) {
//     const duration = fromDateMoment.diff(now); // Duration in milliseconds

//     // When date or time is in the past
//     if (duration < 0) {
//       return "Start Call";
//     }

//     const elapsedDays = fromDateMoment.diff(now, 'days');
//     const elapsedHours = fromDateMoment.diff(now, 'hours') % 24;
//     const elapsedMinutes = fromDateMoment.diff(now, 'minutes') % 60;

//     if (elapsedDays === 0 && elapsedHours === 0 && elapsedMinutes <= 5) {
//       return "Start Call";
//     } else if (elapsedDays === 0 && elapsedHours < 24) {
//       if (elapsedHours === 0) {
//         return `You may connect in ${elapsedMinutes} min`;
//       } else if (elapsedMinutes === 0) {
//         return `You may connect in ${elapsedHours} hr`;
//       } else {
//         return `You may connect in ${elapsedHours} hr ${elapsedMinutes} min`;
//       }
//     } else {
//       return `You may connect on ${moment(endDate).format('YYYY-MM-DD')}`;
//     }
//   } else {
//     return "Start Call";
//   }
// }

export function printDifference(endDate: any, fromdate: any): any {
  // Check if the current date is before the fromdate
  if (Date?.parse(new Date().toISOString()) < Date?.parse(fromdate)) {
    const today = moment([
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    ]);

    const appDate = moment([
      new Date(fromdate).getFullYear(),
      new Date(fromdate).getMonth(),
      new Date(fromdate).getDate(),
    ]);

    let duration =
      new Date(new Date(fromdate.split("+")[0]).getTime()).getTime() -
      new Date().getTime();

    // when date or time is in the past
    if (duration < 0) {
      return "Start Call";
    }

    let milliseconds = Math.floor((duration % 1000) / 100);
    let elapsedDays = Math.floor(duration / (24 * 60 * 60 * 1000));
    let seconds: string | number = Math.floor((duration / 1000) % 60);
    let elapsedMinutes: string | number | any = Math.floor(
      (duration / (1000 * 60)) % 60
    );
    let elapsedHours: string | number | any = Math.floor(
      (duration / (1000 * 60 * 60)) % 24
    );

    elapsedHours =
      elapsedHours < 10 && elapsedHours > 0 ? "0" + elapsedHours : elapsedHours;
    elapsedMinutes =
      elapsedMinutes < 10 && elapsedMinutes > 0
        ? "0" + elapsedMinutes
        : elapsedMinutes;
    seconds = seconds < 10 && seconds > 0 ? "0" + seconds : seconds;

    if (
      elapsedDays == 0 &&
      elapsedHours == 0 &&
      elapsedMinutes <= 5 &&
      elapsedMinutes >= 0
    ) {
      return "Start Call";
    } else {
      if (elapsedDays == 0 && elapsedHours < 24) {
        if (elapsedHours == 0) {
          return "You may connect in " + elapsedMinutes + " min";
        } else {
          if (elapsedMinutes == 0) {
            return "You may connect in " + elapsedHours + " hr";
          } else {
            return (
              "You may connect in " +
              elapsedHours +
              " hr " +
              elapsedMinutes +
              " min"
            );
          }
        }
      } else {
        return (
          "You may connect on " +
          new Date(endDate)?.toISOString().substring(0, 10)
        );
      }
    }
  } else {
    return "Start Call";
  }
}




export function canWeShowDeclineBtnToPatientOnWebsite(status: any, isOnline: any) {
  return (
    isOnline == 0 &&
    (status == appointmentStatusConstantForText.WaitingFromDoctor ||
      status == appointmentStatusConstantForText.Approved ||
      status == appointmentStatusConstantForText.WaitingFromCustomer)
  )
}

export function canWeShowApproveBtnToPatientOnWebsite(status: any, isOnline: any) {
  return (
    isOnline == 0 &&
    status == appointmentStatusConstantForText.WaitingFromCustomer
  )
}

export function canWeShowRescheduleBtnToPatientOnWebsite(status: string) {
  return status == appointmentStatusConstantForText.WaitingFromCustomer
}

export function getAppointmentStatusForMyWebsite(value: string | number | undefined) {
  const item = appointmentStatusFilterConstant.find(
    (item) => item.value == value,
  )
  return item?.text
}

export function getAppointmentStatusSentenceForMyWebsite(value: any) {
  const item = appointmentStatusSentenceFilterConstant.find(
    (item: { value: any; }) => item.value == value,
  )
  return item?.text
}

export function canWeShowStartConsultationBtn(status: string, isOnline: any) {
  return isOnline == 1 && status == appointmentStatusConstantForText.Approved
}

export function canWeShowReportAppoitmentBtn(reportAllowed: any) {
  return reportAllowed == 1
}

export function canWeShowRedDotOnAppointment(status: string | null, isOnline: number) {
  return status != null && (status == appointmentStatusConstantForText.WaitingFromCustomer || (isOnline == 1 && status == appointmentStatusConstantForText.WaitingFromCustomer) || status == appointmentStatusConstantForText.WaitingFromPaymentApproval)
}

export function getTabIdBySesionStorage() {
  return sessionStorage.getItem("tabId");
}

export const getTabId = () => {
  if (getTabIdBySesionStorage()) {
    return getTabIdBySesionStorage();
  } else {
    let tabId = "xxxxxxxx-4xxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    sessionStorage.setItem("tabId", tabId);

    return tabId;
  }
};

export const getPolicyTextColor = (status) => {
  switch (status) {
    case patientInsuraceStatus.ACTIVE:
      return "text-seaGreen";
    case patientInsuraceStatus.BALANCE_INSUFFICIENT:
    case patientInsuraceStatus.TIME_OUT_ERROR:
    case patientInsuraceStatus.POLICY_EXPIRED:
      return "text-error";
    case patientInsuraceStatus.BENEFITS_UPDATING:
      return "text-warning";
  }
};

// export function checkFileSize(file) {
//   const fileSize = file.size;
//   const fsize = Math.round(fileSize / 1024);
//   if (fsize >= 8192) {
//     if (!toast.isActive(toastIdsConstant.fileSizeAlert)) {
//       return (toastIdsConstant.fileSizeAlert = toast.warn(
//         "File should not be greater than 8 MB",
//         {
//           toastId: toastIdsConstant.fileSizeAlert,
//           className: "toast-error",
//         }
//       ));
//     }
//     return true;
//   }
// }



export function checkFileSize(file) {
  if (!file) {
    return false;
  }

  const fileSize = file.size;
  const fsize = Math.round(fileSize / 1024); // Convert to KB

  if (fsize >= 8192) { 
    if (!toast.isActive(toastIdsConstant.fileSizeAlert)) {
      toastIdsConstant.fileSizeAlert = toast.warn(
        "File should not be greater than 8 MB",
        {
          toastId: toastIdsConstant.fileSizeAlert,
          className: "toast-error",
        }
      );
    }
    return true; // Indicate that the file size is too large
  }

  return false; // File size is acceptable
}



// export function uploadDocumentImage(binaryImage: any) {
//   return axios.patch(
//     `${process.env.API_URL}/image`,
//     {
//       image: binaryImage,
//       type: "hospitals",
//     },
//     {
//       headers: WebsiteAuthHeaders(),
//     }
//   );
// }


export const getStagingImage = (
  primarySource: string | undefined,
  fallbackSource: string
): string => {
  const ourImage =
    (primarySource && ENV_VARIABLES.API_BASE_URL + primarySource) ||
    fallbackSource;
  return ourImage;
};

export const getPlaceholderForInsuranceMember= (selectedCard, isEditMode, cardType)=>{
  try{
    if(!isEditMode){
      if(cardType==="2")return "Smart Card Number"
      else if(cardType==='3') return 'LCT Number'
      else return 'Member Number (Entity ID)'
    }
    else{
      if(selectedCard==="Insurance")return'Member Number (Entity ID)'
      else return "Smart Card Number"
    }
  }
  catch(error){
    return 'Member Number (Entity ID)'
  }
  
}


export const getButtonStyles = (isProvider, btnColorData) => {
  return {
    backgroundColor: isProvider ? btnColorData?.btn_bg_color : '#23901E',
    color: isProvider ? btnColorData?.btn_txt_color : 'white',
    borderColor: isProvider ? btnColorData?.btn_bg_color : '#23901E',
  };
};


export const getSimpleButtonStyles = (isProvider, btnColorData) => {
  return {
    backgroundColor: isProvider ? btnColorData?.btn_txt_color : '#F1FFF5',
    color: isProvider ? btnColorData?.btn_bg_color : '#23901E',
    borderColor: isProvider ? btnColorData?.btn_bg_color : '#23901E',
  };
};

