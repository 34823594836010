import React from "react";
import { Env } from "../../constants";
import { getImage } from "../../utils/helperfunction";
import { Dialog, DialogContent } from "../../ui/dialog";
// import doctopPlaceHolder from "../../assets/image/doctor_placeholder.svg";

function CallKit(props) {
  const {
    res,
    cancelCallHandler,
    acceptCallHandler,
    isAddFaqApiLoading,
    openDialog,
  } = props;
  return (
    <Dialog className="popup-cover" open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <h2 className="text-2xl font-semibold text-navy">Consultation Call</h2>

        <div className="my-6 flex flex-col gap-4">
          <img
            src={getImage(res?.result?.eventData?.user_avatar, "")}
            className="w-[80px] h-[80px] rounded-full object-cover mx-auto"
          />
          <p className="text-base font-medium text-navy">{`${res.result.fromUserName} is calling you...`}</p>
        </div>

        <div className="flex items-center justify-center gap-4">
          <button
            className="bg-error text-white border-none text-base font-semibold rounded-lg py-2 px-5 w-[150px]"
            onClick={cancelCallHandler}
          >
            Decline
          </button>
          <button
            className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5 w-[150px]"
            onClick={() => acceptCallHandler(res)}
          >
            Accept
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CallKit;
