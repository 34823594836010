import React from "react";
import { useAttendeeStatus } from "amazon-chime-sdk-component-library-react";
import micOn from "../../../assets/calling-icons/mic_on.svg";
import micOff from "../../../assets/calling-icons/mic_off.svg";
import eyeOn from "../../../assets/calling-icons/eye_on.svg";
import eyeOff from "../../../assets/calling-icons/eye_off.svg";



// import MicOffIcon from "@mui/icons-material/MicOff";
// import MicIcon from "@mui/icons-material/Mic";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
function MuteContorls({ attendeeId }) {
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  return (
    <div className="mute-controls-div">
      <div>{!muted ? <img src={micOn} alt={"Mic is on"}/> : <img src={micOff} alt={"Mic is off"}/>}</div>
      <div>{!videoEnabled ? <img src={eyeOff} alt={"Mic is off"}/>: <img src={eyeOn} alt={"Mic is on"}/>}</div>
    </div>
  );
}

export default MuteContorls;
