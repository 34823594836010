import React from "react";
import Select, { components } from "react-select";
import DropdownArrow from "../../assets/icons/dropdown-arrow.svg";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownArrow} alt="dropdown" loading="eager" />
    </components.DropdownIndicator>
  );
};

const CustomPlaceholder = (props) => {
  console.log("props", props)
  return(
  <components.Placeholder {...props}>
    <div className="flex items-center justify-between cursor-pointer">
    {props?.selectProps?.icon? <img src={props?.selectProps?.icon} className="mr-1"/>:""}
    {props.children}
    </div>
  </components.Placeholder>
)};


const SelectDropdown = ({ onChange, className, value, options, placeholder, customStyles,icon="", ...props }: any) => {
  return (
    <Select
      components={{ DropdownIndicator, Placeholder:CustomPlaceholder }}
      onChange={onChange}
      className={className}
      value={value}
      options={options}
      placeholder={placeholder}
      styles={customStyles}
      icon={icon}
      {...props}

    />
  );
};

export default SelectDropdown;
