import React from "react";
import { Dialog, DialogContent } from "../../../ui/dialog";
// import { Icon } from 'semantic-ui-react'

function UserTryingtoNavigate(props) {
  const { onHide, openDialog } = props;

  return (
    <Dialog className="popup-cover" open={openDialog}>
      <DialogContent className="max-w-[400px] w-full rounded-2xl bg-white text-center p-6">
        <div className="popup-header">
          <h2 className="text-2xl font-semibold text-error">Attention</h2>
        </div>

        <p className="my-6 text-base font-medium text-navy">
          You can't navigate to other page during call. Please disconnect the
          call in order to move out from the page.
        </p>

        <div className="popup-footer">
          <button
            className="bg-seaGreen text-white border-none text-base font-semibold rounded-lg py-2 px-5"
            onClick={onHide}
          >
            Ok
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UserTryingtoNavigate;
