const END_POINTS = {
  PATIENT_HOME: "/api/patient-home/1",
  USER_RATING: "/api/user-event-rating",
  COMMON: "/api/auth",
  SIGNUP: "/api/auth/2",
  CITY: "/api/city",
  ADD_DOCTOR_VIEW: "/api/doctor/1",
  DOCTOR_DETAILS: "/api/patient-home",
  PATIENT_INSURANCE_COMPANY: "/api/insurance-company",
  CALENDER: "/api/calendar",
  EVENT: "/api/event",
  GETNAME:"/api/hospital-user",
  ADDGUESTUSER:"/api/hospital-user",
  APPOINMENT_LISTING:"/api/hospital-event",
  PROFILE:"/api/user",
  UPLOAD_IMAGE:"/api/image",
  PATIENT_INSURANCE_COMPANY_MEMBER:"/api/insurance-company-member"
};
export default END_POINTS;
