import React, { ReactElement, useEffect, useState } from "react";
import Footer from "../components/Common/footer";
import Header from "../components/Common/header";
import { useLocation } from "react-router-dom";
import { SocketProvider } from "../components/MeetingChime/socketContext";
import MeetingNotificationWithSocket from "../components/socket/meeting.notification.with.socket";
import { clearCookies, getGuestUser, getIsRegisteredUser } from "../utils/helperfunction";
interface ChildProps {
  notificationData?: any; // Adjust the type as needed
}
const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation(); // Get the current route's path

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    if((getGuestUser() ||  !getIsRegisteredUser()) && !window?.location?.href?.includes("my-appointments"))
    {
      clearCookies();
    }
  }, [pathname]);
  const [isInternetAvailable, setIsInternetAvailable] = useState(true);
	useEffect(() => {
		const onlineInterval = setInterval(() => {
			navigator?.onLine? setIsInternetAvailable(true) : setIsInternetAvailable(false);
		}, 1000);
		return () => clearInterval(onlineInterval);
	}, []);

  return (
    <div>
      <Header />
      {!isInternetAvailable && (
						<div className="no-internet">No internet connection</div>
					)}
      <MeetingNotificationWithSocket />
      <div className="mx-auto">{children}</div>
      <Footer />
    </div>
  );
};

export default DashboardLayout;
