import { createSlice } from '@reduxjs/toolkit';
import { IAppoinmentList, IDoctor } from '../../utils/types';

interface InitialStateType {
    appoimntentList: IAppoinmentList | null;
    isLoading: boolean;
}

const initialState: InitialStateType = {
    appoimntentList: null,
    isLoading: false,
};

const appointmentListSlice = createSlice({
    name: 'appoinment-list',
    initialState,
    reducers: {
        setAppoinmentList(state, action) {
            return { ...state, ...action.payload };
        },
        resetAppoinmentList(state) {
            state = initialState;
        }
    }
});

export const { setAppoinmentList, resetAppoinmentList } = appointmentListSlice.actions;
export default appointmentListSlice.reducer;
