import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getEvent } from './meeting.api';
import { RootState } from '../../redux/store/store';
// import { globalLoaderTrue } from '../../redux/slices/globalLoaderSlice';

export const getMeetingAction = createAsyncThunk(
  'meeting',
  async (params: any, { dispatch }) => {
    try {
      // dispatch(globalLoaderTrue());
      const response: AxiosResponse<any> = await getEvent(params);
      // dispatch(globalLoaderTrue());
      return response.data?.body;
    } catch (error) {
      // dispatch(globalLoaderTrue());
    }
    return [];
  }
);

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState: {
    data: [] as Body[],
    offset: 0,
    limit: 9,
    specializationId: "all",
    isOnline: 1,
    search: "",
    pharmacyConsultation: 1,
    count: 0,
  },
  reducers: {
    doctorOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    doctorLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    doctorSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    doctorSpecializationId: (state, action: PayloadAction<string>) => {
      state.specializationId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMeetingAction.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  }
});

export const { doctorOffset, doctorSearch, doctorLimit, doctorSpecializationId } = doctorSlice.actions;

export const selectDoctor = (state: RootState) => state.doctor;

export default doctorSlice.reducer;
