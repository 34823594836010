import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false, // Indicates if the global loader should be visible
    apiRequestCount: 0 // Tracks the number of active API requests
};

const globalLoaderSlice = createSlice({
    name: 'global-loader',
    initialState,
    reducers: {
        globalLoaderStart(state) {
            state.apiRequestCount += 1;
            state.isLoading = true; // Show loader when there's at least one pending request
        },
        globalLoaderEnd(state) {
            if (state.apiRequestCount > 0) {
                state.apiRequestCount -= 1;
            }
            // Hide loader when there are no pending requests
            if (state.apiRequestCount === 0) {
                state.isLoading = false;
            }
        },
        resetApiRequestCount(state) {
            state.apiRequestCount = 0;
            state.isLoading = false; // Reset and hide the loader
        }
    }
});

export const { globalLoaderStart, globalLoaderEnd, resetApiRequestCount } = globalLoaderSlice.actions;
export default globalLoaderSlice.reducer;
