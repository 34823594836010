import { createSlice } from "@reduxjs/toolkit";
import { IDeeplinkData, IDeeplinkResponse } from "../../utils/types";

interface InitialStateType {
  appointmentEventData: IDeeplinkResponse | null;
  isAppointmentEventAPILoading: boolean;
}
const initialState: InitialStateType = {
  appointmentEventData: null,
  isAppointmentEventAPILoading: false,
};
const appointmentEventResponseSlice = createSlice({
  name: "appointment-event-response",
  initialState,
  reducers: {
    setAppointmentEventResponse(state, action) {
      return { ...state, ...action.payload };
    },
    resetAppointmentEventResponse(state) {
      state = initialState;
    },
  },
});

export const { setAppointmentEventResponse, resetAppointmentEventResponse } =
  appointmentEventResponseSlice.actions;
export default appointmentEventResponseSlice.reducer;
