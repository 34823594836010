
import { createSlice } from '@reduxjs/toolkit';

export interface ICity {
    id: string;
    name: string;
    country: string;
    country_id: string;
    country_code: string;
}

type InitialStateType = ICity[];

const initialState: InitialStateType = [];

const specializationListSlice = createSlice({
    name: 'cityList',
    initialState,
    reducers: {
        setCityList(state, action) {
            return action.payload; // Set the entire state to the new array of cities
        },
        resetCityList() {
            return initialState; // Reset the state to the initial empty array
        }
    }
});

export const { setCityList, resetCityList } = specializationListSlice.actions;
export default specializationListSlice.reducer;

