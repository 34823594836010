import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import images from "../../utils/images";
import Cookies from "js-cookie";
import LogoutModal from "../../pages/Login-SignUp/logoutModal";
import END_POINTS from "../../utils/api/endpoints";
import API_CONSTANTS from "../../utils/api/api.configuration";
import {
  getImage,
  getStagingImage,
  getUserStatus,
  getUserToken,
} from "../../utils/helperfunction";
import { showAlert } from "../../utils/alert";
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import { Menu as HamburgerIcon } from "lucide-react";
import { getPatientHomeData } from "../../pages/Home/action";
import { RootState } from "../../redux/store/store";

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logindata = useAppSelector((state) => state.auth);
  const user_name = logindata?.user_name || Cookies.get("user_name");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const profileImage = Cookies.get("profile");

  const { patientHomeData } = useAppSelector(
    (state: RootState) => state.patientHome
  );

  const handleGetStarted = () => {
    navigate("/login");
  };

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const response = await API_CONSTANTS.AUTHENTICATION_AXIOS.delete(
        END_POINTS.COMMON,
        {
          params: { user_click: 1 },
          headers: { "liviaapp-token": getUserToken() },
        }
      );
      if (response.status === 200) {
        Cookies.remove("user_name");
        Cookies.remove("userData");
        navigate("/");
      }
    } catch (error: any) {
      const { response, message } = error;
      showAlert(2, response?.data?.messages[0]);
      console.log("errorerror", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    // Dispatch action when header dependencies change
    dispatch(getPatientHomeData());
  }, [scrolled, dispatch]);

  console.log("patientHomeData", patientHomeData);

  return (
    <div
      className={`py-5 sticky top-0 w-full transition-all duration-300 z-[99999] ${
        scrolled
          ? patientHomeData?.is_provider
            ? ""
            : "bg-seaGreen"
          : "bg-background"
      }`}
      style={{
        backgroundColor:
          scrolled && patientHomeData?.is_provider
            ? patientHomeData?.body_data?.header_bg_color || "transparent"
            : undefined,
      }}
    >
      <div className="container-box flex items-center justify-between">
        <img
          src={scrolled ? images.LOGOLIGHT : images.LOGODARK}
          alt="logo"
          className="cursor-pointer pl-2 md:pl:0 h-[42px]"
          onClick={() => navigate("/")}
        />

        <div className="pr-2 md:pr-0 md:hidden flex items-center">
          {/* Hamburger Icon */}
          <button onClick={toggleMenu}>
            <HamburgerIcon className={`text-${scrolled ? "white" : "black"}`} />
          </button>
        </div>

        <div className="hidden md:flex items-center gap-6 w-full justify-end">
          <ul className="flex items-center gap-6 w-full justify-end">
            {[
              "/",
              "/consult-doctor",
              "/specialities",
              ...(getUserStatus() === "1" ? ["/my-appointments"] : []),
              "/my-orders",
              "/about-us",
            ].map((path, index) => (
              <li key={index}>
                {/* <NavLink
                  to={path}
                  className={({ isActive }) =>
                    `text-[14px] p-0.5 border-b-2 font-semibold ${scrolled
                      ? "hover:border-white hover:text-red"
                      : "hover:border-seaGreen hover:text-seaGreen"
                    } ${isActive
                      ? `border-b-2 ${scrolled
                        ? `${!patientHomeData?.is_provider ? `text-[${patientHomeData?.body_data?.header_txt_color}] border-[${patientHomeData?.body_data?.header_txt_color}]` : "text-white border-white"} `
                        : "text-seaGreen border-seaGreen"
                      }`
                      : `border-transparent ${scrolled ? "text-white" : "text-[#07003B]"
                      }`
                    }`
                  }
                > */}
                <NavLink
                  to={path}
                  className={({ isActive }) => {
                    const baseClass =
                      "text-[14px] p-0.5 border-b-2 font-semibold";
                    const hoverClass = scrolled
                      ? "hover:border-white hover:text-red"
                      : "hover:border-seaGreen hover:text-seaGreen";

                    const activeClass = isActive
                      ? `border-b-2 ${
                          scrolled
                            ? patientHomeData?.is_provider
                              ? "" // Leave empty to use inline styles for dynamic color
                              : "text-white border-white"
                            : "text-seaGreen border-seaGreen"
                        }`
                      : `border-transparent ${
                          scrolled ? "text-white" : "text-[#07003B]"
                        }`;

                    return `${baseClass} ${hoverClass} ${activeClass}`;
                  }}
                  style={({ isActive }) =>
                    // Only apply inline styles when `scrolled` is true, `isActive` is true, and `is_provider` is false
                    isActive && scrolled && patientHomeData?.is_provider
                      ? {
                          color: patientHomeData?.body_data?.header_txt_color,
                          borderColor:
                            patientHomeData?.body_data?.header_txt_color,
                        }
                      : {}
                  }
                >
                  {path === "/"
                    ? "Home"
                    : path
                        .split("/")[1]
                        .replace("-", " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                </NavLink>
              </li>
            ))}
            <li>
              {getUserStatus() !== "1" ? (
                // <button
                //   className={`rounded-lg px-6 font-semibold text-base h-[42px] ${scrolled
                //     ? "bg-white text-seaGreen"
                //     : "bg-seaGreen text-white"
                //     }`}
                //   onClick={handleGetStarted}
                // >
                //   Get Started
                // </button>
                <button
                  style={{
                    backgroundColor: patientHomeData?.is_provider
                      ? patientHomeData.body_data.btn_bg_color
                      : scrolled
                      ? "white"
                      : "#23901E",
                    color: patientHomeData?.is_provider
                      ? patientHomeData.body_data.btn_txt_color
                      : scrolled
                      ? "#23901E"
                      : "white",
                  }}
                  className="rounded-lg px-6 font-semibold text-base h-[42px]"
                  onClick={handleGetStarted}
                >
                  Get Started
                </button>
              ) : (
                <div className="relative min-w-[151px] cursor-pointer">
                  <div
                    className="flex items-center gap-1"
                    onClick={toggleDropdown}
                  >
                    <img
                      src={getStagingImage(
                        Cookies.get("profile"),
                        images.PLACEHOLDER
                      )}
                      alt="user-profile"
                      className="w-[34px] h-[34px] rounded-full border border-white p-0.5"
                    />
                    <div
                      className={`text-sm ${
                        scrolled ? "text-white" : "text-black"
                      }`}
                    >
                      <p className="font-normal ">Hello,</p>
                      <p className="font-bold">{user_name}</p>
                    </div>
                    <img
                      src={
                        scrolled ? images.DROPDOWNWHITE : images.DROPDOWNBLACK
                      }
                      alt="dropdown"
                      className="w-5 rotate-180"
                    />
                  </div>

                  {dropdownVisible && (
                    <div className="bg-white shadow-[0px_4px_24px_0px_#0000001A] p-4 rounded-lg absolute top-[50px]">
                      <ul className="text-base font-semibold text-black flex flex-col gap-4">
                        <li
                          className="border-b-[0.5px] border-[#C8D6E0] pb-4 flex items-center gap-3 cursor-pointer"
                          onClick={() => navigate("/profile")}
                        >
                          <img src={images.MYPROFILE} alt="my-profile" /> My
                          Profile
                        </li>
                        <li
                          className="flex items-center gap-3 cursor-pointer"
                          onClick={handleLogout}
                        >
                          <img src={images.LOGOUTICON} alt="logout" /> Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </li>
          </ul>
          <LogoutModal ModalOpen={isModalOpen} setModalOpen={setIsModalOpen} />
        </div>
      </div>

      {/* Responsive Sliding Drawer Menu */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleMenu}
      ></div>
      <div
        className={`fixed top-0 right-0 bg-white w-[20rem] h-full shadow-lg transform transition-transform duration-300 ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {getUserStatus() !== "1" ? (
          // <button
          //   className="rounded-lg px-4 py-2 font-semibold text-base bg-seaGreen text-white"
          //   onClick={handleGetStarted}
          // >
          //   Get Started
          <div className="bg-seaGreen py-4 px-6">
            <div className="flex items-end justify-between">
              <div className="flex flex-col gap-2">
                <p className="text-base font-bold text-white">Your Profile</p>
                <p className="text-xs font-medium text-white">
                  Log in or Sign up to complete your profile
                </p>
              </div>
              <button
                style={{
                  backgroundColor: !patientHomeData?.is_provider
                    ? patientHomeData?.body_data?.btn_bg_color
                    : "#23901E",
                  color: !patientHomeData?.is_provider
                    ? patientHomeData?.body_data.btn_txt_color
                    : "white",
                }}
                className="rounded px-4 py-2 font-semibold text-sm whitespace-nowrap h-[35px]"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>
          </div>
        ) : (
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={toggleDropdown}
          >
            <img
              src={images.PLACEHOLDER}
              alt="user-profile"
              className="w-[34px] h-[34px] rounded-full border border-white p-0.5"
            />
            <div className="text-sm">
              <p className="font-normal ">Hello,</p>
              <p className="font-bold">{user_name}</p>
            </div>
          </div>
        )}

        <ul className="flex flex-col gap-4 p-4">
          {[
            "/",
            "/consult-doctor",
            "/specialities",
            ...(getUserStatus() === "1" ? ["/my-appointments"] : []),
            "/my-orders",
            "/about-us",
          ].map((path, index) => (
            <li key={index}>
              <NavLink
                to={path}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                className={({ isActive }) =>
                  `text-[14px] p-2 font-semibold ${
                    isActive ? "text-seaGreen" : "text-black"
                  }`
                }
              >
                {path === "/"
                  ? "Home"
                  : path
                      .split("/")[1]
                      .replace("-", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
