import { setPatientHomeData } from "../../redux/slices/patientHomeSlice";
import { setUserRating } from "../../redux/slices/userRatingSlice";
import { AppDispatch } from "../../redux/store/store"
import { showAlert } from "../../utils/alert";
import API_CONSTANTS from "../../utils/api/api.configuration";
import END_POINTS from "../../utils/api/endpoints";
import { getUserId } from "../../utils/helperfunction";



// export const getPatientHomeData = () => {
//     return (dispatch: AppDispatch) => {
//         const user_id = getUserId()
//         dispatch(setPatientHomeData({ isLoading: true }))
//         API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
//             params: { ...(user_id ? { user_id } : {}) }, headers: {
//                 "LiviaApp-Url": "gothospital.liviaapp.net",
//                 // "LiviaApp-Domain":"https://patientteleconsultation-uat.liviaapp.net/"
//                 "LiviaApp-Domain": "test1"
//             }
//         })
//             .then((res) => {
//                 dispatch(setPatientHomeData({ patientHomeData: res?.data?.body, isLoading: false }))
//             })
//             .catch((error) => {
//                 const { message } = error;
//                 dispatch(setPatientHomeData({ isLoading: false }))
//                 showAlert(2, message)

//             });
//     };
// };


console.log("window.location.hostname", window.location.hostname)
const headers = {
    ...(window.location.hostname !== "patientteleconsultation-uat.liviaapp.net" && {
        "LiviaApp-Domain": window.location.hostname === "localhost" ? "test" : window.location.hostname
    })
};

export const getPatientHomeData = () => {
    return (dispatch: AppDispatch) => {
        const user_id = getUserId();
        dispatch(setPatientHomeData({ isLoading: true }));

        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.PATIENT_HOME, {
            params: { ...(user_id ? { user_id } : {}) },
            // headers: {
            //     // "LiviaApp-Url": "gothospital.liviaapp.net",
            //     "LiviaApp-Domain": window.location.hostname == "localhost" ? "test" : window.location.hostname,
            // }
            // headers: headers

        })
            .then((res) => {
                dispatch(setPatientHomeData({ patientHomeData: res?.data?.body, isLoading: false }));

                // const currentUrl = new URL(window.location.href);
                // currentUrl.searchParams.set("p", "2");
                // window.history.replaceState(null, "", currentUrl.toString());

                // if (window.location.hostname !== "patientteleconsultation-uat.liviaapp.net") {
                //     const currentUrl = new URL(window.location.href);
                //     currentUrl.searchParams.set("p", "2");
                //     window.history.replaceState(null, "", currentUrl.toString());
                // }

            })
            .catch((error) => {
                const { response, message } = error;
                dispatch(setPatientHomeData({ isLoading: false }));


                if (response && response.status === 400) {
                    window.location.href = "/invalid-domain";
                } else {
                    showAlert(2, message);
                }
            });
    };
};

export const getUserRatingData = () => {
    return (dispatch: AppDispatch) => {
        dispatch(setUserRating({ isUserRatingLoading: true }))
        API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.USER_RATING, {
            params: {
                limit: 10,
                offset: 0,
                fromPatientPortal: 1
            }
        })
            .then((res) => {
                dispatch(setUserRating({ userRatingList: res?.data?.body, isUserRatingLoading: false }))
            })
            .catch((error) => {
                const { message } = error;
                dispatch(setUserRating({ userRatingList: [], isUserRatingLoading: false }))
                showAlert(2, message)

            });
    };
};