import { createSlice } from "@reduxjs/toolkit";
import {
  IHospitalAppointment,
  IPatientInsurance,
  InsuranceMemberDependent,
} from "../../utils/types";

type IScheduleAppointment = {
  isConnectNow:boolean
  appointmentType: string | null;
  openChooseAppointmentTypeDialog: boolean;
  showPaymentTypeDialog: boolean;
  openGuestSignupDialog:boolean;
  pay_type: string | null;
  doctor_id: string | null;
  doctor_fees: null;
  is_online: number;
  openDependentDialog: boolean;
  activePolicySelected: IPatientInsurance | null;
  openAppointmentSlotDialog: boolean;
  selectedSlotData: IHospitalAppointment | null;
  selectedBeneficiary: InsuranceMemberDependent | null;
};

const initialState: IScheduleAppointment = {
  isConnectNow:false,
  appointmentType: null,
  openChooseAppointmentTypeDialog: false,
  showPaymentTypeDialog: false,
  openGuestSignupDialog:false,
  pay_type: null,
  doctor_id: null,
  doctor_fees: null,
  is_online: 0,
  openDependentDialog: false,
  activePolicySelected: null,
  openAppointmentSlotDialog: false,
  selectedSlotData: null,
  selectedBeneficiary: null,
};

const scheduleAppointmentSlice = createSlice({
  name: "scheduleAppointment",
  initialState,
  reducers: {
    setScheduleAppointmentDetail(state, action) {
      return { ...state, ...action.payload };
    },
    resetScheduleAppointmentDetail(state) {
      return { ...initialState };
    },
  },
});

export const { setScheduleAppointmentDetail, resetScheduleAppointmentDetail } =
  scheduleAppointmentSlice.actions;
export default scheduleAppointmentSlice.reducer;
