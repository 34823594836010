/* eslint no-use-before-define: 0 */ // --> OFF
// import { api } from "../../api/apiRequest"
// import { httpEndpoints, endpoints } from "../../utils/endpoints";
import axios from "axios";
import END_POINTS from "../../utils/api/endpoints";
import { WebsiteAuthHeaders } from "../../utils/helperfunction";
export const httpEndpoints = {
	GET: "get",
	POST: "post",
	PUT: "put",
	PATCH: "patch",
	DELETE: "delete",
};

// --------------------- Generic API CALL -----------------------
function axiosApi(apiParameter) {
  alert(process.env.REACT_APP_API_BASE_URL)
  return axios(apiParameter).then(handleResponse);
}
function handleResponse(response) {
  return response;
}

export const api = (
  endpoint,
  apiMethod,
  data,
  queryParams,
  authRequired = true
) => {
  const apiParameter = {
    method: apiMethod,
    url: process.env.REACT_APP_API_BASE_URL + endpoint,
    // withCredentials: true,
    timeout: 1000 * 120, // Wait for 120 seconds
  };

  // get refreshToken and accessToken in session storage, inorder to prevent sideffect on page reload
  const accessToken = "dsadsds";
  // append headers if required
  if (accessToken) {
    // ---------- headers ----------
    if (authRequired) {
      const headers = {
        "Content-Type": "application/json",
        "LiviaApp-language": "en",
        // 'LiviaApp-Url': window.location.origin
        // "LiviaApp-Url": getAppUrlInLS(),
      };
      apiParameter.headers = headers;
    }
  }

  /**
   * Conditinal check for sending data/queryparams in apiParameter
   */
  if (data !== null) {
    apiParameter.data = data;
  }
  if (queryParams !== undefined) {
    apiParameter.params = queryParams;
  }

  return axiosApi(apiParameter);
};

export default class MeetingService {
  getMeeting = async (eventId) => {
    try {
      const response = await api(`${END_POINTS.EVENT}`, httpEndpoints.PATCH, {
        event_id: eventId,
        notification: 1,
      });
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  getMeetingWithNotification = async (eventId) => {
    try {
      const response = await api(`${END_POINTS.EVENT}`, httpEndpoints.PATCH, {
        event_id: eventId,
      });
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  updateMeetingDuration = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  cancelMeeting = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  callRejected = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };
  callAccepted = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  meetingJoined = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };

  saveDoctorNotes = async (payload) => {
    try {
      const response = await api(
        `${END_POINTS.EVENT}`,
        httpEndpoints.PATCH,
        payload
      );
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };
}

const WebsiteauthHeaders = (headers) => ({
  ...headers,
});
export class WebsiteMeetingService {
  getMeeting = async (eventId) => {
    return axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}`,
      {
        event_id: eventId,
        notification: 1,
      },
      {
        headers: WebsiteauthHeaders(),
      }
    );
  };

  getMeetingWithNotification = async (eventId) => {
    return axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}`,
      {
        event_id: eventId,
        meeting_notification:1
      },
      {
        headers: WebsiteauthHeaders(),
      }
    );
  };

  meetingJoined = async (payload) => {
    return axios.patch(`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}`, payload, {
      headers: WebsiteAuthHeaders(Headers),
    });
  };

  websiteCallRejected = async (payload) => {
    return axios.patch(`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}`, payload, {
      headers: WebsiteauthHeaders(),
    });
  };

  checkDoctorBusy = async (eventId) => {
    return axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}`,
      {
        check_doctor_on_call: 1,
        event_id: eventId,
      },
      {
        headers: WebsiteauthHeaders(),
      }
    );
  };
}



export  class WebsiteHomeService {
	authHeaders = (headers) => ({
		...headers,
		"LiviaApp-language": "en",
		// "LiviaApp-Url": "Vinayak.liviaapp.com",
		// "LiviaApp-Url":window.location.host
	
	});

	headerWithAuthToken = (headers) => ({
		...headers,
		"LiviaApp-Token": localStorage.getItem("website_access_token"),
		"LiviaApp-country": localStorage.getItem("website_country_code") ?? 'Ke',
		"LiviaApp-language": "en",
		"LiviaApp-city":  "186301", // as per discussion with sudhir
		// 'LiviaApp-Url': window.location.host,
		
	});

	getHomeInfo = async () => {
		return axios.get(`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}/1`, {
			headers: ({...this.authHeaders(), "LiviaApp-Token": "",}),
		});
	};

	getDoctorList = async (offset, limit, search, specialization, location) => {
		return axios.get(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}?offset=${offset}&limit=${limit}&is_doctor_list=1&search=${search}&specialization_id=${specialization}`,
			{
				headers: this.authHeaders(),
			}
		);
	};

	getSpecialityList = async (limit, offset, search) => {
		return axios.get(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}?offset=${offset}&limit=${limit}&is_specialization_list=1&search=${search}`,
			{
				headers: this.authHeaders(),
			}
		);
	};

	getMyClaimList = async (offset, limit, status, search) => {
		return axios.post(
			`${process.env.REACT_APP_API_BASE_URL}/event?offset=${offset}&limit=${limit}&filter[statuses]=${status}&search=${search}`,
			{
				get_possible: 0,
				sort_type: "3",
			},
			{
				headers: this.authHeaders(),
			}
		);
	};

	getMyAppointmetsList = async (limit, offset, status, payload) => {
		return axios.post(
			`${process.env.REACT_APP_API_BASE_URL}/hospital-event?offset=${offset}&limit=${limit}&filter[statuses]=${status}`,
			{
				...payload,
				get_possible: 0,
				sort_type: "3",
				user_id: 123,
			},
			{
				headers: this.headerWithAuthToken(),
			}
		);
	};

	getMyProfile = async (payload) => {
		return axios.post(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
			headers: this.authHeaders(),
		});
	};

	getAbout = async () => {
		return axios.get(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}?is_about_us=1`,
			{
				headers: this.authHeaders(),
			}
		);
	};

	getFaq = async (limit, offset, search) => {
		return axios.get(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}?limit=${limit}&offset=${offset}&search=${search}&is_faq_list=1`,
			{
				headers: this.authHeaders(),
			}
		);
	};

	getMyAppointmetsDetail = async (id) => {
		return axios.get(`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.EVENT}/${id}`, {
			headers: this.headerWithAuthToken(),
		});
	};

	getMyClaimDetail = async (payload) => {
		return axios.post(`${process.env.REACT_APP_API_BASE_URL}/about`, {
			headers: this.authHeaders(),
		});
	};

	getMySpecialization = async () => {
		return axios.get(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}?specialization_dropdown_data=1`,
			{
				headers: this.authHeaders(),
			}
		);
	};

	contactUs = async (payload) => {
		return axios.patch(
			`${process.env.REACT_APP_API_BASE_URL}${END_POINTS.HOSPITAL_HOME}`,
			payload,
			{
				headers: this.authHeaders(),
			}
		);
	};
}
