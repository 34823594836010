import { createSlice } from "@reduxjs/toolkit";
import { IAppointmentDetail} from "../../utils/types";

interface InitialStateType {
  appointmentDetail: IAppointmentDetail | null;
  isAppointmentDetailLoading: boolean;
}
const initialState: InitialStateType = {
    appointmentDetail: null,
    isAppointmentDetailLoading: false,
};
const appointmentDetailResponseSlice = createSlice({
  name: "appointment-detail-response",
  initialState,
  reducers: {
    setAppointmentDetail(state, action) {
      return { ...state, ...action.payload };
    },
    resetAppointmentDetail(state) {
      state = initialState;
    },
  },
});

export const { setAppointmentDetail, resetAppointmentDetail } =
  appointmentDetailResponseSlice.actions;
export default appointmentDetailResponseSlice.reducer;
