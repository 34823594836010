import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAppDispatch } from "./redux/store/hooks";
import { getPatientHomeData } from "./pages/Home/action";
// import SignUp from './pages/Login-SignUp/signUp';
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { getInsuranceList, getSpecializationList } from "./actions";
import Chime from "./components/MeetingChime";
import { SocketProvider } from "./components/MeetingChime/socketContext";
import SignUp from "./pages/Login-SignUp/signUp";
import Specialities from "./pages/Specialities";
import { LoaderCircle } from "lucide-react";
import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./components/Route/PrivateRoute";
import InvalidDomain from "./components/InvalidDomain";
// import Chime from "./pages/Chime";
// import Chime from "./pages/Chime";
// import Chime from "./pages/Chime/chime";
// import Chime from "./pages/Chime.jsx";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login-SignUp"));
const ConsultDoctor = lazy(() => import("./pages/Consult_Doctor"));
const MyAppointments = lazy(() => import("./pages/My_Appoinments"));
const MyOrders = lazy(() => import("./pages/My_Orders"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Profile = lazy(() => import("./pages/Profile"));
const DoctorDetails = lazy(
  () => import("./pages/Consult_Doctor/doctorDetails")
);
const AppointmentDetails = lazy(
  () => import("./pages/My_Appoinments/appointmentDetails")
);

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {


    // if (window.location.origin !== "https://patientteleconsultation-uat.liviaapp.net/") {
    //   // Append the query parameter 'p=2' if the origin is different
    //   const currentUrl = new URL(window.location.href);
    //   currentUrl.searchParams.set("p", "2");
    //   window.history.replaceState(null, "", currentUrl.toString());
    // }
    // dispatch(getPatientHomeData());
    dispatch(getInsuranceList());
    dispatch(getSpecializationList());
  }, []);

  return (
    <Router>
      <SocketProvider>
        <Suspense 
        // fallback={<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-300 bg-opacity-75 z-50">
        //   <div className="loader-container">
        //     <LoaderCircle className="animate-spin h-20 w-20" color="#9EE5CA" />
        //   </div>
        // </div>}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp type={""} />} />
            <Route path="/consult-doctor" element={<ConsultDoctor />} />
            <Route path="/consult-doctor/spec/:id" element={<ConsultDoctor />} />
            <Route path="/doctor/profile/:id" element={<DoctorDetails />} />
            <Route path="/specialities" element={<Specialities />} />
            {/* <Route path="/my-appointments" element={<MyAppointments />} /> */}
            {/* <Route
            path="/my-appointments/detail/:id"
            element={<AppointmentDetails />}
          /> */}
            <Route
              path="/my-appointments"
              element={<PrivateRoute element={<MyAppointments />} />}
            />
            <Route
              path="/my-appointments/detail/:id"
              element={<PrivateRoute element={<AppointmentDetails />} />}
            />
            {/* <Route path="/my-appointments/detail/meeting-chime/:id/meeting" element={<Chime />} /> */}
            <Route
              path="/my-appointments/detail/meeting-chime/:id/meeting"
              element={<PrivateRoute element={<Chime />} />}
            />
            <Route path="/my-orders" element={<MyOrders />} />
            <Route path="/about-us" element={<AboutUs />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route
              path="/profile"
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route path="/invalid-domain" element={<InvalidDomain />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </SocketProvider>
    </Router>
  );
};

export default App;
