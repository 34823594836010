import { createSlice } from '@reduxjs/toolkit';

interface InitialStateType {
    clinics_list: any;
    reviews_rating: any;
    id: string;
    email: string;
    doctor_phone: string;
    avatar: string;
    user_status: string;
    description: string;
    reception_phone_numbers: string[];
    phone: string;
    qualification: string;
    available_now: string;
    profile_views: string;
    avg_professionalism_rating: string;
    avg_punctuality_rating: string;
    avg_appearance_rating: string;
    experience_years: string;
    is_favourite: string;
    name: string;
    online_consultation_fees: string;
    consultation_fees: string;
    hospital_id: number;
    doctors_photos: string[];
    specializations: {
        id: string;
        name: string;
        address: string;
        avatar: string;
    }[];
    insurance_companies: {
        id: string;
        name: string;
        avatar: string;
        fees: string;
    }[];
    hospital_photos: string[];
};

const initialState: { doctorDetailsData: InitialStateType, isLoading: boolean } = {
    doctorDetailsData: {
        id: "",
        email: "",
        doctor_phone: "",
        avatar: "",
        user_status: "",
        description: "",
        reception_phone_numbers: [],
        phone: "",
        qualification: "",
        available_now: "",
        profile_views: "",
        avg_professionalism_rating: "",
        avg_punctuality_rating: "",
        avg_appearance_rating: "",
        experience_years: "",
        is_favourite: "",
        name: "",
        online_consultation_fees: "",
        consultation_fees: "",
        hospital_id: 0,
        doctors_photos: [],
        specializations: [],
        insurance_companies: [],
        hospital_photos: [],
        reviews_rating:[],
        clinics_list:[]
    },
    isLoading: false
};

const doctorDetailsSlice = createSlice({
    name: 'doctor-details',
    initialState,
    reducers: {
        setDoctorDetails(state, action) {
            return { ...state, ...action.payload };
        },
        resetDoctorDetails(state) {
            state = initialState;
        }
    }
});

export const { setDoctorDetails, resetDoctorDetails } = doctorDetailsSlice.actions;
export default doctorDetailsSlice.reducer;
