import AsyncSelect from 'react-select/async';
import { ICity } from '../../redux/slices/citySlice';
import END_POINTS from '../../utils/api/endpoints';
import API_CONSTANTS from '../../utils/api/api.configuration';
import { components } from 'react-select';
import DropdownArrow from "../../assets/icons/dropdown-arrow.svg";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropdownArrow} alt="dropdown" loading="eager" />
    </components.DropdownIndicator>
  );
};

const fetchCityFromAPI = async (inputValue: string) => {
  const response = await API_CONSTANTS.PATIENT_API_AXIOS.get(END_POINTS.CITY, {
    params: {
      limit: 100,
      country_code: "ke",
      ...(inputValue ? { search: inputValue } : {}),
    },
  });
  const responseCity: ICity[] = response?.data?.body;
  return responseCity.map((item: any) => ({
    label: item.name,
    value: item.id,
    ...item,
  }));
};

type IProp = {
  handleCityChange: (value: any) => void;
  value?: any;
  className?: any;
  customStyles?: any
  isDisabled?:boolean
};
function CityAutocomplete({ handleCityChange, value, className, customStyles,isDisabled=false }: IProp) {
  const promiseOptions = (inputValue: string) => {
    return fetchCityFromAPI(inputValue);
  }
  return (
    <AsyncSelect loadOptions={promiseOptions} placeholder='Search City' defaultOptions isClearable onChange={handleCityChange} value={value} components={{ DropdownIndicator }} className={className} styles={customStyles} isDisabled={isDisabled}
    />
  )
}

export default CityAutocomplete;
