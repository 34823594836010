import { createSlice } from '@reduxjs/toolkit';
import { ICountry, IDoctor, IInsurance, Specialization } from '../../utils/types';


interface InitialStateType {
    type: number;
    branches: any[];
    hospital: any[];
    doctors: IDoctor[];
    insurance_compnanies: IInsurance[];
    countries_list:ICountry[]
    banner_data: any;
    contact_us_emails: any[];
    about_us_texts: string;
    body_data: any;
    specialization_data:Specialization[];
    count_events_pending_action:number;
    total_records:number
}
const initialState:InitialStateType = {
   type:1,
    branches: [], 
    hospital: [],
    doctors:[],
    insurance_compnanies:[],
    countries_list:[],
    banner_data:{},
    contact_us_emails:[],
    about_us_texts:'',
    body_data:{},
    specialization_data:[],
    count_events_pending_action:0,
    total_records:0
    
};

const specializationListSlice = createSlice({
    name: 'countryList',
    initialState,
    reducers: {
        setCountryList(state, action) {
            return { ...state, ...action.payload };
        },
        resetCountryList(state) {
            state = initialState;
        }
    }
});

export const { setCountryList, resetCountryList } = specializationListSlice.actions;
export default specializationListSlice.reducer;
