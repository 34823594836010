import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogClose } from "../../ui/dialog";
import { X } from "lucide-react";
import { getPatientHomeData } from "../Home/action";
import { useAppDispatch } from "../../redux/store/hooks";
import images from "../../utils/images";

type PropTypes = {
  ModalOpen: boolean;
  setModalOpen: any;
};

function SignUpModal({ ModalOpen, setModalOpen }: PropTypes) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setModalOpen(false);
    dispatch(getPatientHomeData());
    navigate("/");
  };

  return (
    <Dialog open={ModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="top-[45%] bg-white max-w-[500px] w-[100%] py-14 px-8 rounded-3xl"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogClose asChild>
          <button
            onClick={handleClose}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
            aria-label="Close"
          >
            <X className="w-6 h-6" />
          </button>
        </DialogClose>

        <div className="text-center text-navy ">
          <img
            src={images.CONGRATULATIONS}
            alt="congratulations"
            className="mx-auto"
          />
          <h2 className="text-[26px] font-bold mt-6 mb-4">Congratulations !</h2>
          <p className="text-base font-medium">
            You've successfully Signed Up to Safaricom—your health journey
            begins here
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SignUpModal;
