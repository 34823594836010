import { setAppoinmentList } from "../../redux/slices/appointmentListSlice";
import { setAppointmentDetail } from "../../redux/slices/appointmentDetailSlice";
import { setChimeMeetingData } from "../../redux/slices/chimeMeetingSlice";
import { AppDispatch } from "../../redux/store/store";
import { showAlert } from "../../utils/alert";
import API_CONSTANTS from "../../utils/api/api.configuration";
import END_POINTS from "../../utils/api/endpoints";
import { getUserId, WebsiteAuthHeaders } from "../../utils/helperfunction";
import { globalLoaderEnd, globalLoaderStart } from "../../redux/slices/globalLoaderSlice";

export const getAppoinmentList = (offset: number, limit: number, filter = "", isSetTotalRecords = false) => {
  return (dispatch: AppDispatch) => {
    const user_id = getUserId();
    dispatch(setAppoinmentList({ appoimntentList: {}, isLoading: true }));

    API_CONSTANTS.PATIENT_API_AXIOS.post(
      // `${END_POINTS.APPOINMENT_LISTING}?offset=${offset}&limit=${limit}&filter[statuses]=`,
      `${END_POINTS.APPOINMENT_LISTING}?offset=${offset}&limit=${limit}&from_patient_portal=1${filter ? `&filter[statuses]=${filter}` : ''}`,
      {
        user_id,
        get_possible: 0,
        sort_type: "3",
        // specialization_id: specialization,
      },
      {
        headers: {
          ...WebsiteAuthHeaders(Headers), "LiviaApp-Url":
            "gothospital.liviaapp.net",
        }
      },
    )
      .then((res) => {
        dispatch(setAppoinmentList({ appoimentListData: res?.data?.body, isLoading: false }));
        if (isSetTotalRecords) {
          dispatch(setAppoinmentList({ totalRecords: res?.data?.count }));
        }
      })
      .catch((error) => {
        const { response, message } = error;
        dispatch(setAppoinmentList({ appoimentListData: {}, isLoading: false }));
        showAlert(2, message);
      });
  };
};

export function getAppointmentDetail(appointmentId: string) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(globalLoaderStart());
      dispatch(setAppointmentDetail({
        appointmentDetail: null,
        isAppointmentDetailLoading: true,
      }))
      API_CONSTANTS.PATIENT_API_AXIOS.get(`${END_POINTS.EVENT}/${appointmentId}`, {
        headers: WebsiteAuthHeaders(Headers),
      })
        .then((res) => {
          dispatch(setAppointmentDetail({
            appointmentDetail: res?.data?.body,
            isAppointmentDetailLoading: false,
          }))
          dispatch(globalLoaderEnd());
          resolve(res?.data?.body);
        })
        .catch((error) => {
          const { response, message } = error;
          console.log(response)
          showAlert(2, response?.data?.message[0]);
          dispatch(globalLoaderEnd());
          dispatch(setAppointmentDetail({
            appointmentDetail: null,
            isAppointmentDetailLoading: false,
          }))
          resolve(null)
        });
    });
  };
}

export function getWebsiteMeetingAction(eventId: string, navigate: any) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        setChimeMeetingData({
          chimeMeetingResponse: null,
          isMeetingDataLoading: true,
        })
      );
      API_CONSTANTS.PATIENT_API_AXIOS.patch(END_POINTS.EVENT,
        {
          event_id: eventId,
          notification: 1,
        },
        {
          headers: WebsiteAuthHeaders(Headers),
        })
        .then((res) => {
          dispatch(
            setChimeMeetingData({
              isMeetingDataLoading: false,
            })
          );
          if (res?.data?.is_time_valid != 1) {
            navigate(`/my-appointments/detail/${eventId}`);
            showAlert(2, res?.data?.msg)
            return

          }
          dispatch(
            setChimeMeetingData({
              chimeMeetingResponse: res?.data,
            })
          );
          if (window.location.href.indexOf("?join=true") == -1) {
            navigate(`/my-appointments/detail/meeting-chime/${eventId}/meeting`)

            // window.location.href = ;
          } else {
            navigate(`/my-appointments/detail/meeting-chime/${eventId}/meeting?join=true`)
          }
          ;
          resolve(res?.data?.body);
        })
        .catch((error) => {
          const { response, message } = error;
          showAlert(2, response?.data?.message[0]);
          dispatch(
            setChimeMeetingData({
              chimeMeetingResponse: null,
              isMeetingDataLoading: false,
            })
          );
          navigate(`/my-appointments/detail/${eventId}`);
          resolve(null)
        });
    });
  };
}

export function addReview(payload:any, handleClose:()=>void) {
  return (dispatch: AppDispatch) => {
    return new Promise(async (resolve, reject) => {
      API_CONSTANTS.PATIENT_API_AXIOS.post(END_POINTS.USER_RATING, payload, {
        headers: WebsiteAuthHeaders(Headers),
      })
        .then((res) => {
          handleClose()
          showAlert(1, "Review submitted for the doctor.")
          resolve(res)
          
        })
        .catch((error) => {
          const { response, message } = error;
          console.log(response)
          showAlert(2, response?.data?.messages[0]);
          resolve(null)
        });
      })
    
  };
}