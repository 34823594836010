import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '../../ui/dialog'
import images from '../../utils/images'
import StarRatings from 'react-star-ratings';
import { useAppDispatch } from '../../redux/store/hooks';
import { addReview } from './action';
import { Icons } from '../../utils/icons';

interface IProps {
    openDialog:boolean;
    handleClose:()=> void;
    doctor_id:number;
    event_id:number;
    handleOk?:()=>void;

}

function ReviewDoctorDialog({openDialog, handleClose,doctor_id,event_id,handleOk}:IProps) {
    const [professionalismRating, setProfessionalismRating] = useState(0)
    const [punctualityRating, setPunctualityRating] = useState(0)
    const [appearanceRating, setAppearanceRating] = useState(0)
    const [reviewDescription, setReviewDescriptionRating] = useState("");
    const [isSubmitReviewLoading, setSubmitReviewLoading] = useState(false);
    const dispatch = useAppDispatch();

    const submitReview = ()=> {
      const payload={professionalism_rating:professionalismRating,punctuality_rating:punctualityRating,appearance_rating:appearanceRating,phone_code:"254",doctor_id:doctor_id,event_id:event_id,os_type:3,comment:reviewDescription,anonymous:1}
      setSubmitReviewLoading(true);
      dispatch(addReview(payload, handleClose)).then((res)=>{
        setSubmitReviewLoading(false);
        handleOk && handleOk();
      }).catch(error=> setSubmitReviewLoading(false))
    }
    useEffect(()=>{if(!openDialog){
      setProfessionalismRating(0);
      setPunctualityRating(0);
      setAppearanceRating(0);
      setReviewDescriptionRating("")
    }},[openDialog])


  return (
    <Dialog open={openDialog}>
        <DialogContent className="bg-white max-w-[900px] w-[100%] p-6 rounded-3xl z-[99999] max-h-[95%] overflow-y-auto">
        <div>
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold text-navy">Rate Now</h2>
          { !handleOk && <button onClick={handleClose} disabled={isSubmitReviewLoading}><img src={images.CLOSE} alt="close" /></button>}
          
        </div> 

        <ul className="flex flex-col gap-4 mb-8">
          <li className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={images.PROFESSIONALISM} alt="Professionalism" />
              <span className="text-base font-semibold text-navy">
                Rate Professionalism
              </span>
              <StarRatings
                rating={professionalismRating}
                starRatedColor="#FFDC64"
                numberOfStars={5}
                starDimension="20px"
                name="rating"
                starSpacing="0"
                changeRating={(newRating)=>{setProfessionalismRating(newRating)}}
            />
            </div>
          </li>
          <li className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={images.PUNCTUALITY} alt="Punctuality" />
              <span className="text-base font-semibold text-navy">
                Rate Punctuality
              </span>
              <StarRatings
                rating={punctualityRating}
                starRatedColor="#FFDC64"
                numberOfStars={5}
                starDimension="20px"
                name="rating"
                starSpacing="0"
                changeRating={(newRating)=>{setPunctualityRating(newRating)}}
             />
            </div>
          </li>
          <li className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <img src={images.APPEARANCE} alt="Appearance" />
              <span className="text-base font-semibold text-navy">
                Rate Appearance
              </span>
              <StarRatings
                rating={appearanceRating}
                starRatedColor="#FFDC64"
                numberOfStars={5}
                starDimension="20px"
                name="rating"
                starSpacing="0"
                changeRating={(newRating)=>{setAppearanceRating(newRating)}}
              />
            </div>
          </li>
        </ul>

        <div className="flex flex-col gap-3">
          <label className="text-lg font-semibold text-black">Review</label>
          <textarea
            className="resize-none bg-[#CBCAD70D] border-[0.5px] border-[#CBCAD780] rounded-[16px] w-full min-h-[106px] outline-none p-4 text-sm font-medium text-black"
            placeholder="Write here..."
            value={reviewDescription}
            onChange={(e)=> setReviewDescriptionRating(e?.target?.value || '')}
          ></textarea>
        </div>

        <div className="mt-7 text-center">
          <button className="text-base font-semibold text-white rounded-lg bg-seaGreen w-[236px] h-[52px]" onClick={submitReview} disabled={isSubmitReviewLoading}>
            {isSubmitReviewLoading? <Icons.spinner
                className="h-6 animate-spin w-4 mx-auto"
                aria-hidden="true"
            />:
            'Submit'}
          </button>
        </div>
        </div>
        </DialogContent>
    </Dialog>
  )
}

export default ReviewDoctorDialog