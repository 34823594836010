import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogClose } from "../../ui/dialog";
import { X } from "lucide-react";
import { getPatientHomeData } from "../Home/action";
import { useAppDispatch } from "../../redux/store/hooks";
import images from "../../utils/images";
import { Button } from "../../ui/button";
import { showAlert } from "../../utils/alert";
import END_POINTS from "../../utils/api/endpoints";
import API_CONSTANTS from "../../utils/api/api.configuration";
import { getUserToken } from "../../utils/helperfunction";
import Cookies from "js-cookie";
import { Icons } from "../../utils/icons";
import { useState } from "react";

type PropTypes = {
    ModalOpen: boolean;
    setModalOpen: any;
};

function LogoutModal({ ModalOpen, setModalOpen }: PropTypes) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isPending, setIsPending] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
        dispatch(getPatientHomeData());
        navigate("/");
    };

    const handleLogout = async () => {
        setIsPending(true)
        try {
            const response = await API_CONSTANTS.AUTHENTICATION_AXIOS.delete(
                END_POINTS.COMMON,
                { params: { user_click: 1 }, headers: { "liviaapp-token": getUserToken() } },
            );
            if (response.status === 200) {
                Cookies.remove("user_name");
                Cookies.remove("userData");
                // setIsPending(false)
                setModalOpen(false)
                navigate("/")
                setIsPending(false)
            }

        } catch (error: any) {
            const { response, message } = error;
            showAlert(2, response?.data?.messages[0]);
            setIsPending(false)

        }
    }

    return (
        <Dialog open={ModalOpen} onOpenChange={handleClose}>
            <DialogContent
                className="top-[45%] bg-white max-w-[445px] w-[100%] py-14 px-8 rounded-3xl"
                onInteractOutside={(e) => e.preventDefault()}
            >
                <DialogClose asChild>
                </DialogClose>

                <div className="text-center text-navy ">
                    <img
                        src={images.LOGOUT}
                        alt="congratulations"
                        className="mx-auto"
                    />
                    <h2 className="text-[26px] font-bold mt-6 mb-4">Logout Account!</h2>
                    <p className="text-base font-medium">
                        Are you sure you want to logout? Once you <br />logged out you need to login again.
                    </p>
                </div>
                <div className="flex justify-center gap-4 mt-4">

                    <Button className="border rounded w-[180px] h-[52px]" onClick={handleLogout} >{isPending ? (
                        <Icons.spinner
                            className="h-6 animate-spin w-4 mx-auto"
                            aria-hidden="true"
                        />
                    ) : 'Logout'}</Button>
                    <Button className="border border-seaGreen rounded w-[180px] bg-white h-[52px]" onClick={() => setModalOpen(false)}>Cancel</Button>
                </div>

            </DialogContent>
        </Dialog>
    );
}

export default LogoutModal;
