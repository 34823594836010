import React from "react";
import ArrowBorderRound from "../../../assets/icons/arrow-border-round.svg";

interface ExpertCardProps {
  bannerImage: string;
  views: string;
  title: string;
  description: string;
  onViewDoctors?: () => void;
  colorData?: any;
  isProvider?: string;
}

const ChooseUsCard: React.FC<ExpertCardProps> = ({
  bannerImage,
  views,
  title,
  description,
  onViewDoctors,
  colorData,
  isProvider,
}) => {
  return (
    <div className="rounded-xl py-[30px] px-5 bg-white md:min-h-[325px] text-center">
      {bannerImage && (
        <img
          src={bannerImage}
          alt={`${title}-banner`}
          className="w-[90px] md:w-[120px] h-[90px] md:h-[120px] rounded-[50%] mx-auto"
        />
      )}

      <h4 className="text-xl text-blackLight font-bold mt-5 md:mt-6 mb-10 md:min-h-[84px]">
        {views}
      </h4>

      {/* {description && ( */}
      {/* <p className="font-medium text-navy mt-2 mb-5 min-h-[96px]"></p> */}
      {/* )} */}

      {/* <button
        onClick={onViewDoctors}
        className="flex items-center mx-auto gap-4 p-2 text-seaGreen font-semibold"
      >
        View Doctors <img src={ArrowBorderRound} alt="arrow" />
      </button> */}

      <button
        onClick={onViewDoctors}
        style={{
          // backgroundColor: isProvider ? colorData?.btn_bg_color : '#23901E',
          color: isProvider ? colorData?.btn_bg_color : "#23901E",
        }}
        className={`flex items-center mx-auto gap-4 p-2 font-semibold rounded-lg`}
      >
        View Doctors <img src={ArrowBorderRound} alt="arrow" />
      </button>
    </div>
  );
};

export default ChooseUsCard;
